import React, { useEffect, useState, useRef } from "react";
import { MapContainer, TileLayer, useMap, Marker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";

import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";

import icon from "./Constants";

//user current location

import "./Map.css";
import useGeoLocation from "./user-current-location/Location";
import axios from "axios";

// Cordinates of Marcillac
const purpleOptions = { color: "white" };

function LeafletgeoSearch() {
  const map = useMap();
  useEffect(() => {
    // enableLocateMe;
    const provider = new OpenStreetMapProvider();

    const searchControl = new GeoSearchControl({
      provider,
      marker: {
        icon,
      },
    });

    // console.log("searchCTRL", searchControl);

    map.addControl(searchControl);

    // console.log("map", map);
    function searchEventHandler(result) {
      console.log(result.location);
      map.flyTo([result.location.y, result.location.x], 14, {
        animate: true,
      });
    }

    map.on("geosearch/showlocation", searchEventHandler);

    return () => map.removeControl(searchControl);
  }, []);

  return null;
}

function CurrentUserLocation() {
  const map = useMap();
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    map.locate({
      setView: true,
    });

    map.on("locationfound", (e) => {
      handleOnLocationFound(e);
      handleOnLocationFoundMarker(e);
    });
  }, []);

  const handleOnLocationFound = (event) => {
    const latlng = event.latlng;
    const radius = event.accuracy;
    const circle = L.circle(latlng, radius);
    console.log("lat long", latlng);

    circle.addTo(map);
  };

  const handleOnLocationFoundMarker = (event) => {
    if (marker !== null) {
      map.removeLayer(marker);
    }

    let newMarker = new L.marker(event.latlng, { icon: icon });

    setMarker(newMarker);

    newMarker.addTo(map);
  };

  return null;
}

const FlyToSearch = ({ latCoord, longCoord }) => {
  const map = useMap(); // available when component nested inside MapContainer

  const fly = () => {
    reverseGeoCode(latCoord, longCoord);
    map.flyTo([latCoord, longCoord], 14, {
      animate: true,
    });
  };

  return null;
};

// const FlyToButton = () => {
//   const map = useMap(); // available when component nested inside MapContainer
//   const location = useGeoLocation();

//   const fly = () => {
//     reverseGeoCode(location.coordinates.lat, location.coordinates.lng);
//     map.flyTo([location.coordinates.lat, location.coordinates.lng], 14, {
//       animate: true,
//     });
//   };

//   return (
//     <button
//       style={{ position: "absolute", top: "0", right: "0", zIndex: "9999" }}
//       onClick={fly}
//     >
//       Fly
//     </button>
//   );
// };

const FlyToButton = () => {
  const map = useMap(); // available when component nested inside MapContainer
  // const location = useGeoLocation();

  const [marker, setMarker] = useState(null);

  useEffect(() => {
    map.locate({
      setView: true,
    });

    map.on("locationfound", (e) => {
      // handleOnLocationFound(e);
      // handleOnLocationFoundMarker(e);
      fly(e);
    });
  }, []);

  const fly = (event) => {
    console.log(event.latlng);
    reverseGeoCode(event.latlng.lat, event.latlng.lng);
    map.flyTo([event.latlng.lat, event.latlng.lng], 14, {
      animate: true,
    });

    if (marker !== null) {
      map.removeLayer(marker);
    }

    let newMarker = new L.marker(event.latlng, { icon: icon });

    setMarker(newMarker);

    newMarker.addTo(map);
  };

  return null;
};
const reverseGeoCode = async (lat, long) => {
  await axios
    .get(
      `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${long}`
    )
    .then((result) => {
      console.log("Reverse geocose", result);

      //TODO api call to fetch all user in current city
    })
    .catch((err) => console.log(err));
};

const Map = () => {
  // const [map, setMap] = useState(null);
  const [ops, setPos] = useState(null);
  const [enableLocateMe, setEnableLocateMe] = useState(false);

  const [center, setCenter] = useState({ lat: 51.509865, lng: -0.118092 });
  const ZOOM_LEVEL = 9;
  const mapRef = useRef();

  const location = useGeoLocation();

  const showMyLocation = () => {
    if (location.loaded && !location.error) {
      mapRef.current.leafletElement.flyTo(
        [location.coordinates.lat, location.coordinates.lng],
        ZOOM_LEVEL,
        { animate: true }
      );
    } else {
      alert(location.error.message);
    }
  };

  const enableLocateMeFun = () => {
    setEnableLocateMe(!enableLocateMe);
  };

  // const map = useMap();
  const centerMap = [51.509865, -0.118092];

  // const changePos = (pos) => {
  //   setCenter(pos);
  //   const { map } = this.state;
  //   if (map) map.flyTo(pos);
  // };

  // useEffect(() => {
  //   map.locate({
  //     setView: true
  //   })
  // }, []);

  // render() {
  return (
    <div id="mapid">
      <MapContainer
        center={centerMap}
        zoom={4}
        ref={mapRef}
        // whenCreated={(map) => setMap(map)}
        scrollWheelZoom={true}
        className="map"
      >
        <TileLayer
          attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
        />
        <LeafletgeoSearch />

        {enableLocateMe && <FlyToButton />}

        {/* {location.loaded && !location.error && (
          <Marker
            icon={icon}
            position={[location.coordinates.lat, location.coordinates.lng]}
          ></Marker>
        )} */}

        {/* <CurrentUserLocation /> */}
      </MapContainer>

      <div className="row my-4">
        <div className="col d-flex justify-content-center">
          <button className="btn btn-primary" onClick={enableLocateMeFun}>
            Locate Me
          </button>
        </div>
      </div>
    </div>
  );
  // }
};

export default Map;
