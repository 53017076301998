import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// swiper bundle styles
import "swiper/css/bundle";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./SliderPerView.css";

// import required modules
import { Pagination, Navigation } from "swiper";
const items = [
  {
    name: "Random Name #1",
    description: "Probably the most random thing you have ever seen!",
    img: "https://source.unsplash.com/LkaGRQiic78",
  },
  {
    name: "Random Name #2",
    description: "Hello World!",
    img: "https://source.unsplash.com/LkaGRQiic78",
  },
  {
    name: "Random Name #2",
    description: "Hello World!",
    img: "https://source.unsplash.com/LkaGRQiic78",
  },
  {
    name: "Random Name #2",
    description: "Hello World!",
    img: "https://source.unsplash.com/LkaGRQiic78",
  },
  {
    name: "Random Name #2",
    description: "Hello World!",
    img: "https://source.unsplash.com/LkaGRQiic78",
  },
];

export default function ImageSlider(props) {
  const [list, setList] = useState(props.data || items);

  return (
    <>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        slidesPerGroup={1}
        // loop={true}
        // loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {list.map((el, ind) => (
          <SwiperSlide key={ind}>
            <img src={el.img} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
