import React, { useEffect, useState } from "react";

// import Carousel from "react-material-ui-carousel";
import { Button } from "@mui/material";

//swiper
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// swiper bundle styles
import "swiper/css/bundle";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

import "./ImageSlider.css";

const items = [
  {
    name: "Random Name #1",
    description: "Probably the most random thing you have ever seen!",
    img: "https://source.unsplash.com/9D_rUDe7xvA",
  },
  {
    name: "Random Name #2",
    description: "Hello World!",
    img: "https://source.unsplash.com/Y1drF0Y3Oe0",
  },
  {
    name: "Random Name #2",
    description: "Hello World!",
    img: "https://source.unsplash.com/__QqvTI5Edc",
  },
  {
    name: "Random Name #2",
    description: "Hello World!",
    img: "https://source.unsplash.com/usfIE5Yc7PY",
  },
  {
    name: "Random Name #2",
    description: "Hello World!",
    img: "https://source.unsplash.com/9LgM7NqXnCI",
  },
];

export default function ImageSlider(props) {
  const [list, setList] = useState(items);

  // useEffect(() => {
  //   if (props) setList(props.data);
  // }, []);

  return (
    // <Carousel
    //   autoPlay={false}
    //   duration={500}
    //   animation="slide"
    //   swipe
    //   indicators
    // >
    //   {list.map((el, i) => (
    //     <Item key={i} item={el} />
    //   ))}
    // </Carousel>
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        loop={true}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {list.map((el, ind) => (
          <SwiperSlide
            key={ind}
            // style={{ background: `${"url(" + `${el.img}` + ")"}` }}
          >
            <img src={el.img} />
          </SwiperSlide>
        ))}
        {/* <SwiperSlide>Slide 1</SwiperSlide>
        <SwiperSlide>Slide 2</SwiperSlide>
        <SwiperSlide>Slide 3</SwiperSlide>
        <SwiperSlide>Slide 4</SwiperSlide>
        <SwiperSlide>Slide 5</SwiperSlide>
        <SwiperSlide>Slide 6</SwiperSlide>
        <SwiperSlide>Slide 7</SwiperSlide>
        <SwiperSlide>Slide 8</SwiperSlide>
        <SwiperSlide>Slide 9</SwiperSlide> */}
      </Swiper>
    </>
  );
}

function Item(props) {
  return (
    <div
      style={{
        height: "400px",
        display: "grid",
        placeItems: "center",
        background: `${"url(" + props.item.img + ")"}`,
      }}
    >
      <h2>{props.item.name}</h2>
      <p>{props.item.description}</p>

      <Button className="CheckButton">Check it out!</Button>
    </div>
  );
}
