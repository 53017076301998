import React, { useEffect, useState } from "react";
import Axios from "axios";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@mui/material/IconButton";

//transition
import Fade from "@mui/material/Fade";

import "./Privacy.css";
import { TextField } from "@mui/material";

export default function Privicy() {
  const [lan, setLan] = React.useState("");

  const handleChange = (event) => {
    setLan(event.target.value);
  };

  const [ex, setEx] = useState({
    name: [
      { en: "Assisted pull-up" },
      { it: "Pull-up assistito" },
      { es: "Pull-up asistido" },
      { fr: "Traction assistée" },
      { de: "Assistierter Klimmzug" },
      { jpn: "アシストプルアップ" },
    ],
    img: "https://drive.google.com/uc?export=view&id=1_krkJRed-eIc9u0j6vqcgd__YiVvpO7s",
    video: "",
    // category: [
    //   { en: "Biceps" },
    //   { it: "Bicipiti" },
    //   { es: "Biceps" },
    //   { de: "Bizeps" },
    //   { fr: "Biceps" },
    //   { jpn: "上腕二頭筋" },
    // ],
    // category: [
    //   { en: "Triceps" },
    //   { it: "Tricipiti" },
    //   { es: "Triceps" },
    //   { de: "Trizeps" },
    //   { fr: "Triceps" },
    //   { jpn: "上腕三頭筋" },
    // ],
    category: [
      { en: "Forearms" },
      { it: "Avambracci" },
      { es: "Antebrazo" },
      { de: "Unterarm" },
      { fr: "Avant-bras" },
      { jpn: "前腕筋" },
    ],
    // category: [
    //   { en: "Back" },
    //   { it: "Schiena" },
    //   { es: "Espalda" },
    //   { de: "Rücken" },
    //   { fr: "Dos" },
    //   { jpn: "背中" },
    // ],
    desc: [
      {
        en: "Grab the pull-up bar with a wide grip. Inflate your chest and pull yourself up towards the bar. Lower yourself in a slow, controlled downward motion, always keeping your chest out.",
      },
      {
        it: "Afferra la barra per trazioni con una presa larga. Gonfia il petto e tirati su verso la barra. Abbassati con un movimento lento e controllato verso il basso, tenendo sempre il petto in fuori.",
      },
      {
        es: "Agarra la barra de dominadas con un agarre ancho. Saca el pecho y levántate hasta llegar a la barra. Desciende con un movimiento descendente lento y controlado, manteniendo siempre el pecho hacia afuera.",
      },
      {
        fr: "Saisissez la barre de traction avec une prise large. Gonflez votre poitrine et tirez-vous vers la barre. Abaissez-vous dans un mouvement lent et contrôlé vers le bas, en gardant toujours votre poitrine bombée.",
      },
      {
        de: "Greifen Sie die Klimmzugstange mit weitem Griff. Blase deine Brust auf und ziehe dich zur Stange hoch. Senken Sie sich in einer langsamen, kontrollierten Abwärtsbewegung ab und halten Sie dabei immer Ihre Brust heraus.",
      },
      {
        jpn: "広いグリップでプルアップバーをつかみます。胸を膨らませて、バーに向かって引き上げます。ゆっくりと制御された下向きの動きで身を下げ、常に胸を外します。",
      },
    ],
    sport: [
      { en: "Gym" },
      { it: "Palestra" },
      { es: "Gimnasio" },
      { de: "Turnhalle" },
      { fr: "Gym" },
      { jpn: "ジム" },
    ],
    equipment: [
      { en: "Dumbbell" },
      { it: "Manubrio" },
      { es: "Mancuerna" },
      { de: "Kurzhantel" },
      { fr: "Haltère" },
      { jpn: "ダンベル" },
    ],
    // equipment: [
    //   { en: "Barbell" },
    //   { it: "Bilancere" },
    //   { es: "Barra" },
    //   { de: "Langhantel" },
    //   { fr: "Barre" },
    //   { jpn: "バーベル" },
    // ],
    // equipment: [
    //   { en: "Machinery" },
    //   { it: "Macchinario" },
    //   { es: "Maquinaria" },
    //   { de: "Maschinen" },
    //   { fr: "Machinerie" },
    //   { jpn: "機械" },
    // ],
    // equipment: [
    //   { en: "Bodyweight exercises" },
    //   { it: "Esercizio a corpo libero" },
    //   { es: "Sin equipamiento" },
    //   { de: "Ohne Geräte" },
    //   { fr: "Sans équipement" },
    //   { jpn: "器具なし" },
    // ],
  });

  // console.log("ex", ex);
  const handleEx = (e) => {
    const { id, value } = e.target;
    let tmp = { ...ex };
    if (lan) {
      // console.log(lan);
      if (id === "img") {
        tmp.img = value;
      } else {
        tmp[id].forEach((el) => {
          if (lan === "en" && Object.keys(el)[0] === "en") {
            el.en = value;
            return;
          }
          if (lan === "it" && Object.keys(el)[0] === "it") {
            el.it = value;
            return;
          }
          if (lan === "de" && Object.keys(el)[0] === "de") {
            el.de = value;
            return;
          }
          if (lan === "es" && Object.keys(el)[0] === "es") {
            el.es = value;
            return;
          }
          if (lan === "jpn" && Object.keys(el)[0] === "jpn") {
            el.jpn = value;
            return;
          }
          if (lan === "fr" && Object.keys(el)[0] === "fr") {
            el.fr = value;
            return;
          }
        });
      }
      setEx(tmp);
    }
  };

  // console.log("exolo", ex);

  const clear = () => {
    setEx({
      name: [
        { en: "" },
        { it: "" },
        { es: "" },
        { fr: "" },
        { de: "" },
        { jpn: "" },
      ],
      img: "",
      video: "",
      category: ex.category,
      desc: [
        { en: "" },
        { it: "" },
        { es: "" },
        { fr: "" },
        { de: "" },
        { jpn: "" },
      ],
      sport: ex.sport,

      equipment: ex.equipment,
    });
  };

  const pos = async () => {
    await Axios.post("https://yoxeen.ks-hub.com:4000/exercise", ex, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((result) => console.log(result))
      .catch((error) => console.log(error));

    clear();
  };

  const search = async () => {
    await Axios.get("https://yoxeen.ks-hub.com:4000/exercise/search", {
      params: { name: ex.name },
    })
      .then((result) => console.log("res", result))
      .catch((err) => console.log("error", err));
  };

  const del = async () => {
    await Axios.delete("https://yoxeen.ks-hub.com:4000/exercise/", {
      params: { name: ex.name },
    })
      .then((result) => console.log("res", result))
      .catch((err) => console.log("error", err));
  };

  const [allEx, setAllEx] = useState([]);

  useEffect(() => {
    console.log("allEx", allEx);
  }, []);

  const all = async () => {
    if (lan) {
      await Axios.get("https://yoxeen.ks-hub.com:4000/exercise/", {
        params: { lang: lan },
      })
        .then((result) => {
          // console.log("res", result.data);
          setAllEx(result.data);
        })
        .catch((err) => console.log("error", err));
    }
  };

  return (
    <Fade in={true} {...(true ? { timeout: 500 } : {})}>
      <div className="privicy">
        <h1>Privacy Policy for yoxeen</h1>

        <p>
          At yoxeen, accessible from yoxeen.it:4000, one of our main priorities is
          the privacy of our visitors. This Privacy Policy document contains
          types of information that is collected and recorded by yoxeen and how
          we use it.
        </p>

        <p>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us.
        </p>

        <p>
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in yoxeen. This policy is not applicable to any
          information collected offline or via channels other than this website.
          Our Privacy Policy was created with the help of the{" "}
          <a href="https://www.termsfeed.com/privacy-policy-generator/">
            TermsFeed Free Privacy Policy Generator
          </a>
          .
        </p>

        <h2>Consent</h2>

        <p>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </p>

        <h2>Information we collect</h2>

        <p>
          The personal information that you are asked to provide, and the
          reasons why you are asked to provide it, will be made clear to you at
          the point we ask you to provide your personal information.
        </p>
        <p>
          If you contact us directly, we may receive additional information
          about you such as your name, email address, phone number, the contents
          of the message and/or attachments you may send us, and any other
          information you may choose to provide.
        </p>
        <p>
          When you register for an Account, we may ask for your contact
          information, including items such as name, company name, address,
          email address, and telephone number.
        </p>

        <h2>How we use your information</h2>

        <p>We use the information we collect in various ways, including to:</p>

        <ul>
          <li>Provide, operate, and maintain our website</li>
          <li>Improve, personalize, and expand our website</li>
          <li>Understand and analyze how you use our website</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the website, and for
            marketing and promotional purposes
          </li>
          <li>Send you emails</li>
          <li>Find and prevent fraud</li>
        </ul>

        <h2>Log Files</h2>

        <p>
          yoxeen follows a standard procedure of using log files. These files
          log visitors when they visit websites. All hosting companies do this
          and a part of hosting services' analytics. The information collected
          by log files include internet protocol (IP) addresses, browser type,
          Internet Service Provider (ISP), date and time stamp, referring/exit
          pages, and possibly the number of clicks. These are not linked to any
          information that is personally identifiable. The purpose of the
          information is for analyzing trends, administering the site, tracking
          users' movement on the website, and gathering demographic information.
        </p>

        <h2>Cookies and Web Beacons</h2>

        <p>
          Like any other website, yoxeen uses 'cookies'. These cookies are used
          to store information including visitors' preferences, and the pages on
          the website that the visitor accessed or visited. The information is
          used to optimize the users' experience by customizing our web page
          content based on visitors' browser type and/or other information.
        </p>

        <h2>Google DoubleClick DART Cookie</h2>

        <p>
          Google is one of a third-party vendor on our site. It also uses
          cookies, known as DART cookies, to serve ads to our site visitors
          based upon their visit to www.website.com and other sites on the
          internet. However, visitors may choose to decline the use of DART
          cookies by visiting the Google ad and content network Privacy Policy
          at the following URL –{" "}
          <a href="https://policies.google.com/technologies/ads">
            https://policies.google.com/technologies/ads
          </a>
        </p>

        <h2>Our Advertising Partners</h2>

        <p>
          Some of advertisers on our site may use cookies and web beacons. Our
          advertising partners are listed below. Each of our advertising
          partners has their own Privacy Policy for their policies on user data.
          For easier access, we hyperlinked to their Privacy Policies below.
        </p>

        <ul>
          <li>
            <p>Google</p>
            <p>
              <a href="https://policies.google.com/technologies/ads">
                https://policies.google.com/technologies/ads
              </a>
            </p>
          </li>
        </ul>

        <h2>Advertising Partners Privacy Policies</h2>

        <p>
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of yoxeen.
        </p>

        <p>
          Third-party ad servers or ad networks uses technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective
          advertisements and links that appear on yoxeen, which are sent
          directly to users' browser. They automatically receive your IP address
          when this occurs. These technologies are used to measure the
          effectiveness of their advertising campaigns and/or to personalize the
          advertising content that you see on websites that you visit.
        </p>

        <p>
          Note that yoxeen has no access to or control over these cookies that
          are used by third-party advertisers.
        </p>

        <h2>Third Party Privacy Policies</h2>

        <p>
          yoxeen's Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how
          to opt-out of certain options.{" "}
        </p>

        <p>
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers'
          respective websites.
        </p>

        <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

        <p>
          Under the CCPA, among other rights, California consumers have the
          right to:
        </p>
        <p>
          Request that a business that collects a consumer's personal data
          disclose the categories and specific pieces of personal data that a
          business has collected about consumers.
        </p>
        <p>
          Request that a business delete any personal data about the consumer
          that a business has collected.
        </p>
        <p>
          Request that a business that sells a consumer's personal data, not
          sell the consumer's personal data.
        </p>
        <p>
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </p>

        <h2>GDPR Data Protection Rights</h2>

        <p>
          We would like to make sure you are fully aware of all of your data
          protection rights. Every user is entitled to the following:
        </p>
        <p>
          The right to access – You have the right to request copies of your
          personal data. We may charge you a small fee for this service.
        </p>
        <p>
          The right to rectification – You have the right to request that we
          correct any information you believe is inaccurate. You also have the
          right to request that we complete the information you believe is
          incomplete.
        </p>
        <p>
          The right to erasure – You have the right to request that we erase
          your personal data, under certain conditions.
        </p>
        <p>
          The right to restrict processing – You have the right to request that
          we restrict the processing of your personal data, under certain
          conditions.
        </p>
        <p>
          The right to object to processing – You have the right to object to
          our processing of your personal data, under certain conditions.
        </p>
        <p>
          The right to data portability – You have the right to request that we
          transfer the data that we have collected to another organization, or
          directly to you, under certain conditions.
        </p>
        <p>
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </p>

        <h2>Children's Information</h2>

        <p>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </p>

        <p>
          yoxeen does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </p>
      </div>

      {/* <div className="privicy" style={{ display: "none" }}>
        <h1>Privicy</h1>

        <h2>EXERCISE</h2>
        <div className="privicy-input">
          <TextField
            variant="outlined"
            placeholder="name"
            id="name"
            onChange={handleEx}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <ClearIcon onClick={clear} />
                </IconButton>
              ),
            }}
          />
          <TextField
            variant="outlined"
            placeholder="desc"
            multiline
            id="desc"
            onChange={handleEx}
          />

          <h4>https://drive.google.com/uc?export=view&id=</h4>
          <TextField
            variant="outlined"
            placeholder="url"
            multiline
            id="img"
            onChange={handleEx}
          />
          <TextField
            variant="outlined"
            placeholder="sport"
            multiline
            id="sport"
            onChange={handleEx}
          />

          <TextField
            variant="outlined"
            placeholder="category"
            id="category"
            onChange={handleEx}
          />

          <TextField
            variant="outlined"
            placeholder="equipment"
            id="equipment"
            onChange={handleEx}
          />

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Language</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={lan}
              label="language"
              onChange={handleChange}
            >
              <MenuItem value={"it"}>it</MenuItem>
              <MenuItem value={"en"}>en</MenuItem>
              <MenuItem value={"es"}>es</MenuItem>
              <MenuItem value={"de"}>de</MenuItem>
              <MenuItem value={"fr"}>fr</MenuItem>
              <MenuItem value={"jpn"}>jpn</MenuItem>
              {/* <MenuItem value={"chi"}>chi</MenuItem> */}
      {/* </Select>
          </FormControl>
        </div>
        <button onClick={pos}>send json</button> */}

      {/* <h2>{ex["en"]["name"]}</h2> */}
      {/* <h2>{[ex.en].name}</h2>
        <h2>{ex.img}</h2> */}
      {/* <h2>{ex["en"]["category"]}</h2>
        <h2>{ex["it"]["name"]}</h2>
        <h2>{ex["it"]["category"]}</h2> */}
      {/* {visit(ex)} */}

      {/* <div style={{ width: "100%", border: "solid 1px black" }}></div>

        <button onClick={search}>Search</button>

        <div style={{ width: "100%", border: "solid 1px black" }}></div>
        <button onClick={del}>Delete</button>

        <div style={{ width: "100%", border: "solid 1px black" }}></div>
        <button onClick={all}>Get all</button>

        <div style={{ width: "100%", border: "solid 1px black" }}></div>

        <h2>Exercise</h2>

        <div className="privicy-info">
          {ex.name.map((el, ind) => (
            <h2 key={ind + "a"}>
              name {Object.keys(el)[0]}: {el[Object.keys(el)[0]]}
            </h2>
          ))}
          {ex.desc.map((el, ind) => (
            <h2 key={ind + "a"}>
              desc {Object.keys(el)[0]}: {el[Object.keys(el)[0]]}
            </h2>
          ))}
          {ex.category.map((el, ind) => (
            <h2 key={ind + "a"}>
              category {Object.keys(el)[0]}: {el[Object.keys(el)[0]]}
            </h2>
          ))}
          {ex.sport.map((el, ind) => (
            <h2 key={ind + "a"}>
              sport {Object.keys(el)[0]}: {el[Object.keys(el)[0]]}
            </h2>
          ))}
          {ex.equipment.map((el, ind) => (
            <h2 key={ind + "a"}>
              equipment {Object.keys(el)[0]}: {el[Object.keys(el)[0]]}
            </h2>
          ))}
          <h2>img: {ex.img}</h2>
          <h2>video: {ex.video}</h2>
        </div>
      </div> */}
    </Fade>
  );
}
