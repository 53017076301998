import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Switch from "@mui/material/Switch";

//toastify
import { ToastContainer, toast } from "react-toastify";

//transition
import Fade from "@mui/material/Fade";

//icons
import CheckIcon from "@mui/icons-material/Check";

import { useTranslation } from "react-i18next";

import "./Plan.css";
import axios from "axios";
import { getAuth } from "firebase/auth";
// import { display } from "@mui/system";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "600px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

//TODO API call totalworkout
// const totalWorkout = [
//   { label: "The Shawshank Redemption", year: 1994 },
//   { label: "The Godfather", year: 1972 },
//   { label: "The Godfather: Part II", year: 1974 },
//   { label: "The Dark Knight", year: 2008 },
//   { label: "12 Angry Men", year: 1957 },
//   { label: "Schindler's List", year: 1993 },
//   { label: "Pulp Fiction", year: 1994 },
//   {
//     label: "The Lord of the Rings: The Return of the King",
//     year: 2003,
//   },
// ];

//TODO api call weekProgram
const weekProgram = [
  {
    id: "1",
    name: "Add",
    thumb: "https://source.unsplash.com/random",
  },
  {
    id: "2",
    name: "Add",
    thumb: "https://source.unsplash.com/random",
  },
  {
    id: "3",
    name: "Add",
    thumb: "https://source.unsplash.com/random",
  },
  {
    id: "4",
    name: "Add",
    thumb: "https://source.unsplash.com/random",
  },
  {
    id: "5",
    name: "Add",
    thumb: "https://source.unsplash.com/random",
  },
  {
    id: "6",
    name: "Add",
    thumb: "https://source.unsplash.com/random",
  },
  {
    id: "7",
    name: "Add",
    thumb: "https://source.unsplash.com/random",
  },
];

export default function Plan() {
  // const auth = getAuth();
  // const user = auth.currentUser;

  const userLocalUid = localStorage.getItem("user");

  //translation obj
  const { t } = useTranslation();

  //dimensione dello schermo
  const [dimension, setDimension] = useState(0);

  //weeklist
  const [weekPlan, updateWeekPlan] = useState(weekProgram);
  //weeklist tmp
  const [tmpWeekPlan, updateTmpWeekPlan] = useState(weekProgram);

  //handle weeklist
  const [trainName, setTrainName] = useState("");
  const [trainPos, setTrainPos] = useState(-1);

  //plan status, single or multiple
  const [singlePlan, setSinglePlan] = useState(true);
  const label = { inputProps: { "aria-label": "Switch demo" } };

  //notelist
  //TODO API call get note
  const [note, setNote] = useState([
    { id: 1, text: "note1" },
    { id: 2, text: "note2" },
    { id: 3, text: "note3" },
  ]);

  //tmp arary to check changes
  const [tmpNote, setTmpNote] = useState([
    { id: 1, text: "note1" },
    { id: 2, text: "note2" },
    { id: 3, text: "note3" },
  ]);

  //take care about UI changes
  const [uiChanged, setUiChanched] = useState(false);
  //take care about first rendering
  const [count, setCount] = useState(0);

  //noteItem
  const [noteItem, setNoteItem] = useState("");

  //noteItemEdit state
  const [noteItemIndex, setNoteItemIndex] = useState(-1);

  //modal note
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  //modal settings
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setNoteItem("");
    setNoteItemIndex(-1);
  };
  const handleOpen2 = (ind) => {
    setOpen2(true);
    setTrainName(weekPlan[ind].name);
    setTrainPos(ind);
  };
  const handleClose2 = () => {
    setOpen2(false);
    setTrainName("");
    setTrainPos(-1);
  };

  //handle week plan daily
  const handleName = (n) => {
    setTrainName(n);
  };

  //handle current category selected
  const handleCategory = (value) => {
    setCurrentCategorySelected(value);
    // filterCategory();
  };

  //TOODO chiamata api èer il salvataggio della week
  const handleSaveName = () => {
    if (trainName === "") {
      handleClose2();
      return;
    }
    let tmp = [...weekPlan];
    tmp[trainPos].name = trainName;
    updateWeekPlan(tmp);
    saveChanges();
    // setUiChanched(true);
    handleClose2();
  };

  //Ottiene la dimensione corrente dello schermo
  function getWindowDimensions() {
    return window.innerWidth;
  }
  //Setta la dimensione della pagina
  useEffect(() => {
    setDimension(getWindowDimensions);
  }, []);

  //Gestisce il resize della pagina
  useEffect(() => {
    function handleResize() {
      setDimension(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //add note
  const handleAdd = () => {
    if (noteItem === "") return;
    let tmp = [...note, { id: note.length + 1, text: noteItem }];
    setNote(tmp);
    handleClose();
  };

  //handle note textfield
  const handleNote = (item) => {
    setNoteItem(item);
  };

  //handle edit note item
  function handleEdit(i) {
    setNoteItemIndex(i);
    setOpen(true);
  }

  //save note edited
  const handleSave = () => {
    // if (noteItemIndex !== -1) {
    handleClose();
    let tmp = [...note];
    note[noteItemIndex].text = noteItem;
    setNote(tmp);
    // } else {
    //   return;
    // }
  };

  //delete single note
  function handleDelete() {
    if (noteItemIndex !== -1) {
      let tmp = [...note];
      let tmp_item = tmp[noteItemIndex];

      let arr = [];
      let c = 0;
      for (let i = 0; i < note.length; i++) {
        if (tmp[i] !== tmp_item) {
          arr[c] = tmp[i];
          c++;
        }
      }
      setNote(arr);
      handleClose();
    } else {
      return;
    }
  }

  //drag and drop event handler
  function handleOnDragEnd(result) {
    // window.event.stopPropagation();
    // window.event.preventDefault();
    if (!result.destination) return;

    const items = Array.from(weekPlan);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    //TODO chiamata api per il salvataggio della nuova disposizione
    updateWeekPlan(items);
  }

  // if (dimension < 1200) {
  //   console.log("horizontal");
  // }else {
  //   console.log("vertical");
  // }

  //APICALL SAVE CHANGES
  const saveChanges = async () => {
    // console.log("saved");
    if (!userLocalUid) return;
    await axios
      .post(
        "https://yoxeen.ks-hub.com:4000/plan",
        {
          id: userLocalUid,
          plan: weekPlan,
          note: note,
        },
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((result) => getPlanData())
      .catch((error) => console.log("not oki", error));
  };

  //API CALL GET DATA
  const getPlanData = async () => {
    if (!userLocalUid) return;
    await axios
      .get("https://yoxeen.ks-hub.com:4000/plan", {
        params: { _id: userLocalUid },
      })
      .then((result) => {
        // console.log("plan result", result);
        updateWeekPlan(result.data.plan);
        updateTmpWeekPlan(result.data.plan);
        setNote(result.data.note);
        setTmpNote(result.data.note);
      })
      .catch((error) => console.log("error", error));
  };

  //API CALL GET DATA
  const [totalWorkout, setTotalWorkout] = useState(["Rest"]);
  const [totalCategory, setTotalCategory] = useState([]);
  const [totalWorkoutObject, setTotalWorkoutObject] = useState([]);
  const [currentCategorySelected, setCurrentCategorySelected] = useState("");

  const getAllWorkout = async () => {
    if (!userLocalUid) return;
    // console.log("linghezza", allWorkout.length);
    // if (allWorkout.length !== 0) return;
    await axios
      .get("https://yoxeen.ks-hub.com:4000/workout/allName", {
        params: { _id: userLocalUid },
      })
      .then((res) => {
        let tmp = [...res.data];
        let tmp_workout = [];
        let tmp_category = [];
        tmp.map((el) => {
          if (tmp_category.indexOf(el.sport) === -1) {
            tmp_category.push(el.sport);
          }
          tmp_workout.push(el.nome);
        });
        // setTotalWorkout(tmp_workout);
        setTotalCategory(tmp_category);
        setTotalWorkoutObject([...res.data]);

        // console.log("responce", res.data);
      })
      .catch((error) => console.log("error a", error));
  };

  useEffect(() => {
    getPlanData();
    getAllWorkout();
  }, []);

  //on category changed also the workout listed are changing
  useEffect(() => {
    // console.log("in");

    if (totalWorkoutObject) {
      let tmp = ["Rest"];
      totalWorkoutObject.map((el) => {
        if (el.sport === currentCategorySelected) tmp.push(el.nome);
      });
      setTotalWorkout(tmp);
    }
  }, [currentCategorySelected]);

  // console.log("axios allworkout responce", workout);
  // console.log("totalWorkout responce", totalWorkout);
  // console.log("totalCategory responce", totalCategory);

  //CHECK CHANGES
  useEffect(() => {
    let t = false;

    if (note.length !== tmpNote.length) {
      setUiChanched(true);
      return;
    } else {
      note.forEach((el, index) => {
        if (el.text !== tmpNote[index].text) {
          t = true;
        }
      });
    }
    weekPlan.forEach((el, index) => {
      if (el.name !== tmpWeekPlan[index].name) {
        t = true;
      }
    });

    if (t) {
      setUiChanched(true);
    } else {
      setUiChanched(false);
    }

    // console.log("t", t);
    // console.log("no-tmp", weekPlan, "tmp", tmpWeekPlan);
  });

  //export plan
  const handlePlanExport = async () => {
    let tmp = [];
    await axios
      .get("https://yoxeen.ks-hub.com:4000/workout/allWk", {
        params: { _id: userLocalUid },
      })
      .then((res) => {
        // setTotalWorkout([...res.data, "rest"]);
        tmp = [...res.data];
        // console.log("responce2", res.data);
      })
      .catch((error) => {
        console.log("error a", error);
        toast.update(id, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
        });
      });

    let tmp_final = [];
    weekPlan.forEach((el, ind) => {
      if (el.name !== "Rest")
        tmp_final.push(tmp.find((elem) => elem.name === el.name));
      else tmp_final.push({ id: "rest", name: "Rest" });
    });
    tmp_final.push({ name: "notes", data: note });
    console.log("final", tmp_final);

    const id = toast.loading("Your pdf is converting...");
    axios
      .post(
        "https://yoxeen.ks-hub.com:4000/pdfPlan",
        { workout: tmp_final, value: "plan", title: tmp_final.name },
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
        }
      )
      .then((response) => {
        toast.update(id, {
          render: "Pdf converted successfully",
          type: "success",
          isLoading: false,
          autoClose: 800,
        });
        //TODO notification untill pdf is fetched
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "plan" + ".pdf";
        link.click();
        //  this.closeModal() // close modal
      })
      .catch((err) => {
        console.log(err);
        toast.update(id, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
        });
      });
  };

  //multi week plane section
  //array of object that contains the weeks plan
  const [multiPlan, setMultiPlan] = useState([]);

  //counter of week inserted
  const [weekPlanCounter, setWeekPlanCounter] = useState(0);

  //single week plan
  const [week, setWeek] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
    five: "",
    six: "",
    seven: "",
  });

  return (
    <Fade in={true} {...(true ? { timeout: 500 } : {})}>
      <div className="plan">
        <ToastContainer />
        {/* <Switch onChange={() => setSinglePlan(!singlePlan)} {...label} /> */}
        {singlePlan ? (
          <>
            {uiChanged && (
              <div className="floating-create-btn">
                <Button
                  className="circle-class"
                  variant="contained"
                  onClick={saveChanges}
                >
                  <CheckIcon />
                </Button>
              </div>
            )}

            <div className="plan-container">
              <h1>{t("menu_plan_title")}</h1>
              <h6>{t("menu_plan_info")}</h6>
              <Button
                // style={{ color: "black", borderColor: "black" }}
                className="plan-btn"
                variant="outlined"
                onClick={handlePlanExport}
              >
                EXPORT TO PDF
              </Button>

              <div className="plan-general-box">
                <div className="center" style={{ width: "100%" }}>
                  <div className="plan-tl-box">
                    <div className="plan-timeline">
                      <ul className="plan-timeline-box">
                        <li>
                          <h5>{t("menu_plan_lun")}</h5>
                        </li>
                        <li>
                          <h5>{t("menu_plan_mar")}</h5>
                        </li>
                        <li>
                          <h5>{t("menu_plan_mer")}</h5>
                        </li>
                        <li>
                          <h5>{t("menu_plan_gio")}</h5>
                        </li>
                        <li>
                          <h5>{t("menu_plan_ven")}</h5>
                        </li>
                        <li>
                          <h5>{t("menu_plan_sab")}</h5>
                        </li>
                        <li>
                          <h5>{t("menu_plan_dom")}</h5>
                        </li>
                      </ul>
                    </div>

                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable
                        droppableId="weekPlan"
                        direction={dimension > 1600 ? "horizontal" : "vertical"}
                      >
                        {(provided) => (
                          <ul
                            className="week"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {weekPlan.map(({ id, name, thumb }, index) => {
                              return (
                                <Draggable
                                  key={id}
                                  draggableId={id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <li
                                      onClick={() => handleOpen2(index)}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      {/* <div className="week-thumb">
                                  <img src={thumb} alt={`${name} Thumb`} />
                                </div> */}
                                      <p>{name}</p>
                                    </li>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </ul>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>

                <div className="plan-note">
                  <div className="plan-note-title">
                    <h1>NOTE</h1>
                    <Button
                      variant="outlined"
                      className="plan-btn"
                      style={{ color: "black" }}
                      onClick={handleOpen}
                    >
                      {t("menu_plan_note_add")}
                    </Button>
                  </div>

                  <h6>{t("menu_plan_note_info")}</h6>

                  <div className="note">
                    {note.map((notes, index) => {
                      return (
                        <div
                          className="note-item"
                          key={index}
                          onClick={() => handleEdit(index)}
                        >
                          {/* <TextField
                  variant="standard"
                  id="outlined-read-only-input"
                  style={{ cursor: "pointer" }}
                  placeholder={notes.text}
                  multiline
                  InputProps={{
                    readOnly: true,
                  }}
                /> */}
                          <h6>{notes.text}</h6>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="schedule">
            <div className="schedule"></div>
          </div>
        )}
        <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Insert your daily plan
              </Typography>
              {/* TODO name of workouts */}
              <Typography
                id="modal-modal-title"
                variant="subtitle1"
                component="h2"
              >
                Choose your ex from your diary or insert rest day, note if the
                name of the workout doesn't match you're not able to see the
                workout plan
              </Typography>
              {/* <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              onChange={(e) => handleNote(e.target.value)}
            /> */}
              <p>Category</p>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={totalCategory}
                placeholder={currentCategorySelected}
                // sx={{ width: 300 }}
                inputValue={currentCategorySelected}
                onInputChange={(event, newInputValue) => {
                  handleCategory(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={weekPlan[trainPos].name}
                    variant="outlined"
                    {...params}
                    // onChange={(e) => handleName(e.target.value)}
                  />
                )}
              />
              <p>Workout name</p>

              <Autocomplete
                disabled={!currentCategorySelected}
                disablePortal
                id="combo-box-demo"
                options={totalWorkout}
                placeholder={trainName}
                // sx={{ width: 300 }}
                inputValue={trainName}
                onInputChange={(event, newInputValue) => {
                  handleName(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={weekPlan[trainPos].name}
                    variant="outlined"
                    {...params}
                    // onChange={(e) => handleName(e.target.value)}
                  />
                )}
              />
              <p></p>
              <Button variant="contained" onClick={handleSaveName}>
                SAVED
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {noteItemIndex === -1 ? (
              <div className="note-modal">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Insert a note
                </Typography>

                <TextField
                  id="outlined-multiline-static"
                  placeholder="Note"
                  multiline
                  fullWidth
                  rows={4}
                  onChange={(e) => handleNote(e.target.value)}
                />

                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => {
                    handleAdd();
                    // setUiChanched(true);
                  }}
                  style={{ marginTop: "20px" }}
                >
                  save
                </Button>
              </div>
            ) : (
              <div className="note-modal">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Edit note
                </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  maxRows={4}
                  multiline
                  fullWidth
                  defaultValue={note[noteItemIndex].text}
                  onChange={(e) => handleNote(e.target.value)}
                />
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleDelete}
                  style={{ marginTop: "20px" }}
                >
                  delete
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => {
                    handleSave();
                    setUiChanched(true);
                  }}
                >
                  save
                </Button>
              </div>
            )}
          </Box>
        </Modal>
      </div>
    </Fade>
  );
}
