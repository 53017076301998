import React, { useEffect, useState } from "react";

//firebse
import {
  getAuth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../../firebase-config";
import { useNavigate } from "react-router-dom";

//axios
import axios from "axios";

//notification
import {
  successNotification,
  errorNotification,
  warningNotification,
} from "../../components/notification/Notification";
import { ToastContainer, toast } from "react-toastify";

//mui
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

//icon
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

//components
import Map from "../../components/map/Map";
import MapTest from "../../components/map/MapTest";

//style
import "./FindExpert.css";
import MapLibre from "../../components/mapLibre/MapLibre";

export default function FindExpert(props) {
  return (
    <div className="findExpert">
      <div className="findExpert-title">
        <h3>
          Seatrch for an expert near to your location or find someone from an
          other city
        </h3>

        {/* <TextField
          sx={{ marginTop: 1, minWidth: "350px" }}
          value={search}
          onChange={handleSearch}
          label={""}
          autoComplete="off"
          InputLabelProps={{ shrink: false }}
          placeholder={"Search for any location"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {search !== "" && (
                  <CloseIcon
                    style={{ cursor: "pointer" }}
                    onClick={clearSearch}
                  />
                )}
              </InputAdornment>
            ),
          }}
          variant="standard"
        /> */}
      </div>

      <div className="findExpert-map">
        <MapTest />
      </div>

      {/* <div className="expert-map-drawer">
        <h3>Expert found in your city</h3>
      </div> */}

      {/* <div className="findExpert-map">
        <MapLibre />
      </div> */}
    </div>
  );
}
