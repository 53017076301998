import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, Outlet, useParams } from "react-router-dom";

//loader
import CircularProgress from "@mui/material/CircularProgress";

//icon
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

import "./Exercise.css";
import { Button } from "@mui/material";

// const data = async () => {
//     axios.get("yoxeen.it:4000/:category/:ex");
// }

// const data = ["a", "b", "c", "d", "e", "f"];

export default function Exercise() {
  //react router params
  const { category, exercise } = useParams();

  //loading state
  const [loading, setLoading] = useState(false);

  //user
  const userLocalUid = localStorage.getItem("user");

  //user setting info
  const [userLang, setUserLang] = useState("");

  const getLang = async () => {
    if (userLocalUid) {
      // console.log("utente", userLocalUid);
      await axios
        .get("https://yoxeen.ks-hub.com:4000/user/", {
          params: { _id: userLocalUid },
        })
        .then((result) => setUserLang(result.data.lang))
        .catch((error) => {
          // console.log("error d", error);
          navigate("/404");
        });
    }
  };

  useEffect(() => {
    getLang();
  }, []);

  //navigate
  const navigate = useNavigate();
  // useEffect(() => {
  //   const t = data.includes(ex);

  //   if (!t) {
  //     navigate("/404");
  //   }
  // }, [ex]);

  // console.log("log id", exercise);

  const [es, setEs] = useState({
    name: "cane",
    desc: "desc",
    muscle: "muscle",
  });

  //API get exercise

  const getExercise = async () => {
    // console.log("name ex", exercise);
    if (exercise === "" || userLang === "") return;
    await axios
      .get("https://yoxeen.ks-hub.com:4000/exercise/search", {
        params: { name: exercise, lang: userLang },
      })
      .then((result) => {
        setEs(result.data);
        // console.log(result.data);
      })
      .catch((error) => console.log(error));
    setLoading(true);
  };

  useEffect(() => {
    getExercise();
  }, [userLang]);

  const [count, setCount] = useState(0);
  //go to top
  useEffect(() => {
    if (count === 0) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      setCount(1);
    }
  }, []);

  return (
    <div className="exercise">
      {/* <Button
        variaton="outloned"
        onClick={() => navigate("/explore/:sport/" + category)}
      >
        <ArrowBackRoundedIcon />
      </Button> */}
      {loading ? (
        <div className="exercise-container">
          <div className="exercise-img" style={{ background: "white" }}>
            <img src={es.img} />
            {/* <img src="https://source.unsplash.com/random" /> */}
            {/* <img src={require("./../../../../images/shoulder.PNG")} /> */}
          </div>
          <div className="exercise-desc">
            <h4>{es.name}</h4>
            <h5>Desc: {es.desc}</h5>
            <h5>Muscle-group: {es.category}</h5>
          </div>
          <h2>Video exercise description</h2>
          <div className="exercise-video">
            <iframe
              // width="420"
              // height="315"
              src="https://www.youtube.com/embed/8WkuChVeL0s"
            ></iframe>
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}
