import React, { useEffect, useState } from "react";

//firebse
import {
  getAuth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../../firebase-config";
import { useNavigate } from "react-router-dom";

//axios
import axios from "axios";

//notification
import {
  successNotification,
  errorNotification,
  warningNotification,
} from "../../components/notification/Notification";
import { ToastContainer, toast } from "react-toastify";

//material components
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";

//menu
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

//i18
import i18next from "i18next";

//icon
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MonitorWeightIcon from "@mui/icons-material/MonitorWeight";
import LanguageIcon from "@mui/icons-material/Language";
import HeightIcon from "@mui/icons-material/Height";

import "./log.css";

import TextField from "@mui/material/TextField";
import { FormControl, Modal } from "@mui/material";
import { Box } from "@mui/system";
import SearchBox from "../../components/nominatim/searchBox";

const languages = [
  "Italiano",
  "English",
  "Francais",
  "Japan",
  "Espanol",
  "Deutch",
];

const langJson = {
  English: "en",
  Francais: "en",
  Japan: "jpn",
  Espanol: "es",
  Italiano: "it",
  Deutch: "de",
};

export default function Log(props) {
  //get obj from selected from Nomitaim
  const [selectPosition, setSelectPosition] = useState(null);

  useEffect(() => {
    if (selectPosition) {
      console.log("Geo data from selected", selectPosition);
      handleClose2();
    }
  }, [selectPosition]);

  //current user detection
  const [currentUser, setCurrentUser] = useState(localStorage.getItem("user"));

  //coach log form status
  const [registeredCoach, setRegisteredCoach] = useState(false);

  //log form show
  const [registered, setRegistered] = useState(false);

  //stage 1 of registration
  const [stage1, setStage1] = useState(false);

  // router navigate
  let navigate = useNavigate();

  //menu anchor
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const [userInfo, setUserInfo] = useState({
    _id: "",
    name: "",
    last_name: "",
    lang: "",
    weight: "",
  });

  //recover email status
  const [emailRecover, setEmailRecover] = useState("");

  // firebase
  const [user, setUser] = useState({});
  onAuthStateChanged(auth, (currentUser) => {
    if (currentUser) {
      // store the user on local storage
      // console.log(currentUser.uid);
      localStorage.setItem("user", currentUser.uid);
      setUser(currentUser);
    } else {
      // removes the user from local storage on logOut
      localStorage.removeItem("user");
    }
  });

  // useEffect(() => {
  //   console.log(user);
  //   if (user != null) {
  //     navigate("/account");
  //   } else {
  //     navigate("/log");
  //   }
  // }, [user]);

  const authentication = getAuth();

  useEffect(() => {
    setCurrentUser(
      localStorage.getItem("user") ? localStorage.getItem("user") : null
    );
  }, []);

  useEffect(() => {
    console.log("auth", authentication);
    // console.log("in");
    // if (currentUser !== null) {
    //   console.log("in2");

    //   navigate("/account");
    // } else {
    //   console.log("in3");

    //   navigate("/log");
    // }

    if (currentUser) {
      console.log("in2");

      navigate("/account");
    } else {
      console.log("in3");

      navigate("/log");
    }
  }, []);

  //register
  const [userName, setUserName] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [weight, setWeight] = useState(0);
  const [height, setHeight] = useState(0);

  const clear = () => {
    // setLogEmail("");
    // setLogPassword("");
    setName("");
    setSurname("");
    setUserName("");
    setEmail("");
    setPassword("");
    setWeight(0);
    setHeight(0);
  };

  useEffect(() => {
    if (!stage1) clear();
  }, [stage1]);

  function fieldControl() {
    if (name === "" || surname === "" || email === "" || password === "") {
      return false;
    } else {
      return true;
    }

    // console.log(name, surname, email, password);
  }

  const handleName = (e) => {
    setName(e.target.value);
  };
  function handleSurname(val) {
    setSurname(val);
  }
  const handleUserName = (e) => {
    setName(e.target.value);
  };
  function handleEmail(val) {
    setEmail(val);
  }
  function handlePassword(val) {
    setPassword(val);
  }
  function handleWeight(val) {
    setWeight(val);
  }
  function handleHeight(val) {
    setHeight(val);
  }

  // useEffect(() => {
  //   console.log(name, surname, email, password, weight, height, userInfo);
  // });

  //log
  const [logEmail, setLogEmail] = useState("");
  const [logPassword, setLogPassword] = useState("");

  function handleLogEmail(val) {
    setLogEmail(val);
  }

  function handleLogPassword(val) {
    setLogPassword(val);
  }

  // user registration
  const register = async () => {
    try {
      const user = await createUserWithEmailAndPassword(auth, email, password);
      // console.log("fire", user);
      // console.log("name", name);
      // console.log("surName", surname);

      const tmp_user = {
        _id: user.user.uid,
        username: userName,
        name: name,
        last_name: surname,
        email: email,
        lang: userInfo.lang || "en",
        weight: weight,
      };

      // console.log("blank", tmp_user);
      // navigate("/account");
      await axios
        .post("https://yoxeen.ks-hub.com:4000/user", tmp_user, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then(() => {
          successNotification("Registration completed succesfully!");
          navigate("/account");
        })
        .catch((error) => {
          console.log(error);
          errorNotification("Registration failed");
        });
    } catch (error) {
      console.log(error);
      errorNotification("Email already registered");
      return;
    }
  };

  const login = async () => {
    signInWithEmailAndPassword(authentication, logEmail, logPassword)
      .then(async (userCredential) => {
        const userCred = userCredential.user;
        console.log("userCred", userCred);
        console.log("sign in ININININ", userCred);
        await axios
          .post(
            "https://yoxeen.ks-hub.com:4002/login",
            {
              userCred,
            },
            {
              responseType: "arraybuffer",
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            }
          )
          .then((res) => {
            console.log("res of login", res);
        navigate("/account");
            })
            .catch((error) => {
              console.log(error);
              console.log("mireda");
            });
      })
      .catch((error) => {
        errorNotification("Email or password wrong!");
        console.log(error);
        // const errorCode = error.code;
        // const errorMessage = error.message;
      });
  };

  //handle registered status
  const handleLog = () => {
    // clear();
    setRegistered(!registered);
  };

  // console.log(registeredCoach);
  //handle coach registered status
  const handleLogCoach = () => {
    setRegisteredCoach(!registeredCoach);
  };

  //first modal open
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleRecoverPw = () => {
    setOpen(true);
    // sendPasswordResetEmail(authentication, email)
    //   .then(() => {
    //     // Password reset email sent!
    //     // ..
    //     successNotification("Request completed successfully");
    //   })
    //   .catch((error) => {
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     successNotification("Something goes wrong");

    //     // ..
    //   });
  };

  const handleRecover = () => {
    // getAuth()
    //   .generatePasswordResetLink(emailRecover)
    //   .then((link) => {
    //     // Construct password reset email template, embed the link and send
    //     // using custom SMTP server.
    //     // return sendPasswordResetEmail(emailRecover, "displayName", link);
    //   })
    //   .catch((error) => {
    //     // Some error occurred.
    //   });
    console.log(emailRecover);

    // sendPasswordResetEmail(authentication, emailRecover)
    // .then(() => {
    //   successNotification("Request completed successfully");
    // })
    // .catch((err) => console.log(err));

    // sendPasswordResetEmail(auth, emailRecover)
    //   .then(() => {
    //     successNotification("Request completed successfully");
    //   })
    //   .catch((err) => console.log(err));

    sendPasswordResetEmail(authentication, emailRecover)
      .then(() => {
        // Password reset email sent!
        successNotification("Request completed successfully");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        console.log(errorCode, errorMessage);
      });
    handleClose();
  };

  //search coach location modal
  const [open2, setOpen2] = useState(false);
  const handleClose2 = () => {
    setOpen2(false);
  };

  //modal style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "600px",
    bgcolor: "white",
    boxShadow: 24,
    p: 4,
  };
  return (
    <div className={`${props.sideState ? "active" : ""}`}>
      <div className="log-container">
        <ToastContainer />
        <div className="log-container-box">
          {/* <div className="log-text">
            <h1>WELCOME TO YOXEA</h1>
            <h3>.......................</h3>
            <p>join us in our community</p>
          </div>

          <div className="line"></div> */}

          <div className="log-box">
            {registered ? (
              <>
                {stage1 ? (
                  <div className="register-box">
                    <div className="register-title">
                      <ArrowBackIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => setStage1(false)}
                      />
                      <h1></h1>
                    </div>

                    <TextField
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MonitorWeightIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">Kg</InputAdornment>
                        ),
                      }}
                      style={{ marginTop: "20px" }}
                      fullWidth={true}
                      placeholder={weight ? weight : "Peso"}
                      variant="standard"
                      // value={weight || 0}
                      onChange={(e) => handleWeight(e.target.value)}
                    />

                    <TextField
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HeightIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">cm</InputAdornment>
                        ),
                      }}
                      fullWidth={true}
                      style={{ marginTop: "20px" }}
                      placeholder={height ? height : "Altezza"}
                      variant="standard"
                      // value={height || 0}
                      onChange={(e) => handleHeight(e.target.value)}
                    />

                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      style={{ marginTop: "20px" }}
                      options={languages}
                      fullWidth
                      cariant="standard"
                      onChange={(e, val) => {
                        // handleClose();
                        i18next.changeLanguage(langJson[val]);
                        let tmp = { ...userInfo };
                        tmp.lang = langJson[val];
                        setUserInfo(tmp);
                      }}
                      renderInput={(params) => (
                        <TextField
                          onChange={(e) => {
                            let tmp = { ...userInfo };
                            tmp.lang = langJson[e.target.value];
                            setUserInfo(tmp);
                          }}
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <LanguageIcon />
                              </InputAdornment>
                            ),
                          }}
                          label="Lang"
                          className="lang"
                          variant="standard"
                          placeholder={
                            userInfo.lang ? userInfo.lang : "Select language"
                          }
                        />
                      )}
                    />

                    {/* <Button
                      fullWidth
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      Language
                    </Button>

                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {languages.map(({ code, name }) => {
                        return (
                          <MenuItem
                            // disabled={code === currentLanguageCode}
                            key={code}
                            onClick={() => {
                              handleClose();
                              i18next.changeLanguage(code);
                              let tmp = { ...userInfo };
                              tmp.lang = code;
                              setUserInfo(tmp);
                            }}
                          >
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Menu> */}

                    <div style={{ width: "100%" }}>
                      <Button
                        fullWidth={true}
                        className="register-btn"
                        style={{ marginTop: "20px" }}
                        variant="contained"
                        onClick={register}
                      >
                        COMPLETE REGISTRATION
                      </Button>
                      <div className="sign-up">
                        <h5>Already have an account?</h5>
                        <p
                          onClick={() => {
                            handleLog();
                            setStage1(false);
                          }}
                        >
                          Login
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="register-box">
                    <div className="register-title">
                      <div style={{ cursor: "pointer" }} onClick={handleLog}>
                        <ArrowBackIcon />
                      </div>
                      <h1>Registrati</h1>
                    </div>

                    <form
                      className="register-box form"
                      style={{ width: "100%" }}
                      autoComplete="new-password"
                    >
                      <TextField
                        type="text"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleOutlinedIcon />
                            </InputAdornment>
                          ),
                          autoComplete: "new-password",
                        }}
                        style={{ marginTop: "20px" }}
                        fullWidth={true}
                        placeholder={"nome"}
                        variant="standard"
                        // value={name || ""}
                        onChange={handleName}
                      />
                      <TextField
                        type="text"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleOutlinedIcon />
                            </InputAdornment>
                          ),
                          autoComplete: "new-password",
                        }}
                        style={{ marginTop: "10px" }}
                        fullWidth={true}
                        placeholder={surname ? surname : "cognome"}
                        // value={surname || ""}
                        variant="standard"
                        onChange={(e) => handleSurname(e.target.value)}
                      />

                      <TextField
                        type="text"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleOutlinedIcon />
                            </InputAdornment>
                          ),
                          autoComplete: "new-password",
                        }}
                        style={{ marginTop: "20px" }}
                        fullWidth={true}
                        placeholder={"username"}
                        variant="standard"
                        // value={name || ""}
                        onChange={handleUserName}
                      />
                      <TextField
                        type="text"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MailOutlineIcon />
                            </InputAdornment>
                          ),
                          autoComplete: "new-password",
                        }}
                        style={{ marginTop: "10px" }}
                        fullWidth={true}
                        placeholder={email ? email : "email"}
                        variant="standard"
                        // value={email || ""}
                        onChange={(e) => handleEmail(e.target.value)}
                      />
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <VpnKeyOutlinedIcon />
                            </InputAdornment>
                          ),
                          autoComplete: "new-password",
                        }}
                        style={{ marginTop: "10px" }}
                        fullWidth={true}
                        placeholder="password"
                        variant="standard"
                        type="password"
                        onChange={(e) => handlePassword(e.target.value)}
                      />
                    </form>
                    <div style={{ width: "100%" }}>
                      <Button
                        fullWidth={true}
                        className="register-btn"
                        style={{ marginTop: "20px" }}
                        variant="contained"
                        // onClick={register}
                        onClick={() => {
                          if (
                            name === "" ||
                            surname === "" ||
                            email === "" ||
                            password === ""
                          ) {
                            setStage1(false);
                          } else {
                            setStage1(!stage1);
                          }
                        }}
                      >
                        REGISTRATI
                      </Button>
                      <div className="sign-up">
                        <h5>Already have an account?</h5>
                        <p onClick={handleLog}>Login</p>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {registeredCoach ? (
                  <div className="register-box">
                    <div className="register-title">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={handleLogCoach}
                      >
                        <ArrowBackIcon />
                      </div>
                      <div>
                        <h1>Register as coach</h1>
                        <h5>
                          Compile the below form to get in touch and start a
                          good relationship
                        </h5>
                      </div>
                    </div>

                    <form
                      className="register-box formCoach"
                      style={{ width: "100%" }}
                      autoComplete="new-password"
                    >
                      <TextField
                        type="text"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleOutlinedIcon />
                            </InputAdornment>
                          ),
                          autoComplete: "new-password",
                        }}
                        style={{ marginTop: "20px" }}
                        fullWidth={true}
                        placeholder={"nome"}
                        variant="standard"
                        // value={name || ""}
                        onChange={handleName}
                      />
                      <TextField
                        type="text"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleOutlinedIcon />
                            </InputAdornment>
                          ),
                          autoComplete: "new-password",
                        }}
                        style={{ marginTop: "10px" }}
                        fullWidth={true}
                        placeholder={surname ? surname : "cognome"}
                        // value={surname || ""}
                        variant="standard"
                        onChange={(e) => handleSurname(e.target.value)}
                      />

                      <TextField
                        type="text"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleOutlinedIcon />
                            </InputAdornment>
                          ),
                          autoComplete: "new-password",
                        }}
                        style={{ marginTop: "20px" }}
                        fullWidth={true}
                        placeholder={"username"}
                        variant="standard"
                        // value={name || ""}
                        defaultValue={""}
                        onChange={handleUserName}
                      />
                      <TextField
                        type="text"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MailOutlineIcon />
                            </InputAdornment>
                          ),
                          autoComplete: "new-password",
                        }}
                        style={{ marginTop: "10px" }}
                        fullWidth={true}
                        placeholder={email ? email : "email"}
                        variant="standard"
                        // value={email || ""}
                        onChange={(e) => handleEmail(e.target.value)}
                      />
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <VpnKeyOutlinedIcon />
                            </InputAdornment>
                          ),
                          autoComplete: "new-password",
                        }}
                        style={{ marginTop: "10px" }}
                        fullWidth={true}
                        placeholder="password"
                        variant="standard"
                        type="password"
                        onChange={(e) => handlePassword(e.target.value)}
                      />
                      <TextField
                        type="text"
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <VpnKeyOutlinedIcon />
                            </InputAdornment>
                          ),
                          autoComplete: "new-password",
                        }}
                        style={{ marginTop: "10px" }}
                        fullWidth={true}
                        placeholder="instagram account"
                        variant="standard"
                        // type="password"
                        // onChange={(e) => handlePassword(e.target.value)}
                      />
                      <TextField
                        type="text"
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <VpnKeyOutlinedIcon />
                            </InputAdornment>
                          ),
                          autoComplete: "new-password",
                        }}
                        style={{ marginTop: "10px" }}
                        fullWidth={true}
                        placeholder="Type of coach, nutrition, fitness ecc..."
                        variant="standard"
                        // type="password"
                        // onChange={(e) => handlePassword(e.target.value)}
                      />
                    </form>
                    <div style={{ width: "100%" }}>
                      <Button
                        fullWidth={true}
                        className="register-btn"
                        style={{ marginTop: "20px" }}
                        variant="contained"
                        // onClick={register}
                        onClick={() => {
                          if (
                            name === "" ||
                            surname === "" ||
                            email === "" ||
                            password === ""
                          ) {
                            setStage1(false);
                          } else {
                            setStage1(!stage1);
                          }
                        }}
                      >
                        Invia richiesta account x
                      </Button>
                      <div className="sign-up">
                        <h5>Already have an account?</h5>
                        <p onClick={handleLogCoach}>Login</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="logIn-box">
                    <div className="register-title">
                      <h1>Accedi</h1>
                    </div>
                    <FormControl
                      className="register-box form"
                      style={{ width: "100%" }}
                    >
                      <TextField
                        type="text"
                        inputProps={{
                          autoComplete: "new-password",
                        }}
                        fullWidth={true}
                        style={{ marginTop: "20px" }}
                        placeholder="email"
                        variant="standard"
                        onChange={(e) => handleLogEmail(e.target.value)}
                      />

                      <div style={{ width: "100%" }}>
                        <TextField
                          type="text"
                          inputProps={{
                            autoComplete: "new-password",
                          }}
                          fullWidth={true}
                          style={{ marginTop: "10px" }}
                          placeholder="password"
                          variant="standard"
                          type="password"
                          onChange={(e) => handleLogPassword(e.target.value)}
                        />

                        <div className="sign-up" style={{ marginBottom: "0" }}>
                          <p
                            onClick={handleRecoverPw}
                            style={{ width: "100%", textAlign: "right" }}
                          >
                            Forgot password?
                          </p>
                        </div>
                      </div>
                    </FormControl>

                    <div style={{ width: "100%" }}>
                      <Button
                        fullWidth={true}
                        style={{ marginTop: "20px" }}
                        variant="contained"
                        onClick={login}
                        className="signiIn-btn"
                      >
                        Sign In
                      </Button>

                      {/* <div className="sign-up">
                  <h5>Not registered yet?</h5>
                  <p onClick={handleLog}>SignUp</p>
                </div> */}
                      <div>
                        <div className="sign-up">
                          <h5>Not a member?</h5>
                          <p onClick={handleLog}>Signup</p>
                        </div>
                        <div className="sign-up">
                          <h5>Are you a coach?</h5>
                          <p onClick={handleLogCoach}>Click here!</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TextField
            variant="outlined"
            placeholder="Insert your email"
            onChange={(e) => setEmailRecover(e.target.value)}
          />
          <Button variant="outlined" onClick={() => handleRecover()}>
            Recover password
          </Button>
        </Box>
      </Modal>

      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="log-search-nominatim-box">
          <SearchBox
            selectPosition={selectPosition}
            setSelectPosition={setSelectPosition}
          />
        </Box>
      </Modal>
    </div>
  );
}
