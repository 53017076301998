import React, { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
// import InboxIcon from '@mui/icons-material/Inbox';
// import DraftsIcon from '@mui/icons-material/Drafts';
import LogoutIcon from "@mui/icons-material/Logout";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ShieldIcon from "@mui/icons-material/Shield";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ListSubheader from "@mui/material/ListSubheader";

//icons
import MenuIcon from "@mui/icons-material/Menu";
import LoginIcon from "@mui/icons-material/Login";
import HomeIcon from "@mui/icons-material/Home";
import DateRangeIcon from "@mui/icons-material/DateRange";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import BookIcon from "@mui/icons-material/Book";
import ArchiveIcon from "@mui/icons-material/Archive";
import HttpsIcon from "@mui/icons-material/Https";
import HelpIcon from "@mui/icons-material/Help";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";

//transition
import Fade from "@mui/material/Fade";

import { useTranslation } from "react-i18next";

import { auth } from "../../firebase-config";
import { useNavigate, useLocation } from "react-router-dom";

import "./Account.css";
import { Button } from "@mui/material";
import axios from "axios";

export default function Account(props) {
  //local uid
  const userLocalUid = localStorage.getItem("user");

  //translation obj
  const { t } = useTranslation();

  //location
  const location = useLocation();
  // console.log(location.pathname);

  //screen dim
  const [dimension, setDimension] = useState(0);

  //Ottiene la dimensione corrente dello schermo
  function getWindowDimensions() {
    return window.innerWidth;
  }
  //Setta la dimensione della pagina
  useEffect(() => {
    setDimension(getWindowDimensions);
  }, []);
  //Gestisce il resize della pagina
  useEffect(() => {
    function handleResize() {
      setDimension(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [slide, setSlide] = useState(false);

  const handleOpenS = (a) => {
    setSlide(a);
  };

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const setSelected = () => {
    setSelectedIndex(0);
  };

  const handleListItemClick = (event, index) => {
    setSlide(false);
    setSelectedIndex(index);
  };

  let navigate = useNavigate();

  const navigateToHome = async () => {
    // console.log("ok2");

    // await axios
    //   .post(
    //     "https://yoxeen.ks-hub.com:4000/logout",
    //     {
    //       userId: "id",
    //     },
    //     {
    //       // responseType: "arraybuffer",
    //       // headers: {
    //       //   "Content-Type": "application/json",
    //       // },
    //       withCredentials: true,
    //     }
    //   )
    //   .then((res) => {
    localStorage.removeItem("user");
    navigate("/log");
    // console.log("cookie removed");
    // })
    // .catch((error) => {
    //   console.log(error);
    //   console.log("mireda");
    // });
  };
  const handleLogOut = () => {
    console.log("ok");
    auth.signOut().then(
      () => {
        navigateToHome();
      },
      (error) => {
        console.log("error", error);
      }
    );
  };

  useEffect(() => {
    props.navState(true);
  });

  useEffect(() => {
    if (dimension > 1024) {
      setSlide(false);
    }
  }, [dimension]);

  //disable scroll
  const functiondisable = () => {
    // To get the scroll position of current webpage
    let TopScroll = window.pageYOffset || document.documentElement.scrollTop;
    let LeftScroll = window.pageXOffset || document.documentElement.scrollLeft;
    // if scroll happens, set it to the previous value

    window.onscroll = function () {
      window.scrollTo(LeftScroll, TopScroll);
    };
  };

  const functionenable = () => {
    window.onscroll = function () {};
  };

  useEffect(() => {
    if (slide === false) {
      functionenable();
    } else {
      functiondisable();
    }
  }, [slide]);

  useEffect(() => {
    let t = location.pathname.split("/");
    // console.log("t", t);
    if (location.pathname === "/account") setSelectedIndex(-1);
    if (location.pathname === "/account/create") setSelectedIndex(0);
    // if (location.pathname === "/account/create" && user && !user.firstAccess)
    //   setSelectedIndex(0);

    if (location.pathname === "/account/view") setSelectedIndex(1);
    if (location.pathname === "/account/plan") setSelectedIndex(2);
    if (location.pathname === "/account/progress") setSelectedIndex(3);
    if (location.pathname === "/account/diet") setSelectedIndex(4);

    if (location.pathname === "/account/general") setSelectedIndex(12);
    if (location.pathname === "/account/profile") setSelectedIndex(13);
    if (location.pathname === "/account/password") setSelectedIndex(14);
    if (location.pathname === "/account/privicy") setSelectedIndex(15);
    if (location.pathname === "/account/help") setSelectedIndex(16);
    // console.log(location.pathname);
    //TODO da verificare
    if (location.pathname === "/account/create" + "/" + t[3]) {
      setSelectedIndex(0);
      // navigate("/account/create" + "/" + t[3]);
    } else {
      navigate(`${location.pathname}`);
    }
  }, []);

  const [user, setUser] = useState({});

  //variabile che permette di caprie se la chiamata è stata già effettuata
  const [userCount, setUserCount] = useState(0);

  const getInfo = async () => {
    if (userLocalUid) {
      // console.log("sto chiedendo");
      await axios
        .get("https://yoxeen.ks-hub.com:4000/user", {
          params: { _id: userLocalUid },
        })
        .then((result) => {
          setUser(result.data);
          if (result.data) {
            if (result.data.firstAccess) {
              if (result.data.firstAccess === false) {
                navigate("/account/create");
                setSelected(0);
              }
            }
          }
          // console.log("Respone from user", result.data);
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    if (userCount === 0) {
      // console.log("caneporcocane");
      getInfo();
      setUserCount(userCount + 1);
      setSelectedIndex(0);
      navigate("/account/create");
    }
  }, []);

  //check if user is logged
  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/log");
  }, []);

  // console.log("user", user);
  // console.log("userCount", userCount);

  //remove scroll event listener
  // useEffect(() => {
  //   window.removeEventListener("scroll");
  // }, []);

  //funzione che modifica il primo accesso alla app
  const handleChangeFirstAccess = async () => {
    await axios
      .put("https://yoxeen.ks-hub.com:4000/user/firstAccess", {
        _id: userLocalUid,
      })
      .then((res) => console.log("changed", res))
      .catch((err) => console.log(err));
  };

  return (
    <Fade in={true} {...(true ? { timeout: 500 } : {})}>
      <div className="account">
        <div className={slide === true ? "togg" : ""}>
          <div className="account-slide-box">
            <Box className="account-slide">
              <List
                component="nav"
                aria-label="main mailbox folders"
                subheader={
                  <ListSubheader
                    className="slide-divider-text"
                    disableSticky={true}
                  >
                    App features
                  </ListSubheader>
                }
              >
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="create"
                >
                  <ListItemButton
                    selected={selectedIndex === 0}
                    onClick={(event) => handleListItemClick(event, 0)}
                  >
                    <ListItemIcon>
                      <AddCircleIcon className="slide-icon" />
                    </ListItemIcon>
                    <ListItemText
                      className="slide-text"
                      primary={t("menu_crea")}
                    />
                  </ListItemButton>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="view"
                >
                  <ListItemButton
                    selected={selectedIndex === 1}
                    onClick={(event) => handleListItemClick(event, 1)}
                  >
                    <ListItemIcon>
                      <FormatListBulletedIcon className="slide-icon" />
                    </ListItemIcon>
                    <ListItemText
                      className="slide-text"
                      primary={t("menu_visualizza")}
                    />
                  </ListItemButton>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="plan"
                >
                  <ListItemButton
                    selected={selectedIndex === 2}
                    onClick={(event) => handleListItemClick(event, 2)}
                  >
                    <ListItemIcon>
                      <DateRangeIcon className="slide-icon" />
                    </ListItemIcon>
                    <ListItemText
                      className="slide-text"
                      primary={t("menu_plan")}
                    />
                  </ListItemButton>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="progress"
                >
                  <ListItemButton
                    selected={selectedIndex === 3}
                    onClick={(event) => handleListItemClick(event, 3)}
                  >
                    <ListItemIcon>
                      <LeaderboardIcon className="slide-icon" />
                    </ListItemIcon>
                    <ListItemText
                      className="slide-text"
                      primary={t("menu_progress")}
                    />
                  </ListItemButton>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="diet"
                >
                  <ListItemButton
                    selected={selectedIndex === 4}
                    onClick={(event) => handleListItemClick(event, 4)}
                  >
                    <ListItemIcon>
                      <RestaurantIcon className="slide-icon" />
                    </ListItemIcon>
                    <ListItemText className="slide-text" primary={"Diet"} />
                  </ListItemButton>
                </Link>

                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="viewdiet"
                >
                  <ListItemButton
                    selected={selectedIndex === 5}
                    onClick={(event) => handleListItemClick(event, 5)}
                  >
                    <ListItemIcon>
                      <RestaurantIcon className="slide-icon" />
                    </ListItemIcon>
                    <ListItemText
                      className="slide-text"
                      primary={"View your diets"}
                    />
                  </ListItemButton>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="find"
                >
                  <ListItemButton
                    selected={selectedIndex === 6}
                    onClick={(event) => handleListItemClick(event, 6)}
                  >
                    <ListItemIcon>
                      <EmojiPeopleIcon className="slide-icon" />
                    </ListItemIcon>
                    <ListItemText className="slide-text" primary={"Social"} />
                  </ListItemButton>
                </Link>
                {/* <Divider /> */}
                <ListSubheader
                  className="slide-divider-text"
                  disableSticky={true}
                >
                  Related pages
                </ListSubheader>
                {/* <Divider /> */}
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/explore"
                >
                  <ListItemButton
                    selected={selectedIndex === 7}
                    onClick={(event) => {
                      handleListItemClick(event, 7);
                      props.navState(false);
                      functionenable();
                    }}
                  >
                    <ListItemIcon>
                      <ArchiveIcon className="slide-icon" />
                    </ListItemIcon>
                    <ListItemText className="slide-text" primary="Explore" />
                  </ListItemButton>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/blog"
                >
                  <ListItemButton
                    selected={selectedIndex === 8}
                    onClick={(event) => {
                      handleListItemClick(event, 8);
                      props.navState(false);
                      functionenable();
                    }}
                  >
                    <ListItemIcon>
                      <BookIcon className="slide-icon" />
                    </ListItemIcon>
                    <ListItemText className="slide-text" primary="Blog" />
                  </ListItemButton>
                </Link>
              </List>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/find"
              >
                <ListItemButton
                  selected={selectedIndex === 9}
                  onClick={(event) => {
                    handleListItemClick(event, 9);
                    props.navState(false);
                    functionenable();
                  }}
                >
                  <ListItemIcon>
                    <ArchiveIcon className="slide-icon" />
                  </ListItemIcon>
                  <ListItemText className="slide-text" primary="Find experts" />
                </ListItemButton>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/community"
              >
                <ListItemButton
                  selected={selectedIndex === 10}
                  onClick={(event) => {
                    handleListItemClick(event, 10);
                    props.navState(false);
                    functionenable();
                  }}
                >
                  <ListItemIcon>
                    <ArchiveIcon className="slide-icon" />
                  </ListItemIcon>
                  <ListItemText className="slide-text" primary="Community" />
                </ListItemButton>
              </Link>
              {/* <Divider /> */}

              <List
                component="nav"
                aria-label="secondary mailbox folder"
                subheader={
                  <ListSubheader
                    className="slide-divider-text"
                    disableSticky={true}
                  >
                    Profile
                  </ListSubheader>
                }
              >
                {/* <Divider /> */}
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="general"
                >
                  <ListItemButton
                    selected={selectedIndex === 11}
                    onClick={(event) => handleListItemClick(event, 11)}
                  >
                    <ListItemIcon>
                      <AccountCircleIcon className="slide-icon" />
                    </ListItemIcon>
                    <ListItemText
                      className="slide-text"
                      primary={t("menu_generali")}
                    />
                  </ListItemButton>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="profile"
                >
                  <ListItemButton
                    selected={selectedIndex === 12}
                    onClick={(event) => handleListItemClick(event, 12)}
                  >
                    <ListItemIcon>
                      <ManageAccountsIcon className="slide-icon" />
                    </ListItemIcon>
                    <ListItemText
                      className="slide-text"
                      primary={"Edit profile"}
                    />
                  </ListItemButton>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="password"
                >
                  <ListItemButton
                    selected={selectedIndex === 13}
                    onClick={(event) => handleListItemClick(event, 13)}
                  >
                    <ListItemIcon>
                      <HttpsIcon className="slide-icon" />
                    </ListItemIcon>
                    <ListItemText className="slide-text" primary={"Password"} />
                  </ListItemButton>
                </Link>

                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="privacy"
                >
                  <ListItemButton
                    selected={selectedIndex === 14}
                    onClick={(event) => handleListItemClick(event, 14)}
                  >
                    <ListItemIcon>
                      <ShieldIcon className="slide-icon" />
                    </ListItemIcon>
                    <ListItemText
                      className="slide-text"
                      primary={t("menu_privicy")}
                    />
                  </ListItemButton>
                </Link>

                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="help"
                >
                  <ListItemButton
                    selected={selectedIndex === 15}
                    onClick={(event) => handleListItemClick(event, 15)}
                  >
                    <ListItemIcon>
                      <HelpIcon className="slide-icon" />
                    </ListItemIcon>
                    <ListItemText className="slide-text" primary={"help"} />
                  </ListItemButton>
                </Link>
              </List>
              {/* <Divider /> */}
              <List
                component="nav"
                aria-label="secondary mailbox folder"
                subheader={
                  <ListSubheader
                    className="slide-divider-text"
                    disableSticky={true}
                  >
                    Log
                  </ListSubheader>
                }
              >
                {/* <Divider /> */}

                <Link style={{ textDecoration: "none", color: "black" }} to="/">
                  <ListItemButton
                    selected={selectedIndex === 16}
                    onClick={(event) => {
                      handleListItemClick(event, 16);
                      props.navState(false);
                      functionenable();
                    }}
                  >
                    <ListItemIcon>
                      <HomeIcon className="slide-icon" />
                    </ListItemIcon>
                    <ListItemText
                      className="slide-text"
                      primary={t("menu_home")}
                    />
                  </ListItemButton>
                </Link>
                {auth.currentUser ? (
                  <ListItemButton
                    style={{ top: "100% !important", left: "0" }}
                    selected={selectedIndex === 17}
                    onClick={(event) => {
                      handleListItemClick(event, 17);
                      handleLogOut();
                    }}
                  >
                    <ListItemIcon>
                      <LogoutIcon className="slide-icon" />
                    </ListItemIcon>
                    <ListItemText
                      className="slide-text"
                      primary={t("menu_log_out")}
                    />
                  </ListItemButton>
                ) : (
                  <ListItemButton
                    style={{ top: "100% !important", left: "0" }}
                    selected={selectedIndex === 18}
                    onClick={(event) => {
                      handleListItemClick(event, 18);
                      handleLogOut();
                    }}
                  >
                    <ListItemIcon>
                      <LoginIcon className="slide-icon" />
                    </ListItemIcon>
                    <ListItemText
                      className="slide-text"
                      primary={t("menu_sign_in")}
                    />
                  </ListItemButton>
                )}

                {/* <Link style={{ textDecoration: "none", color: "black" }} to="/"> */}
                <ListItemButton
                // selected={selectedIndex === 11}
                // onClick={(event) => {
                //   handleListItemClick(event, 11);
                //   props.navState(false);
                //   functionenable();
                // }}
                >
                  {/* <ListItemIcon>
                      <HomeIcon className="slide-icon" />
                    </ListItemIcon> */}
                  <ListItemText className="margin" primary={""} />
                </ListItemButton>
                {/* </Link> */}
              </List>
            </Box>
          </div>

          <div
            className={slide ? "black-touch op" : "black-touch"}
            onClick={() => {
              handleOpenS(false);
            }}
          >
            {/* <h1>ju</h1> */}
          </div>

          <div className="account-container center">
            <div className="account-nav">
              <div className="account-nav-container">
                <div className="account-nav-icon">
                  <MenuIcon
                    className="account-nav-burger"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleOpenS(true);
                    }}
                  />
                </div>
              </div>
            </div>
            {selectedIndex === -1 &&
              location.pathname === "/account" &&
              user &&
              user.firstAccess && (
                <div className="account-content-start-container center">
                  <div className="account-content-start center">
                    <h1 style={{ color: "#21355e" }}>BENVENUTO IN YOXEEN</h1>
                    <h3 style={{ color: "#21355e" }}>
                      Scopri la piattaforma per costruire la tua scheda di
                      allenamento
                    </h3>
                    <p style={{ color: "#21355e" }}>
                      Ci saranno prossimi aggiornamenti importanti
                    </p>
                    <h4 style={{ color: "#21355e" }}>RESTA CONNESSO</h4>
                    <Button
                      variant="outlined"
                      style={{ color: "#21355e", borderColor: "#21355e" }}
                      onClick={(event) => {
                        setSelectedIndex(0);
                        // handleListItemClick(event, 0);
                        if (user && user.firstAccess) {
                          handleChangeFirstAccess();
                        }
                        navigate("/account/create");
                      }}
                    >
                      Iniziamo !
                    </Button>
                  </div>
                </div>
              )}
            {selectedIndex != -1 && (
              <div className="account-content">
                <Outlet context={[selectedIndex, setSelectedIndex]} />
              </div>
            )}
          </div>
        </div>
      </div>
    </Fade>
  );
}
