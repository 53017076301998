//Api translation call
//https://api.mymemory.translated.net/get?q=Hello World!&langpair=en|it
import { Button, cardHeaderClasses, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

import foodCategory from "./foodCategory.json";
import edamameFood from "./edamameFoodItem.json";

import "./food.css";

export default function Food() {
  const urlId = process.env.EDAMAME_ID;
  const urlKey = process.env.EDAMAME_KEY;

  // console.log(urlId, urlKey);
  //   console.log(process.env);

  const [currentData, setCurrentData] = useState({});

  const languages = ["en", "it", "es", "de", "fr", "jpn"];

  //defoult food obj to load to DB
  //TODO add main nutrient to display directly without loopin throught all parameters
  //mainNutrient: {prot, carb, fat, sugar, sodium, }
  const food = {
    name: {
      en: "",
      it: "",
      es: "",
      fr: "",
      jpn: "",
    },
    listName: [],
    category: {
      en: "",
      it: "",
      es: "",
      fr: "",
      jpn: "",
    },
    nutrient: {},
    caloricSpread: {},
  };

  //   console.log("energy", food.category["it"]);

  //current food
  const [currentFoodTranslated, setCurrentFoodTranslated] = useState(food);

  const clearCurrentFoodTranslated = () => {
    setCurrentFoodTranslated(food);
  };

  //handle current food name changes
  const handleCurrentFoodNameTranslated = (e, el) => {
    // console.log("event", e.target.value);
    // console.log("id", el);

    let tmp = { ...currentFoodTranslated };
    tmp.name[el] = e.target.value;
    setCurrentFoodTranslated(tmp);
    // console.log("current_tmp_name", tmp);
  };

  //handle current food category changes
  const handleCurrentFoodCategoryTranslated = (e, el) => {
    // console.log("event", e.target.value);
    // console.log("id", el);

    let tmp = { ...currentFoodTranslated };
    tmp.category[el] = e.target.value;
    setCurrentFoodTranslated(tmp);
    // console.log("current_tmp_category", tmp);
  };

  //EDAMAME
  // const searchFood = async () => {
  //     // https://api.edamam.com/api/food-database/v2/parser?app_id=25692c2d&app_key=%20e7ede7615712396db9baa0f7c2904f13&ingr=pizza
  //     // axios.get(`https://api.edamam.com/api/food-database/v2/parser?app_id=${process.env.REACT_APP_EDAMAME_ID}&app_keys=${process.env.REACT_APP_EDAMAME_KEY}&ingr=pizza`)
  //     axios.get(`https://api.edamam.com/api/food-database/v2/parser?app_id=${process.env.REACT_APP_EDAMAME_ID}&app_key=${process.env.REACT_APP_EDAMAME_KEY}&ingr=pizza&nutrition-type=logging`)
  //     .then((res) => console.log(res))
  //     .catch((err) => console.log(err));
  // }

  // const searchFood = async () => {
  //   // https://api.edamam.com/api/food-database/v2/parser?app_id=25692c2d&app_key=%20e7ede7615712396db9baa0f7c2904f13&ingr=pizza
  //   // axios.get(`https://api.edamam.com/api/food-database/v2/parser?app_id=${process.env.REACT_APP_EDAMAME_ID}&app_keys=${process.env.REACT_APP_EDAMAME_KEY}&ingr=pizza`)
  //   axios
  //     .get(
  //       `https://api.edamam.com/api/nutrition-data?app_id=${process.env.REACT_APP_EDAMAME_ID_NUTR}&app_key=${process.env.REACT_APP_EDAMAME_KEY_NUTR}&ingr=100g ${foodName}&nutrition-type=logging`
  //     )
  //     .then((res) => {
  //       console.log(res);
  //       setCurrentSpoonFood(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // };

  //   const searchFood = async () => {
  //     // https://api.edamam.com/api/food-database/v2/parser?app_id=25692c2d&app_key=%20e7ede7615712396db9baa0f7c2904f13
  //     axios
  //       .get(
  //         `https://api.nal.usda.gov/fdc/v1/foods/search?api_key=eQMbtKTubXagwl7uduHlZs5mFWuUHfMlpjMeaikv&query=${foodName}`
  //       )
  //       .then((res) => {
  //         console.log(res);
  //         setCurrentData(res);
  //       })
  //       .catch((err) => console.log(err));
  //   };

  //   console.log("curr", currentSpoonFood);
  // //nutriex
  // axios.get("https://trackapi.nutritionix.com/v2/search/instant?query=apple", {
  //     headers: {
  //     id: process.enc.EDAMAME_ID,
  //     key: process.enc.EDAMAME_ID
  //     }
  // })
  // https://api.nal.usda.gov/fdc/v1/foods/search?query=cheddar&dataType=Foundation,SR%20Legacy&pageSize=25&pageNumber=2&sortBy=dataType.keyword&sortOrder=asc&brandOwner=Kar%20Nut%20Products%20Company
  // fooddata central

  //   console.log("current", currentData);
  //   console.log("name", foodName);
  // console.log("current 1", currentData.data.foods[0].foodNutrients[0].nutrientName);

  //api cll steps

  /*
  Prerequisiti: lista nomi prodotti, lista nomi id associati
  1- ricerca prodotto tramite nome
  2- recupero id prodotto
  3- ricerca nutrienti dall'id del prodotto
  4- caricare in DB
 */

  //**** VARIABLES

  //defoult array of all food to search
  const defaultFoodArray = [
    //meat
    "bacon",
    "beef",
    "chicken",
    "chicken breast",
    "meat",
    "duck",
    "ham",
    "kidneys",
    "lamb",
    "liver",
    "minced beef",
    "paté",
    "salami",
    "sausages",
    "pork",
    "pork pie",
    "turkey",
    "veal",

    //fruit
    "apple",
    "apricot",
    "banana",
    "blackberry",
    "blackcurrant",
    "blueberry",
    "cherry",
    "coconut",
    "fig",
    "gooseberry",
    "grape",
    "grapefruit",
    "kiwi fruit",
    "lemon",
    "lime",
    "mango",
    "melon",
    "orange",
    "peach",
    "pear",
    "pineapple",
    "plum",
    "pomegranate",
    "raspberry",
    "redcurrant",
    "rhubarb",
    "strawberry",

    //fish
    "anchovy",
    "cod",
    "haddock",
    "herring",
    "herring",
    "mackerel",
    "pilchard",
    "plaice",
    "salmon",
    "sardine",
    "smoked salmon",
    "sole",
    "trout",
    "tuna",

    //vegetables
    "artichoke",
    "asparagus",
    "aubergine",
    "avocado",
    "beansprouts",
    "beetroot",
    "broad beans",
    "broccoli",
    "Brussels sprouts",
    "cabbage",
    "carrot",
    "cauliflower",
    "celery",
    "chilli pepper",
    "courgette",
    "cucumber",
    "French beans",
    "lettuce",
    "mushroom",
    "onion",
    "peas",
    "potato",
    "pumpkin",
    "radish",
    "rocket",
    "runner beans",
    "swede",
    "sweet potato",
    "sweetcorn",
    "tomato",
    "turnip",
    "spinach",
    "kidney beans",

    //fats and condimenti
    "pasta sauce",
    "tomato purée",
    "olive oil",
    "butter",
    "margarine",
    "ketchup",
    "mayonnaise",
    "mustard",
    "peanut butter",

    //dairy
    "cream",
    "cheese",
    "blue cheese",
    "cottage cheese",
    "goats cheese",
    "cheddar",
    "emmental",
    "grana padano",
    "ricotta",

    "parmesan",
    "mozzarella",

    "eggs",
    "milk",
    "full-fat milk",
    "semi-skimmed milk",
    "skimmed milk",
    "sour cream",
    "yoghurt",

    //cereali e derivati
    "bread",
    "brown bread",
    "white bread",
    "pitta bread",
    "plain flour",
    "self-raising flour",
    "cornflour",
    "oat",
    "oat flour",
    "pasta",
    "rice",
    "pizza",
    "noodles",
    "cereal",
    "muesli",
    "tortilla",
    "porridge",

    //zuccheri e snack
    "cake",
    "brioche",
    "sugar",
    "honey",
    "jam",
    "biscuits",
    "chocolate",
    "ice cream",
    "candy",

    //frutta esiccata e frutta secca
    "dried prunes",
    "dates",
    "raisins",
    "peanut",
    "walnuts",

    //supplementi
    "whey proteins",
    "whey",

    //cosa non ho aggiunto
    "cornflakes",
    "crisps",
    "chips",
    "dried apricots",
    "olives",
  ];

  //count variable that updatet on each api request finished
  const [count, setCount] = useState(134);

  //array that contains all food id to make api requests
  const [foodRequestId, setFoodRequestId] = useState([
    43212, 1016076, 5062, 1065062, 98912, 10151, 13323, 10017026, 13325, 23557,
    7071, 1017063, 10010084, 5167, 17142, 9003, 9021, 10419297, 9083, 9050,
    9070, 12104, 9089, 9107, 9112, 9148, 9152, 9159, 9176, 9326, 9200, 9236,
    9252, 9266, 9291, 1009286, 9302, 9084, 9307, 9316, 15002, 15015, 15033,
    15039, 15050, 99283, 15076, 15088, 15077, 15114, 10015121, 15119, 11007,
    11011, 11209, 9037, 11043, 11080, 16053, 11090, 11098, 11109, 11124, 11135,
    11143, 11819, 11477, 11206, 10111052, 11215, 11216, 11246, 11252, 11260,
    11282, 11304, 10111333, 11352, 11429, 11959, 10011052, 11507, 11529, 11564,
    10011457, 11291, 10011485, 13346, 16033, 6159, 19411, 19095, 4053, 1001,
    6038, 1018, 1159, 1123, 4073, 1077, 1011077, 12118, 93761, 93607, 1001116,
    93652, 18064, 18069, 18413, 18292, 18133, 93730, 9032, 9087, 9299, 42184,
    8020, 19296, 19297, 20420, 21224, 20444, 11420420, 11935, 4025, 2046,
    10111333, 4114, 4135, 10118192, 1102, 16158, 12135, 9195, 16091, 12155,
    93698, 11297, 2036, 2049, 2010, 1002014, 2025, 2028, 2037, 98899, 20132,
    10099239, 16098,
  ]);

  // console.log("length", foodRequestId.length);

  //array that contains all food obj
  const [foodObjTransleted, setFoodObjTranslated] = useState([]);

  //get current searche food name
  const [foodName, setFoodName] = useState("");

  //variabile locale che serve per visualizzare i valori correnti della ricerca dell'alimento
  const [currentSpoonFood, setCurrentSpoonFood] = useState({});

  //save previus category
  const [prevCategory, setPrevCategory] = useState({});

  const changeToPrevCategory = () => {
    if (!prevCategory) {
      console.log("ERROR");
      return;
    }
    let tmp = { ...currentFoodTranslated };
    tmp.category = { ...prevCategory };
    setCurrentFoodTranslated(tmp);
  };

  // console.log("prevCtegory", prevCategory);

  //**** useeffect
  //useeffect that allowas to make api call of a list
  // useEffect(() => {
  //   if (count > defaultFoodArray.length) {
  //     console.log("ok");
  //     return;
  //   } else {
  //     setFoodName(defaultFoodArray[count]);
  //     searchFood();
  //   }
  // }, [count]);

  useEffect(() => {
    if (count > defaultFoodArray.length) {
      console.log("ok");
      return;
    } else {
      // setFoodName(defaultFoodArray[count]);
      // searchFood();
      clearCurrentFoodTranslated();
      getNutrients(foodRequestId[count]);
    }
  }, [count]);

  //**** FUNCTIONS
  //function that decide wich element is choose from me
  const addId = (id) => {
    // console.log("id", id);
    // console.log("count_inside", count);

    let tmp = [...foodRequestId];
    setFoodRequestId([...tmp, id]);
    setCurrentSpoonFood({});
    setFoodName("");
    let tmp2 = count + 1;
    setCount(tmp2);
  };

  const addNext = () => {
    let tmp2 = count + 1;
    setCount(tmp2);
  };
  const addPrev = () => {
    // clearCurrentFoodTranslated();
    let tmp2 = count - 1;
    setCount(tmp2);
  };

  useEffect(() => {
    setFoodName(defaultFoodArray[count]);
  }, [count]);

  //   console.log("foodArr", foodRequestId);
  //   console.log("count", count);

  //**** API CALLS
  const [foodArrayToDb, setFoodArrayToDb] = useState([]);

  //function that call db to add items
  const addToDB = () => {
    if (currentFoodTranslated) {
      let currentFoodTranslatedTmp = { ...currentFoodTranslated };

      let name = [];

      languages.map((el) => {
        if (currentFoodTranslated.name[el])
          name.push(currentFoodTranslated.name[el]);
      });

      currentFoodTranslatedTmp.listName = [...name];

      let tmp = [...foodArrayToDb, currentFoodTranslatedTmp];
      setFoodArrayToDb(tmp);

      console.log("tmp", currentFoodTranslatedTmp);

      axios
        .post("https://yoxeen.ks-hub.com:4000/food/", currentFoodTranslatedTmp, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log("ok", res);
          setPrevCategory(currentFoodTranslated.category);
        })
        .catch((err) => console.log("err", err));
    }
  };

  //function to start the iteration
  const startIter = () => {
    // setFoodName(defaultFoodArray[count]);
    searchFood();
  };

  //function to start api call to geto food nutrients
  const startNutrients = () => {
    // setFoodName(defaultFoodArray[count]);
    console.log("ok");
    setCount(0);
    setFoodName(defaultFoodArray[count]);
    // searchFood(defaultFoodArray[count]);
    //getNutrients(foodRequestId[count]);
  };

  //function to confirm the object translated
  const confirmItemObjectTranslated = () => {
    // setFoodObjTranslated([...foodObjTransleted, currentFoodTranslated]);
    // setCount(count + 1);
    getNutrients(98924);
  };

  //   useEffect(() => {
  //   }, [foodName]);

  //SPOONACULAR API REACT_APP_SPOONACULAR_KEY
  // const searchFood = async () => {
  //   //api call to search product and save product id to an array
  //   //and then after
  //   axios
  //     .get(
  //       `https://api.spoonacular.com/food/ingredients/search?query=${defaultFoodArray[count]}&apiKey=${process.env.REACT_APP_SPOONACULAR_KEY}`
  //     )
  //     .then((res) => {
  //       // console.log("Pussy", res);
  //       setCurrentSpoonFood(res.data);
  //       // if (res.data.results.length === 0) setCount(count + 1);
  //     })
  //     .catch((err) => console.log(err));
  // };

  //EDAMAME
  const searchFood = async () => {
    // https://api.edamam.com/api/food-database/v2/parser?app_id=25692c2d&app_key=%20e7ede7615712396db9baa0f7c2904f13&ingr=pizza
    // axios.get(`https://api.edamam.com/api/food-database/v2/parser?app_id=${process.env.REACT_APP_EDAMAME_ID}&app_keys=${process.env.REACT_APP_EDAMAME_KEY}&ingr=pizza`)
    console.log("count", count);
    console.log("Current item displayed", currentFoodTranslated);
    axios
      .get(
        `https://api.edamam.com/api/nutrition-data?app_id=${process.env.REACT_APP_EDAMAME_ID_NUTR}&app_key=${process.env.REACT_APP_EDAMAME_KEY_NUTR}&nutrition-type=cooking&ingr=100g%20${foodName}`
      )
      .then(async (responce) => {
        console.log("Get Nutrients api", responce);
        const foodNameTranslate = foodName;
        const foodCategoryTranslate = "General";

        //buld object to display on the screen and to insert into DB
        let tmp = { ...food };
        let tmp_category = {
          en: "",
          it: "",
          es: "",
          fr: "",
          jpn: "",
        };
        let tmp_name = {
          en: "",
          it: "",
          es: "",
          fr: "",
          jpn: "",
        };
        try {
          //api call to translate
          for (let el = 0; el < languages.length; el++) {
            // const element = languages[el];

            if (el != "en") {
              const res_name = await axios.get(
                `https://api.mymemory.translated.net/get?q=${foodNameTranslate}!&langpair=en|${languages[el]}`
              );
              if (res_name.data.matches.length !== 0) {
                tmp_name = {
                  ...tmp_name,
                  [languages[el]]: res_name.data.matches[0].translation,
                };
              } else {
                tmp_name = {
                  ...tmp_name,
                  [languages[el]]: foodNameTranslate,
                };
              }
              const res_category = await axios.get(
                `https://api.mymemory.translated.net/get?q=${foodCategoryTranslate}!&langpair=en|${languages[el]}`
              );
              if (res_name.data.matches.length !== 0) {
                tmp_category = {
                  ...tmp_category,
                  [languages[el]]: res_category.data.matches[0].translation,
                };
              } else {
                tmp_category = {
                  ...tmp_category,
                  [languages[el]]: foodCategoryTranslate,
                };
              }
              //   console.log("res", res_category, res_name);
            } else {
              tmp_name = {
                ...tmp_name,
                [el]: responce.data.name,
              };
              tmp_category = {
                ...tmp_category,
                [el]: responce.data.category,
              };
            }
            // console.log(el);
            // console.log("tmp", tmp_category, tmp_name);
          }
        } catch (err) {
          console.log(err);
        }

        tmp.category = { ...tmp_category };
        tmp.name = { ...tmp_name };

        //nutrition data
        let nutrients = {};

        Object.keys(edamameFood.nutrients).map((el) => {
          // console.log("in", responce.data.totalNutrients[el]);
          if (responce.data.totalNutrients[el]) {
            nutrients = {
              ...nutrients,
              [edamameFood.nutrients[el]]: {
                label: responce.data.totalNutrients[el]["label"],
                value: responce.data.totalNutrients[el]["quantity"],
                unit: responce.data.totalNutrients[el]["unit"],
              },
            };
          } else {
            nutrients = {
              ...nutrients,
              [edamameFood.nutrients[el]]: {
                label: `${edamameFood.nutrients[el]}`,
                value: 0,
                unit: "g",
              },
            };
          }
        });
        tmp.nutrient = { ...nutrients };

        //total nutrition caloric spread

        let caloricSpread = {};

        Object.keys(edamameFood.KcalFromNutrients).map((el) => {
          if (responce.data.totalNutrientsKCal[el])
            caloricSpread = {
              ...caloricSpread,
              [edamameFood.KcalFromNutrients[el]]: {
                label: responce.data.totalNutrientsKCal[el]["label"],
                value: responce.data.totalNutrientsKCal[el]["quantity"],
                unit: responce.data.totalNutrientsKCal[el]["unit"],
              },
            };
        });

        tmp.caloricSpread = { ...caloricSpread };

        //main nutrients
        // let tmp_mainNutrients = {};

        // // if (tmp.nutrient) {
        // //   tmp.nutrient.map((el) => {
        // //     if (
        // //       el.name === "Potassium" ||
        // //       el.name === "Protein" ||
        // //       el.name === "Sugar" ||
        // //       el.name === "Fiber" ||
        // //       el.name === "Sodium" ||
        // //       el.name === "Cholesterol" ||
        // //       el.name === "Fat" ||
        // //       el.name === "Fiber" ||
        // //       el.name === "Calories" ||
        // //       el.name === "Calcium"
        // //     ) {
        // //       tmp_mainNutrients = {
        // //         ...tmp_mainNutrients,
        // //         [el.name.toLowerCase()]: {
        // //           name: el.name,
        // //           value: el.amount,
        // //           unit: el.unit,
        // //         },
        // //       };
        // //     }
        // //     if (el.name === "Vitamin C") {
        // //       tmp_mainNutrients = {
        // //         ...tmp_mainNutrients,
        // //         vitaminC: {
        // //           name: el.name,
        // //           value: el.amount,
        // //           unit: el.unit,
        // //         },
        // //       };
        // //     }
        // //     if (el.name === "Vitamin A") {
        // //       tmp_mainNutrients = {
        // //         ...tmp_mainNutrients,
        // //         vitaminA: {
        // //           name: el.name,
        // //           value: el.amount,
        // //           unit: el.unit,
        // //         },
        // //       };
        // //     }

        // //     if (el.name === "Poly Unsaturated Fat") {
        // //       tmp_mainNutrients = {
        // //         ...tmp_mainNutrients,
        // //         polyUnsaturatedFat: {
        // //           name: el.name,
        // //           value: el.amount,
        // //           unit: el.unit,
        // //         },
        // //       };
        // //     }
        // //     if (el.name === "Mono Unsaturated Fat") {
        // //       tmp_mainNutrients = {
        // //         ...tmp_mainNutrients,
        // //         monoUnsaturatedFat: {
        // //           name: el.name,
        // //           value: el.amount,
        // //           unit: el.unit,
        // //         },
        // //       };
        // //     }
        // //   });
        // // }
        // tmp.mainNutrients = { ...tmp_mainNutrients };

        //list name for mongoDB search engine index
        let name = [];

        languages.map((el) => {
          if (tmp.name[el]) name.push(tmp.name[el]);
        });

        tmp.listName = [...name];
        // console.log("full object nutrient obj", tmp);

        //set the tmp object correctly
        setCurrentFoodTranslated(tmp);
        console.log("currenti food getted", tmp);
        //display th tmp object
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (foodName) searchFood();
  }, [foodName]);

  //SPOONACULAR API TO GET NUTRIENTS FROM A FOOD ID
  const getNutrients = async (id) => {
    //api call to get profucts
    // axios
    //   .get(
    //     ` https://api.spoonacular.com/food/ingredients/${id}/information?amount=1&apiKey=${process.env.REACT_APP_SPOONACULAR_KEY}`
    //   )
    //   .then(async (responce) => {
    //     // console.log("Get Nutrients api", responce);
    //     const foodNameTranslate = responce.data.name;
    //     const foodCategoryTranslate =
    //       responce.data.categoryPath[0] || "General";
    //     //buld object to display on the screen and to insert into DB
    //     let tmp = { ...food };
    //     let tmp_category = {
    //       en: "",
    //       it: "",
    //       es: "",
    //       fr: "",
    //       jpn: "",
    //     };
    //     let tmp_name = {
    //       en: "",
    //       it: "",
    //       es: "",
    //       fr: "",
    //       jpn: "",
    //     };
    //     try {
    //       //api call to translate
    //       for (let el = 0; el < languages.length; el++) {
    //         // const element = languages[el];
    //         if (el != "en") {
    //           const res_name = await axios.get(
    //             `https://api.mymemory.translated.net/get?q=${foodNameTranslate}!&langpair=en|${languages[el]}`
    //           );
    //           if (res_name.data.matches.length !== 0) {
    //             tmp_name = {
    //               ...tmp_name,
    //               [languages[el]]: res_name.data.matches[0].translation,
    //             };
    //           } else {
    //             tmp_name = {
    //               ...tmp_name,
    //               [languages[el]]: foodNameTranslate,
    //             };
    //           }
    //           const res_category = await axios.get(
    //             `https://api.mymemory.translated.net/get?q=${foodCategoryTranslate}!&langpair=en|${languages[el]}`
    //           );
    //           if (res_name.data.matches.length !== 0) {
    //             tmp_category = {
    //               ...tmp_category,
    //               [languages[el]]: res_category.data.matches[0].translation,
    //             };
    //           } else {
    //             tmp_category = {
    //               ...tmp_category,
    //               [languages[el]]: foodCategoryTranslate,
    //             };
    //           }
    //           //   console.log("res", res_category, res_name);
    //         } else {
    //           tmp_name = {
    //             ...tmp_name,
    //             [el]: responce.data.name,
    //           };
    //           tmp_category = {
    //             ...tmp_category,
    //             [el]: responce.data.category,
    //           };
    //         }
    //         // console.log(el);
    //         // console.log("tmp", tmp_category, tmp_name);
    //       }
    //     } catch (err) {
    //       console.log(err);
    //     }
    //     tmp.category = { ...tmp_category };
    //     tmp.name = { ...tmp_name };
    //     tmp.nutrient = [...responce.data.nutrition.nutrients];
    //     tmp.caloricSpread = { ...responce.data.nutrition.caloricBreakdown };
    //     let tmp_mainNutrients = {};
    //     if (tmp.nutrient) {
    //       tmp.nutrient.map((el) => {
    //         if (
    //           el.name === "Potassium" ||
    //           el.name === "Protein" ||
    //           el.name === "Sugar" ||
    //           el.name === "Fiber" ||
    //           el.name === "Sodium" ||
    //           el.name === "Cholesterol" ||
    //           el.name === "Fat" ||
    //           el.name === "Fiber" ||
    //           el.name === "Calories" ||
    //           el.name === "Calcium"
    //         ) {
    //           tmp_mainNutrients = {
    //             ...tmp_mainNutrients,
    //             [el.name.toLowerCase()]: {
    //               name: el.name,
    //               value: el.amount,
    //               unit: el.unit,
    //             },
    //           };
    //         }
    //         if (el.name === "Vitamin C") {
    //           tmp_mainNutrients = {
    //             ...tmp_mainNutrients,
    //             vitaminC: {
    //               name: el.name,
    //               value: el.amount,
    //               unit: el.unit,
    //             },
    //           };
    //         }
    //         if (el.name === "Vitamin A") {
    //           tmp_mainNutrients = {
    //             ...tmp_mainNutrients,
    //             vitaminA: {
    //               name: el.name,
    //               value: el.amount,
    //               unit: el.unit,
    //             },
    //           };
    //         }
    //         if (el.name === "Poly Unsaturated Fat") {
    //           tmp_mainNutrients = {
    //             ...tmp_mainNutrients,
    //             polyUnsaturatedFat: {
    //               name: el.name,
    //               value: el.amount,
    //               unit: el.unit,
    //             },
    //           };
    //         }
    //         if (el.name === "Mono Unsaturated Fat") {
    //           tmp_mainNutrients = {
    //             ...tmp_mainNutrients,
    //             monoUnsaturatedFat: {
    //               name: el.name,
    //               value: el.amount,
    //               unit: el.unit,
    //             },
    //           };
    //         }
    //       });
    //     }
    //     let name = [];
    //     languages.map((el) => {
    //       if (tmp.name[el]) name.push(tmp.name[el]);
    //     });
    //     tmp.listName = [...name];
    //     tmp.mainNutrients = { ...tmp_mainNutrients };
    //     // console.log("full object nutrient obj", tmp);
    //     //set the tmp object correctly
    //     setCurrentFoodTranslated(tmp);
    //     //display th tmp object
    //   })
    //   .catch((err) => console.log(err));
  };

  // console.log("current Arry of ids", foodRequestId);
  // console.log("Total array of food", foodArrayToDb);

  //   console.log("arr", defaultFoodArray[count]);
  //   console.log("foodname", foodName);
  // console.log("current", currentFoodTranslated);

  // const handleChange = (ev) => {
  //   console.log("ok");

  //   let files = ev.target.files;
  // };

  const saveToLocal = () => {
    console.log("Ok, saved to local");
    localStorage.setItem("FoodArray", JSON.stringify(foodArrayToDb));
  };

  const testWordReference = async () => {
    axios
      .get("http://api.wordreference.com/iten/cane")
      .then((res) => console.log("res", res))
      .catch((err) => console.log("err", err));
  };

  return (
    <div>
      {/* <input type="file" name="file" onChange={(e) => handleChange(e)} /> */}

      <div className="food">
        {/* <h1>CANE</h1> */}

        <div className="food-box">
          <div className="food-search">
            <TextField
              placeholder={"Search"}
              variant="standard"
              // value={weight || 0}
              onChange={(e) => setFoodName(e.target.value)}
            />
            <Button onClick={searchFood} variant="outlined">
              search
            </Button>
            <Button onClick={startNutrients} variant="outlined">
              start
            </Button>
            <Button onClick={confirmItemObjectTranslated} variant="outlined">
              OK
            </Button>
            <Button onClick={saveToLocal} variant="outlined">
              SAVE
            </Button>
            <Button onClick={changeToPrevCategory} variant="outlined">
              Save prev category
            </Button>
            <Button onClick={addToDB} variant="outlined">
              Insert
            </Button>
            <Button onClick={addNext}>Next</Button>
            <Button onClick={addPrev}>Prev</Button>
            <Button onClick={testWordReference}>TEST</Button>
          </div>
        </div>

        {/* food id to search */}
        <div className="food-box">
          <div className="food-content">
            {currentSpoonFood && currentSpoonFood.results && (
              <>
                {currentSpoonFood.results.map((el) => (
                  <div className="food-item" onClick={() => addId(el.id)}>
                    <h3>id: {el.id}</h3>
                    <h3>name: {el.name}</h3>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>

        {/* food to insert into db preview */}
        <div className="food-box">
          <div className="food-content">
            {currentFoodTranslated && (
              <>
                <h3>name:</h3>
                {currentFoodTranslated.name && (
                  <>
                    {languages.map((el) => (
                      <div className="food-item">
                        <h3>
                          name {el}: {currentFoodTranslated.name[el]}
                        </h3>
                        <TextField
                          value={currentFoodTranslated.name[el]}
                          variant="outlined"
                          // value={weight || 0}
                          onChange={(e) =>
                            handleCurrentFoodNameTranslated(e, el)
                          }
                        />
                      </div>
                    ))}
                  </>
                )}
                <h3>category:</h3>
                {currentFoodTranslated.category && (
                  <>
                    {languages.map((el) => (
                      <div className="food-item">
                        <h3>
                          name_category {el}:{" "}
                          {currentFoodTranslated.category[el]}
                        </h3>
                        <TextField
                          value={currentFoodTranslated.category[el]}
                          variant="outlined"
                          // value={weight || 0}
                          onChange={(e) =>
                            handleCurrentFoodCategoryTranslated(e, el)
                          }
                        />
                      </div>
                    ))}
                  </>
                )}

                <h3>nutrients:</h3>

                {edamameFood.nutrientArray.map((el) => (
                  <div className="food-item">
                    <h3>
                      {currentFoodTranslated.nutrient &&
                        currentFoodTranslated.nutrient[el] &&
                        currentFoodTranslated && (
                          <>
                            {currentFoodTranslated.nutrient[el]["label"]}:{" "}
                            {currentFoodTranslated.nutrient[el]["value"]}{" "}
                            {currentFoodTranslated.nutrient[el]["unit"]}
                          </>
                        )}
                      {/* {currentFoodTranslated.nutrient &&
                        currentFoodTranslated.nutrient[el] &&
                        currentFoodTranslated.nutrient[el]["label"](
                          <>{currentFoodTranslated.nutrient[el]["label"]}: </>
                        )} */}
                    </h3>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
