import React from "react";
import { Link } from "react-router-dom";
import { auth } from "../../firebase-config";
// import { onAuthStateChanged } from "firebase/auth";

import "./Sidebar.css";

export default function Sidebar(props) {
  // firebase
  // const [user, setUser] = useState({});
  // onAuthStateChanged(auth, (currentUser) => {
  //   setUser(currentUser);
  // })

  return (
    <div className={`sidebar-container ${props.sideState ? "active" : ""}`}>
      <div className="sidebar-logo-container">
        <div className="sidebar-logo"></div>
      </div>

      <ul>
        <li>
          <Link className="item" to="/">
            Home
          </Link>
        </li>
        <li>
          <Link className="item" to="/modpres">
            Start to build
          </Link>
        </li>

        {auth != null ? (
          <li className="down">
            <Link
              className="item"
              to="/account"
              onClick={() => props.navState(true)}
            >
              Account
            </Link>
          </li>
        ) : (
          <li className="down">
            <Link className="item" to="/Log">
              Account
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
}
