
import React, { useState } from 'react';
import "./Module.css"
import Card from '../../components/card/Card';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    background: "white",
    borderRadius: '20px',
    padding: '50px',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


export default function Module(props) {

    // general array
    const [titolo, setTitolo] = useState("Nome esercizio");
    const [cardList, setCardList] = useState([]);

    /*Modal get input text*/
    const [sets, setsSet] = useState([]);



    /*Card index to edit*/
    const [indice, setIndice] = useState();


    /*Modal 1*/
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    /*Modal 2*/
    const [open2, setOpen2] = useState(false);


    /*Temp single item*/
    const [singleItem, setSingleItem] = useState({ titolo: "", descrizione: [] });






    function handleOpen2(value) {

        setIndice(value);
        if (cardList.length > 0 && cardList[value] != null) {
            const tmp = cardList[value];
            setSingleItem(tmp);
            setTitolo(tmp.titolo);
            setsSet(tmp.descrizione);
            setOpen2(true);
        }

    }

    const handleClose2 = () => {
        setOpen2(false);
        setSingleItem({ titolo: "", descrizione: [] });
        setTitolo("");
        setsSet([]);
    }



    const handleAddiction = () => {
        handleOpen();
    }

    const handleClear = () => {
        setCardList([]);
    }

    const handleAddSet = () => {

        var tmp = [...sets, "default"];
        setsSet(tmp);

    }

    const handleAddSet2 = () => {

        var tmp = [...sets, "default"];
        singleItem.descrizione = tmp;
        setsSet(tmp);

    }


    function handleRemoveSet(ind) {

        let tmp = [];
        let c = 0;

        for (let d = 0; d < sets.length; d++) {
            if (d !== ind) {
                tmp[c] = sets[d];
                c++;
            }


        }

        setsSet(tmp);
        singleItem.descrizione = tmp;

    }

    function handleTextFieldChange(value, index) {

        let tmp = [...sets];

        if (index === "titolo") {
            setTitolo(value);
        } else {
            tmp[index] = value;
            setsSet(tmp);
        }

    }


    const handleSaveItem = () => {

        let tmp = { titolo: titolo, descrizione: sets };

        let tmp_list = [...cardList, tmp];
        setCardList(tmp_list);

        setTitolo("Nome esercizio");
        setsSet([]);
        handleClose();

    }


    /*Single card edit*/


    function handleCardTextFieldChange(value, inputInd) {

        let tmp = [...sets];

        if (inputInd === "titolo") {
            setTitolo(value);
            singleItem.titolo = value;
        } else {
            tmp[inputInd] = value;
            setsSet(tmp);
            singleItem.descrizione = tmp;
        }

    }


    function handleDeleteItem() {

        handleClose2();

        let tmp = [...cardList];
        let tmp_item = tmp[indice];

        let arr = [];
        let c = 0;
        for (let i = 0; i < cardList.length; i++) {
            if (tmp[i] !== tmp_item) {
                arr[c] = tmp[i]
                c++;
            }

        }
        setCardList(arr);
        setIndice(0);

    }

    const handleBlur = (e) => {
        e.target.value = "";
    }


    return (
        <div className={`${props.sideState ? "active" : ""}`}>
            <div className="module-container">

                <div className="module-container-box">


                    <h1>Start to create your workout</h1>

                    <div>
                        <button onClick={handleAddiction} className="module-add-btn">Add Exercise</button>
                        <button onClick={handleClear} className="module-add-btn">Clear workspace</button>

                    </div>



                    <div className="module-card-box">
                        {cardList.length > 0 && cardList.map((item, index) => {
                            return (<div key={index} onClick={() => handleOpen2(index)}><Card key={index} titolo={item.titolo} descrizione={item.descrizione} /> </div>);
                        })}
                    </div>

                    {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}


                    {cardList.length > 0 &&
                        <Modal
                            open={open2}
                            onClose={handleClose2}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <div style={style}>
                                <h1>Edit ex</h1>

                                <Grid item xs={12} md={6}>
                                    <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                                        Add sets to your excercise
                                    </Typography>

                                    <List dense={true}>

                                        <ListItem style={{ width: "100%", display: "flex", flexDirection: "column" }}>

                                            <TextField id="outlined-basic" label={titolo} placeholder={titolo} onBlur={handleBlur} variant="outlined" onChangeCapture={(e) => { handleCardTextFieldChange(e.target.value, "titolo") }} />
                                            {sets.length > 0 &&
                                                sets.map((sett, indexx) => {
                                                    return (
                                                        <div key={indexx} style={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: "100%" }}>
                                                            <TextField key={indexx} label={sett} placeholder={sett} variant="outlined" onBlur={handleBlur} onChange={(e) => handleCardTextFieldChange(e.target.value, indexx)} />
                                                            <h1 style={{ fontSize: "30px", cursor: "pointer" }} onClick={() => handleRemoveSet(indexx)}>-</h1>
                                                        </div>)
                                                })
                                            }

                                        </ListItem>
                                    </List>
                                </Grid>
                                <button className="modal-save-add" onClick={(handleAddSet2)}>Add set</button>

                                <button className="modal-save-add" onClick={(() => handleDeleteItem())}>Delete Item</button>



                            </div>

                        </Modal>
                    }


                    {/* ----------------------------------------------------------------------------------------------------------------------------------------------------- */}

                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div style={style}>
                            <TextField id="outlined-basic" label={titolo} placeholder={titolo} onBlur={handleBlur} variant="outlined" required onChangeCapture={(e) => { handleTextFieldChange(e.target.value, "titolo") }} />

                            <Grid item xs={12} md={6}>
                                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                                    Add sets to your excercise
                                </Typography>

                                <List dense={true}>

                                    <ListItem style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                        {
                                            sets.map((set, index) => {
                                                return (
                                                    <div key={index} style={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: "100%" }}>
                                                        <TextField key={index} label={set} placeholder={set} onBlur={handleBlur} variant="outlined" onChange={(e) => { handleTextFieldChange(e.target.value, index) }} />
                                                        <h1 style={{ fontSize: "30px", cursor: "pointer" }} onClick={() => handleRemoveSet(index)}>-</h1>
                                                    </div>)
                                            })
                                        }
                                    </ListItem>
                                </List>
                            </Grid>
                            <button className="modal-save-add" onClick={(handleAddSet)}>Add set</button>
                            <button className="modal-save-add" onClick={(handleSaveItem)}>Save</button>
                        </div>

                    </Modal>

                </div>
            </div>
        </div>
    )

}
