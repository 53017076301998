import React, { useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

//react select
import Select from "react-select";
import makeAnimated from "react-select/animated";

//icon
import HomeIcon from "@mui/icons-material/Home";

//transition
import Fade from "@mui/material/Fade";

//navigate
import { useNavigate, Outlet, useParams } from "react-router-dom";

//mui
import Button from "@mui/material/Button";

import "./Blog.css";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

const init = [
  {
    img: "https://source.unsplash.com/random",
    category: "football",
    title: "MONTHLY DOWNLOADS",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "football",
    title: "MONTHLY DOWNLOADS",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "football",
    title: "MONTHLY DOWNLOADS",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "football",
    title: "MONTHLY DOWNLOADS",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "football",
    title: "MONTHLY DOWNLOADS",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "swim",
    title: "gym-vs-home-workouts-what-gets-better-results",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "swim",
    title: "the-3-step-plan-to-stick-to-your-workout-routine",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "swim",
    title: "how to create a good workout schedule",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "swim",
    title: "MONTHLY DOWNLOADS",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "MONTHLY DOWNLOADS",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "MONTHLY DOWNLOADS",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "MONTHLY DOWNLOADS",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "MONTHLY DOWNLOADS",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "gym-vs-home-workouts-what-gets-better-results",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "the-3-step-plan-to-stick-to-your-workout-routine",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "how to create a good workout schedule",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "MONTHLY DOWNLOADS",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "MONTHLY DOWNLOADS",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "MONTHLY DOWNLOADS",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "MONTHLY DOWNLOADS",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "MONTHLY DOWNLOADS",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "gym-vs-home-workouts-what-gets-better-results",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "the-3-step-plan-to-stick-to-your-workout-routine",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "how to create a good workout schedule",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "MONTHLY DOWNLOADS",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "MONTHLY DOWNLOADS",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "MONTHLY DOWNLOADS",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "MONTHLY DOWNLOADS",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "MONTHLY DOWNLOADS",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "gym-vs-home-workouts-what-gets-better-results",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "the-3-step-plan-to-stick-to-your-workout-routine",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
  {
    img: "https://source.unsplash.com/random",
    category: "gym",
    title: "how to create a good workout schedule",
    subtitle:
      "Donec sed nunc ac diam euismod pharetra et sed mauris. Curabitur congue mauris a sollicitudin fringilla.",
  },
];

export default function Blog(props) {
  //medi query
  const matches = useMediaQuery("(max-width:700px)");

  //params from url
  //react router params
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      navigate("/" + id);
    }
  }, []);

  //go to top
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);

  //all list of blogs
  const [blogList, setBlogList] = useState(init);

  //blog item
  const [blogActive, setBlogActive] = useState(false);

  //search blog state
  const [filterBlog, setFilterBlog] = useState({
    value: "allSport",
    label: "AllSport",
  });

  //filter options
  const options = [
    { value: "allSport", label: "AllSport" },
    { value: "gym", label: "Gym" },
    { value: "swim", label: "Swim" },
    { value: "football", label: "Football" },
    { value: "basketball", label: "Basketball" },
    { value: "tennis", label: "Tennis" },
  ];

  // gestisce la visualizzazione in base al filtro applicato
  const handleChangeFilter = (selectedOption) => {
    setFilterBlog(selectedOption);
    cane(selectedOption);
  };

  const cane = (inc) => {
    if (inc.value === "allSport") {
      setBlogList(init);
      // console.log("in");
    } else {
      let arr = init.filter((el) => el.category === inc.value);
      setBlogList(arr);
      // console.log("in2", arr, filterBlog);
    }
  };

  //function to read specific blog
  let navigate = useNavigate();
  const readBlog = (title) => {
    // navigate("/blog/" + title);
  };

  //TODO APICALL TO BLOG POSTS

  //TODO API CALL FOR EXERCISE LIST

  return (
    <Fade in={true} {...(true ? { timeout: 500 } : {})}>
      {blogActive ? (
        <div className="blog-box">
          <Outlet />
        </div>
      ) : (
        <div className="blog">
          <div className="blog-img-slider center">
            {/* <div className="filter"></div> */}
            <h1>Blog</h1>
            {/* <img src="https://source.unsplash.com/3jAN9InapQI" alt="" /> */}
          </div>
          {matches && (
            <Select
              className="blog-select"
              styles={{ width: "100%", zIndex: "1000" }}
              placeholder="All Sport"
              defaultValue={{ value: "allSport", label: "AllSport" }}
              value={filterBlog}
              onChange={handleChangeFilter}
              options={options}
            />
          )}

          <div className="blog-filter-box flex-center">
            {!matches && (
              <Select
                styles={{ width: "100%" }}
                placeholder="All Sport"
                defaultValue={{ value: "allSport", label: "AllSport" }}
                value={filterBlog}
                onChange={handleChangeFilter}
                options={options}
              />
            )}
            {/* TODO map list from api sport category */}
            <div className="blog-filter">
              <h6>nutrition</h6>
              <h6>fitness</h6>
              <h6>lastest</h6>
              <h6>lifestyle</h6>
              <h6>fitness</h6>
            </div>
            {/* <div className="blog-filter-sport">
          <Button variant="outlined">gym</Button>
          <Button variant="outlined">swim</Button>
          <Button variant="outlined">football</Button>
          <Button variant="outlined">tennis</Button>
          <Button variant="outlined">basketball</Button>
        </div> */}
          </div>

          <div className="blog-home-box wd">
            {blogList.map((el, ind) => (
              <div
                key={ind + "a"}
                className="blog-home-item"
                onClick={() => {
                  readBlog(el.title);
                  // setBlogActive(true);
                }}
              >
                <div className="blog-home-item-img">
                  <img src={el.img} />
                </div>
                <h5>{el.category}</h5>
                <h3>
                  <a href="">{el.title}</a>
                </h3>
                <p>{el.subtitle}</p>
                <span>February 18, 2022</span>
                <div className="blog-link">
                  <a href="">CONTINUE READING</a>
                </div>
              </div>
            ))}
          </div>

          <Fade in={true} {...(true ? { timeout: 800 } : {})}>
            <div onClick={() => navigate("/")} className="arrow-class">
              <HomeIcon />
            </div>
          </Fade>
        </div>
      )}
    </Fade>
  );
}
