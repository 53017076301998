
import React from 'react';
import "./Card.css"

export default function Card(props) {


    return (

        <div className="card-container" style={{ margin: "10px" }} >

            <div className="sidebar-logo-container"><div className="sidebar-logo"></div></div>

            <h1> {props.titolo} </h1>



            {
                props.descrizione.map((row, index) => {

                    return (
                        <p key={index}>{row}</p>
                    )
                })


            }


        </div>
    )

}
