import { Button, TextField, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";

//material UI components
import Drawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";

//accordion
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//react select
import Select from "react-select";

//icons
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

//import stylesheet
import "./foodPlan.css";
import axios from "axios";

//notification
import { ToastContainer, toast } from "react-toastify";

import edamameFood from "../../food_api/edamameFoodItem.json";

export default function FoodPlan(props) {
  //dimension of the screen
  const dimension = useMediaQuery("(max-width:600px)");

  //week
  const week = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  //general food day object format
  const food_obj = {
    breakfast: [
      // {
      //   name: "banana",
      //   value: 80,
      //   misure: "g",
      //   energy: 320,
      // },
      // {
      //   name: "apple",
      //   value: 80,
      //   misure: "g",
      //   energy: 320,
      // },
      // {
      //   name: "bread",
      //   value: 80,
      //   misure: "g",
      //   energy: 320,
      // },
    ],
    launch: [
      // {
      //   name: "pasta",
      //   value: 80,
      //   misure: "g",
      //   energy: 320,
      // },
      // {
      //   name: "chicken",
      //   value: 80,
      //   misure: "g",
      //   energy: 320,
      // },
      // {
      //   name: "vegetables",
      //   value: 80,
      //   misure: "g",
      //   energy: 320,
      // },
    ],
    dinner: [
      // {
      //   name: "fish",
      //   value: 80,
      //   misure: "g",
      //   energy: 320,
      // },
      // {
      //   name: "chips",
      //   value: 80,
      //   misure: "g",
      //   energy: 320,
      // },
      // {
      //   name: "vegetables",
      //   value: 80,
      //   misure: "g",
      //   energy: 320,
      // },
    ],
    snack: [
      // {
      //   name: "banana",
      //   value: 80,
      //   misure: "g",
      //   energy: 320,
      // },
      // {
      //   name: "apple",
      //   value: 80,
      //   misure: "g",
      //   energy: 320,
      // },
      // {
      //   name: "bread",
      //   value: 80,
      //   misure: "g",
      //   energy: 320,
      // },
    ],
    total: {
      prot: 0,
      carb: 0,
      fat: 0,
      cal: 0,
    },
  };

  //general food week object to upload to the DB on the FOOD user DB (like plan)
  //   const week_food = {
  //     Monday: {},
  //     Tuesday: {},
  //     Wednesday: {},
  //     Thursday: {},
  //     Friday: {},
  //     Saturday: {},
  //     Sunday: {},
  //   };

  //just for testing
  const [week_food, setWeekFood] = useState({
    Monday: food_obj,
    Tuesday: food_obj,
    Wednesday: food_obj,
    Thursday: food_obj,
    Friday: food_obj,
    Saturday: food_obj,
    Sunday: food_obj,
  });

  //food search otions
  const foodMeal = [
    {
      value: "breakfast",
      label: "breakfast",
    },
    {
      value: "launch",
      label: "launch",
    },
    {
      value: "dinner",
      label: "dinner",
    },
    {
      value: "snack",
      label: "snack",
    },
  ];

  //local variables

  //drawer variable
  const [open, setOpen] = useState(false);

  //bottom inside drawer for food search
  const [open2, setOpen2] = useState(false);

  //Object of all daily food program
  const [food, setFood] = useState({});

  //current food day item clicked
  const [dayFood, setDayFood] = useState("");

  //bottom drawer meal variables
  //select variables //like breakfast, launch, dinner
  const [currMealToModify, setCurrMealToModify] = useState("");

  //searchfood status variable
  const [searchFood, setSearchFood] = useState("");
  const handleSearchFood = (e) => {
    setSearchFood(e.target.value);
  };

  //object array recovered from the search of the searchFood
  const [searchFoodArray, setSearchFoodArray] = useState([]);

  //variabile che rappresenta lo stato se un item risultato dalla ricerca
  //è stato selezionato o meno
  const [foodSelected, setFoodSelected] = useState(false);

  //variabile che rappresenta il food selezionato dalla ricerca API
  const [currentFoodSelected, setCurrentFoodSelected] = useState({});
  const [currentFoodSelectedTemp, setCurrentFoodSelectedTemp] = useState({});

  //variabile che rappresenta la quantità di cibo inserita in un gionro
  const [foodAmount, setFoodAmount] = useState(100);

  //export notification
  const [ExportNotifivation, setExportNotifivation] = useState(false);

  //pdf form
  const [pdfName, setPdfName] = React.useState("");

  const handlePdfName = (name) => {
    setPdfName(name);
  };

  //drawer handle function
  const handleClose = () => {
    setOpen(false);
    clear();
  };
  const handleOpen = () => {
    setOpen(true);
  };

  //drawer search food handle function
  const handleClose2 = () => {
    setOpen2(false);
    // clear();  Bisogna vedere
    clearAllSearch();
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };

  //bottom drawer select handlee change function
  const handleChangeMealSelected = (selectedOption) => {
    setCurrMealToModify(selectedOption);
  };

  //functions

  //clear function on drawer close
  const clear = () => {
    setFood({});
    setDayFood("");
  };

  //clear food search
  function clearSearch() {
    setSearchFood("");
    clearAllSearch();
  }

  //funzione che permette di accedere/modificare la pianificazione settimanale di un determinato giorno
  const handleEditFoodDay = (day) => {
    setFood(week_food[day]);
    setDayFood(day);
    handleOpen();
  };

  //funzione che viene cliccata una volta che i dati sono stati ricevuti dalla chiamata api
  //e che l'utente sceglie per inserire nella sua routine giornaliera
  const handleFoodSearchItemSelected = (obj) => {
    setCurrentFoodSelected(obj);

    // let tmp = {};

    // obj.nutrient.map((el) => {
    //   tmp = { ...tmp, [el.name]: el.amount };
    // });

    setCurrentFoodSelectedTemp(obj);

    setFoodSelected(true);
  };

  //funzione che permette di cambiare la quantità di cibo che si vuole inserire in un determinato pasto
  const handleFoodAmountChange = (e) => {
    setFoodAmount(e.target.value);
  };

  //FUNZIONE che definisce e calcola la quantità di prodotto da inserire
  const handleFoodChanged = () => {
    if (foodAmount > 0 && currentFoodSelectedTemp) {
      let tmp = JSON.parse(JSON.stringify(currentFoodSelectedTemp));
      if (tmp.nutrient) {
        edamameFood.nutrientArray.map((el, index) => {
          let tmp_val = (tmp.nutrient[el]["value"] / 100) * foodAmount;

          if (el === "Kcal") {
            tmp.nutrient[el]["value"] = Math.round(tmp_val);
          } else {
            tmp.nutrient[el]["value"] = parseFloat(tmp_val.toFixed(2));
          }
        });
        setCurrentFoodSelected(tmp);
      }
    }
  };

  const calcTotalNutrientOfDailyFood = (val) => {
    let tmp = { ...food["total"] };

    ["prot", "carb", "fat", "cal"].map((el) => {
      let tmp_val = food["total"][el] + val[el];

      tmp[el] = parseFloat(tmp_val.toFixed(2));
    });

    return tmp;
  };

  //funzione che permette effettivamente di inserire il cibo all'interno della giornata odierna
  const handleInsertFood = () => {
    if (!currMealToModify.label) return; //TODO NOTIFICATION
    // currentFoodSelected
    let tmp = { ...food };

    //TODO
    let tmp_food = {
      name: currentFoodSelected["name"]["it"], //TODO USERLANG
      misure: "g",
      value: foodAmount,
      energy: currentFoodSelected["nutrient"]["Kcal"]["value"],
      prot: currentFoodSelected["nutrient"]["Protein"]["value"],
      carb: currentFoodSelected["nutrient"]["Carbohydrate"]["value"],
      fat: currentFoodSelected["nutrient"]["Fat"]["value"],
      cal: currentFoodSelected["nutrient"]["Kcal"]["value"],
    };

    let total = calcTotalNutrientOfDailyFood(tmp_food);

    tmp["total"] = total;

    tmp[currMealToModify.label] = [...tmp[currMealToModify.label], tmp_food];

    setFood(tmp);
    handleClose2();
  };

  //funzione che permette di aggiornare il cibo assunto un determinato giorno nell'intera settimana
  const handleInsertFoodWeek = () => {
    let tmp = { ...week_food };
    tmp[dayFood] = food;
    setWeekFood(tmp);
    handleClose();
  };

  //API CALL to retrive data

  //api call to get food search
  const getFood = async () => {
    await axios
      .get("https://yoxeen.ks-hub.com:4000/food", {
        params: { name: searchFood },
      })
      .then((res) => {
        setSearchFoodArray(res.data);
      })
      .catch((err) => console.log(err));
  };

  //api call to get pdf from food week
  const getWeekFoodPdf = async () => {
    const id = toast.loading("Your pdf is converting...");

    await axios
      .post(
        "https://yoxeen.ks-hub.com:4000/pdfWeekFood",
        { weekFood: week_food },
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
        }
      )
      .then((response) => {
        toast.update(id, {
          render: "Pdf converted successfully",
          type: "success",
          isLoading: false,
          autoClose: 800,
        });
        setExportNotifivation(false);
        //TODO notification untill pdf is fetched
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        // link.download = pdfName + ".pdf"; TODO insert pdf form form name
        link.download = "weekFood" + ".pdf";
        link.click();
        //  this.closeModal() // close modal
      })
      .catch((err) => {
        toast.update(id, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 400,
        });
        console.log(err);
      });
  };

  //clear function
  const clearAllSearch = () => {
    setCurrentFoodSelected({});
    setCurrentFoodSelectedTemp({});
    setSearchFoodArray([]);
    setCurrMealToModify("");
    // setDayFood("");
    setSearchFood("");
    setFoodSelected(false);
    setFoodAmount(100);
  };

  return (
    <div className="foodPlan">
      <ToastContainer />

      <div className="foodPlan-container">
        <div className="foodPlan-title">
          <h1>Plan your week food routine!</h1>
          <h6>Contact our expert to get support in your daily routine</h6>
          <Button variant="outlined" onClick={getWeekFoodPdf}>
            Export to pdf
          </Button>
          <Button variant="outlined">Save</Button>
          <Button variant="outlined">Delete</Button>
        </div>

        {/* display your weekle main info for all the week*/}
        {/* i have to decide if to keep this section */}
        {/* <div className="foodPlan-info">
          {["Prot", "Carb", "Fat", "Cal"].map((el, index) => (
            <div className="foodPlan-info-item">
              <h3>{el}</h3>
              <h3>0</h3>
            </div>
          ))}
        </div> */}

        {/* display food plan */}

        {week.map((el) => (
          <div className="foodPlan-day">
            <div className="foodPlan-day-title">
              <h3 className="foodPlan-day-title-desc gap10">
                {el}{" "}
                <div className="flex gap10">
                  {["prot", "carb", "fat", "cal"].map((macro, index) => (
                    <div className="flex">
                      {week_food && week_food[el]["total"] && (
                        <>
                          {week_food &&
                          week_food["total"] &&
                          week_food["total"]["prot"] === 0 &&
                          week_food["total"]["carb"] === 0 &&
                          week_food["total"]["fat"] === 0 &&
                          week_food["total"]["cal"] === 0 ? (
                            <></>
                          ) : (
                            <p>
                              {macro} : {week_food[el]["total"][macro]}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </h3>
              <MoreVertIcon
                onClick={() => handleEditFoodDay(el)}
                style={{ cursor: "pointer" }}
              />
            </div>

            {/* all the daily food list divided by category inside accordions */}
            {/* TODO: implement alse the language */}
            {["breakfast", "launch", "dinner", "snack"].map((meal, index) => (
              <div>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{meal}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </Typography> */}
                    <ul>
                      {week_food[el][meal].map((mealIn, index) => (
                        <li>
                          {mealIn.name}: {mealIn.value}g
                        </li>
                      ))}
                    </ul>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        ))}
      </div>

      <Drawer
        anchor={dimension ? "bottom" : "right"}
        // className="drawer-btn"
        variant="temporary"
        // anchor="right"
        open={open}
        onClose={handleClose}
      >
        <div className="foodPlan-drawer">
          <div className="foodPlan-drawer-title">
            <h3>{dayFood}</h3>
            <Button variant="outlined" onClick={handleClose}>
              X
            </Button>
          </div>

          {["breakfast", "launch", "dinner", "snack"].map((meal, index) => (
            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{meal}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </Typography> */}
                  <ul>
                    {food &&
                      food[meal] &&
                      food[meal].map((mealIn, index) => <li>{mealIn.name}</li>)}
                  </ul>
                </AccordionDetails>
              </Accordion>
            </div>
          ))}

          <div className="drawer-total-box">
            {food && food["total"] && food["total"]["cal"] && (
              <h3 style={{ margin: "5px" }}>kcal {food["total"]["cal"]}</h3>
            )}
            <div className="drawer-total">
              {["prot", "carb", "fat", "cal"].map((el, index) => (
                <>
                  {food && food["total"] && food["total"][el] && (
                    <>
                      {el !== "cal" && (
                        <h3 style={{ margin: "5px" }}>
                          {el}:{food["total"][el]} g
                        </h3>
                      )}
                    </>
                  )}
                </>
              ))}
            </div>
          </div>

          <Button variant="outlined" onClick={handleInsertFoodWeek}>
            Save
          </Button>

          <div className="foodPlan-drawer-container">
            <div className="drawer-container-title">
              <h5>Add food to your daily plan</h5>
              <Button variant="outlined" onClick={handleOpen2}>
                Add
              </Button>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer
        className="drawer-btn"
        variant="temporary"
        anchor="bottom"
        open={open2}
        onClose={handleClose2}
      >
        <div className="foodPlan-search">
          <div className="foodPlan-drawer-title">
            <h3>"Search for a food"</h3>
            <Button
              //   style={{
              //     padding: "5px 5px 5px 5px",
              //     lineHeight: "0",
              //     margin: "0",
              //     minWidth: "0",
              //     minHeight: "0",
              //     border: "solid 1px black",
              //   }}
              variant="outlined"
              onClick={handleClose2}
            >
              X
            </Button>
          </div>

          <Select
            styles={{ width: "100%" }}
            className="profile-select"
            placeholder="Select a meal"
            // defaultValue={{ value: "Select", label: "Select" }}
            value={currMealToModify}
            onChange={handleChangeMealSelected}
            options={foodMeal}
          />

          <div className="foodPlan-drawer-container">
            <div className="drawer-container-title">
              <h5>Search for a food in the database</h5>
              <TextField
                sx={{ marginTop: 1, minWidth: "350px" }}
                value={searchFood}
                onChange={handleSearchFood}
                label={""}
                autoComplete="off"
                InputLabelProps={{ shrink: false }}
                placeholder={"Search for any exercise in the database"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchFood !== "" && (
                        <CloseIcon
                          style={{ cursor: "pointer" }}
                          onClick={clearSearch}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <Button onClick={getFood} variant="outlined">
                Search
              </Button>
            </div>

            {!foodSelected && (
              <div className="search-container">
                {searchFoodArray && (
                  <>
                    {searchFoodArray.map((el, index) => (
                      <div
                        style={{ borderBottom: "solid 2px gray" }}
                        className="search-item"
                        onClick={() => handleFoodSearchItemSelected(el)}
                      >
                        {el.nutrient && (
                          <div style={{ width: "100%" }}>
                            <div
                              className="search-item-desc"
                              style={{ justifyContent: "space-between" }}
                            >
                              <h4>nome:{el["name"]["it"]}</h4>
                              <h4>500 kcal</h4>
                            </div>

                            <div className="search-item-desc-2">
                              {/* {el.nutrient.map((nutr, ind) => (
                                <>
                                  <div className="search-item-desc">
                                    <h4>
                                      {nutr.name !== "Calories" && nutr.name}:
                                    </h4>
                                    <h4>
                                      {nutr.name !== "Calories" && nutr.amount}
                                    </h4>
                                    <h4>
                                      {nutr.name !== "Calories" && nutr.unit}
                                    </h4>
                                  </div>
                                </>
                              ))} */}
                              <div className="search-item-desc">
                                <h4>
                                  {el.nutrient["Protein"]["label"]}:{" "}
                                  {el.nutrient["Protein"]["value"]}{" "}
                                  {el.nutrient["Protein"]["unit"]}
                                </h4>
                                <h4>
                                  {el.nutrient["Carbohydrate"]["label"]}:{" "}
                                  {el.nutrient["Protein"]["value"]}{" "}
                                  {el.nutrient["Protein"]["unit"]}
                                </h4>
                                <h4>
                                  {el.nutrient["Fat"]["label"]}:{" "}
                                  {el.nutrient["Protein"]["value"]}{" "}
                                  {el.nutrient["Protein"]["unit"]}
                                </h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                )}
                {/* {searchFoodArray.nutrient && (
                <>
                  {searchFoodArray.nutrient.map((nutr, index) => (
                    <div>
                      {nutr.name === "Protein" ||
                        nutr.name === "Carbohydrates" ||
                        nutr.name === "Fat" ||
                        nutr.name === "Trans Fat" ||
                        nutr.name === "Protein" ||
                        (nutr.name === "Sodium" && <h4>{nutr.name}</h4>)}
                    </div>
                  ))}
                  <div className="drawer-food-item">
                    <h4></h4>
                  </div>
                </>
              )} */}
              </div>
            )}

            {foodSelected && (
              <div className="food-selected-container">
                {currentFoodSelected && (
                  <div className="food-selected-box">
                    <Button onClick={() => setFoodSelected(false)}>back</Button>
                    <div className="food-selected-info">
                      {currentFoodSelected && currentFoodSelected.nutrient && (
                        <>
                          <div
                            className="search-item-desc"
                            style={{ justifyContent: "space-between" }}
                          >
                            <h4>nome:{currentFoodSelected["name"]["it"]}</h4>
                            <h4>
                              {currentFoodSelected.nutrient["Kcal"]["unit"]}{" "}
                              {currentFoodSelected.nutrient["Kcal"]["value"]}
                            </h4>
                          </div>
                          <div className="search-item-desc">
                            <div className="search-item-desc-2">
                              <h4>Carbs</h4>
                              <h4>
                                {
                                  currentFoodSelected.nutrient["Carbohydrate"][
                                    "value"
                                  ]
                                }
                              </h4>
                              <h4>
                                {
                                  currentFoodSelected.nutrient["Carbohydrate"][
                                    "unit"
                                  ]
                                }
                              </h4>
                            </div>
                            <div className="search-item-desc-2">
                              <h4>Prot</h4>
                              <h4>
                                {
                                  currentFoodSelected.nutrient["Protein"][
                                    "value"
                                  ]
                                }
                              </h4>
                              <h4>
                                {
                                  currentFoodSelected.nutrient["Protein"][
                                    "unit"
                                  ]
                                }
                              </h4>
                            </div>
                            <div className="search-item-desc-2">
                              <h4>Fat</h4>
                              <h4>
                                {currentFoodSelected.nutrient["Fat"]["value"]}
                              </h4>
                              <h4>
                                {currentFoodSelected.nutrient["Fat"]["unit"]}
                              </h4>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="food-selected-value">
                      <TextField
                        type="number"
                        value={foodAmount}
                        placeholder="Amount in g of food"
                        onChange={handleFoodAmountChange}
                      />
                      <Button onClick={handleFoodChanged}>Ok</Button>

                      <Button onClick={handleInsertFood}>Insert</Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
}
