import React from "react";
import Button from "@mui/material/Button";

//transition
import Fade from "@mui/material/Fade";

//slider
import ImageSlider from "../../components/carousel/ImageSlider";

//accordion
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//checkbox
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

//icons
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import SportsMartialArtsIcon from "@mui/icons-material/SportsMartialArts";
import DeckIcon from "@mui/icons-material/Deck";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CampaignIcon from "@mui/icons-material/Campaign";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import PeopleIcon from "@mui/icons-material/People";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import EventNoteIcon from "@mui/icons-material/EventNote";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";

//navigation
import { useNavigate, useLocation } from "react-router-dom";

import "./Home.css";
import Build from "../../images/build-1.jpg";
import { TextField } from "@mui/material";

import smoothscroll from "smoothscroll-polyfill";

// kick off the polyfill!
smoothscroll.polyfill();

export default function Home(props) {
  // const t = `home-container ${props.sideState ? "active" : ""}`

  // console.log("ciaoone", t);

  //navigation
  let navigate = useNavigate();

  const translateSlide = () => {
    let container = document.querySelector(".home-container");

    // console.log("entrato", container);
    window.scrollTo({
      top: (105 * window.innerHeight) / 100,
      behavior: "smooth",
    });
  };

  // //go to top
  // useEffect(() => {
  //   document.body.scrollTop = 0; // For Safari
  //   document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  // }, []);

  return (
    <Fade in={true} {...(true ? { timeout: 500 } : {})}>
      <div className={`${props.sideState ? "active" : ""}`}>
        <div className="home-container">
          <section className="hero">
            <div className="hero-img">
              {/* <img src={require("../../images/women.jpg")} /> */}
            </div>
            <div className="title">
              <h1>Each new day is a new opportunity to improve yourself!</h1>
              <p>Train with me for total body wellness</p>
              <Button
                onClick={() => navigate("/account")}
                variant="outlined"
                style={{ color: "white", borderColor: "white" }}
                className="beta-btn"
              >
                START NOW
              </Button>
            </div>

            <div
              onClick={translateSlide}
              className="down-btn"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <KeyboardArrowDownIcon
                style={{ transform: "translateY(20px)" }}
                fontSize="large"
              />
              <KeyboardArrowDownIcon fontSize="large" />
            </div>
          </section>
          {/* <section className="hero">
            <div className="hero-img">
              <img src={require("../../images/women.jpg")} />
            </div>
            <div className="title">
              <h1>BENVENUTO A YOXEA</h1>
              <h3>
                Arriva il miglior programma di allenamento affiancato alla tua
                routin quotidiana
              </h3>
              <a
                href="https://www.google.it"
                target="_blank"
                rel="noopener noreferrer"
              >
                See more
              </a>
            </div>
          </section> */}
          <section id="project" className="project padd-v-50">
            <div className="project-container wd-s">
              <div className="project-text">
                <h1>Where all begins</h1>
                <p>
                  The project was born as a fitness platform in support of
                  athletes. The goal of the application is to provide support
                  for building your own training schedule. Unlike other similar
                  applications, Yoxeen incorporates a large database in support
                  of many sports.{" "}
                </p>

                {/**  <a href="https://www.google.it" target="black">Buy land</a> */}
              </div>
              <div className="project-img">
                <img src="https://source.unsplash.com/CQfNt66ttZM" alt="" />
              </div>
            </div>
          </section>
          <section id="project" className="project padd-v-50">
            <div className="project-container reverse wd-s">
              <div className="project-text">
                <h1>Your plan starts here!</h1>
                <p>
                  To allow you to help you in your path of change, YOXEEN web
                  app has been developed. This platform will allow you to keep
                  track of the main daily activities, connect your google Fit
                  account to view your weekly report, prepare your weekly plan
                  by adding notes for each workout and above all create, view
                  and share your own training schedules. All this just for you!
                  In addition, for the more curious, it will be possible to
                  consult the dedicated blog, which will always be updated by
                  our experts in the sector.{" "}
                </p>

                {/**  <a href="https://www.google.it" target="black">Buy land</a> */}
              </div>
              <div className="project-img">
                <img src="https://source.unsplash.com/fS3tGOkp0xY" alt="" />
              </div>
            </div>
          </section>
          <section id="project" className="project padd-v-50">
            <div className="project-container wd-s">
              <div className="project-text">
                <h1>Come to join our Team</h1>
                <p>
                  Thousands of people have already joined Yoxeen. The new free
                  fitness app will allow you to reach your goals in record time.
                  Furthermore you will be supported by our fitness comunity for
                  any doubts or thoughts you want to expose. In fact, our app is
                  active on all social channels ready to make itself available
                  for you.{" "}
                </p>

                {/**  <a href="https://www.google.it" target="black">Buy land</a> */}
              </div>
              <div className="project-img">
                <img src="https://source.unsplash.com/J154nEkpzlQ" alt="" />
              </div>
            </div>
          </section>

          <section id="project" className="project padd-v-50">
            <div className="project-container reverse wd-s">
              <div className="project-text">
                <h1>Are you an expert?</h1>
                <p>
                  Search your city and find expert in your location. Contact,
                  filter by type and then get in touch{" "}
                </p>

                {/**  <a href="https://www.google.it" target="black">Buy land</a> */}
              </div>
              <div className="project-img">
                <img src="https://source.unsplash.com/Q1p7bh3SHj8" alt="" />
              </div>
            </div>
          </section>
          {/* <section
            id="project"
            className="project"
            data-aos="fade-in"
            data-aos-duration="1000"
          >
            <div className="project-container reverse wd">
              <div className="project-text">
                <h1>Buy Miles</h1>
                <p>
                  RTM, acronym of Road To Mars, is a project with the aim to
                  discovery and create a new place where human can virtually
                  live. The Map is a realistic concept based on the countless
                  nasa’s surveys; it has been separated by a grid that
                  subdivides the atlas in sectioned virtual environments, which
                  staffs funny named MILEs (as fusion of Mars Tiles). Users can
                  buy, sell, own, use and customize the MILEs in order to own
                  areas of interest and resources that will provide the
                  necessary to customize MILEs.{" "}
                </p>

                
              </div>
              <div className="project-img">
                <img src="https://source.unsplash.com/random" alt="" />
              </div>
            </div>
          </section> */}
          {/* <section className="sport">
            <div className="sport-title">
              <h1>ALL SPORTS IN ONE APP</h1>
              <h3>Read more about our workout collection and explore </h3>
            </div>
            <div className="sport-box">
              <div className="sport-item">
                <h3>Calcio</h3>

                <div className="layer"></div>
              </div>
              <div className="sport-item">
                <h3>Palestra</h3>
                <div className="layer"></div>
              </div>
              <div className="sport-item">
                <h3>Nuoto</h3>
                <div className="layer"></div>
              </div>
              <div className="sport-item">
                <h3>Box</h3>
                <div className="layer"></div>
              </div>
              <div className="sport-item">
                <h3>Basket</h3>
                <div className="layer"></div>
              </div>
            </div>
          </section>
          <section className="explore-home">
            <div className="explore-item a">BUILD</div>
            <div className="explore-item b">EXPLORE</div>
            <div className="explore-item c">SHARE</div>
          </section>
          <section className="expected">
            <div className="expected-title">
              <h1>WHAT ARE YOU GOING TO FIND ?</h1>
            </div>
            <div className="expected-box">
              <div className="expected-item">
                <img
                  src="https://source.unsplash.com/random"
                  width="30px"
                  height="30px"
                />
                <h2>Blog</h2>
              </div>
              <div className="expected-item">
                <img
                  src="https://source.unsplash.com/random"
                  width="30px"
                  height="30px"
                />
                <h2>Workout</h2>
              </div>
              <div className="expected-item">
                <img
                  src="https://source.unsplash.com/random"
                  width="30px"
                  height="30px"
                />
                <h2>Tips</h2>
              </div>
            </div>
          </section>

          <section className="progress-home">
            <h1>
              TAKE CARE ABOUT YOUR PROGRESS AND SEE HOW GOOD IS YOUR WORKOUT!
            </h1>
            <div className="progress-home-box">
              <div className="progress-home-item">Weight and Daily section</div>
              <div className="progress-home-item">Workout summer</div>
              <div className="progress-home-item">Google Fit integration</div>
            </div>
          </section>

          <section className="funct">
            <div className="funct-title">JOIN THE GREATEST TEAM IN SPORTS</div>
            <div className="funct-box">
              <div className="funct-item">
                <DeckIcon />
                <h5>Member Exclusive Products</h5>
                <p>
                  Early access to our latest drops, exclusives just for you and
                  your favorite products reserved in your size.
                </p>
              </div>
              <div className="funct-item">
                <EmojiTransportationIcon />
                <h5>Expert Guidance & Advice</h5>
                <p>
                  Your Nike Experts are real athletes with real answers. Chat
                  with them to get recommendations on training and products you
                  can trust.
                </p>
              </div>
              <div className="funct-item">
                <SportsBasketballIcon />
                <h5>Access To Sport, Anytime, Anywhere</h5>
                <p>
                  100+ on-the-go workouts, weekly challenges and personalized
                  training plans that adapt to your goals and schedule.
                </p>
              </div>
              <div className="funct-item">
                <SportsMartialArtsIcon />
                <h5>Inspiring Experiences</h5>
                <p>
                  From courtside to race day, get priority access to the events
                  and sessions you don’t want to miss.
                </p>
              </div>
            </div>
          </section> */}

          <section className="text padd-v-50">
            <div className="text-title wd-t">
              <h2>Keep the motivation</h2>
            </div>

            <div className="text-box wd-r">
              <p>
                In order not to make everything too boring, our experts have
                created a special section that will allow you to dynamically
                view some preset training cards which will help you start,
                continue or maintain your growth path.
              </p>
            </div>
          </section>
          <section className="iphone padd-v-50">
            <div className="iphone-container ">
              <div className="iphone-box">
                <div className="iphone-img">
                  <img src={require("../../images/iphone_d.png")} />
                </div>
                <div className="iphone-slider">
                  <ImageSlider />
                </div>
              </div>
              <div className="iphone-desc">
                <h4>WHAT'S INSIDE</h4>
                <h2>Programs designed for you</h2>
                <p>
                  Workouts, plan, schedule, progess session, all in one app. We
                  know very well how much this difference can make and in fact
                  it is precisely from here that we will start. Stay up to date
                  with the news that will be in the next updates.
                </p>

                <Button variant="contained">Start today for only 1$/m</Button>
              </div>
            </div>
          </section>
          <section className="iphone padd-v-50">
            <div className="iphone-container rev">
              <div className="iphone-desc">
                <h4>OUR TEAM</h4>
                <h2>Join us</h2>
                <p>
                  We are ready to start giving you a hand, hence a quick
                  introduction to the people who will allow you to continue your
                  journey in the world of fitness and sport. We are a young
                  group with years of experience ready to be available to help
                  you.
                </p>

                <Button variant="contained">Contac us</Button>
              </div>
              <div className="iphone-box rev-box">
                <div className="iphone-img">
                  <img src={require("../../images/iphone_d.png")} />
                </div>
                <div className="iphone-slider">
                  <ImageSlider />
                </div>
              </div>
            </div>
          </section>

          <section className="home-info padd-v-50">
            <div className="home-info-box wd">
              <div className="home-info-box-item">
                <h5>ABOUT US</h5>
                <h1>EASY ACCESS TO A VARIETY OF CLASSES</h1>
              </div>
              <div className="home-info-box-item">
                <p>
                  We offer a wide range of group classes on a drop-in basis, so
                  there's always something to fit your mood and fitness level.
                </p>
                <p>
                  Our Club offers group yoga classes, cardio workouts and
                  resistance training sessions
                </p>
              </div>
              <div className="home-info-box-item">
                <p>
                  We will help you get in the best shape of your life thanks to
                  an extensive selection of wellness, group fitness, and
                  personal training options.
                </p>
                <h2>OVER THEN 100 CLASSES</h2>
              </div>
            </div>
          </section>

          <section className="intro padd-v-50">
            <div className="intro-title">
              <h2 className="pad">Membership perks</h2>
            </div>
            <div className="intro-container">
              <div className="intro-box">
                <CampaignIcon style={{ fill: "#ddc469", fontSize: "55px" }} />
                <h3>Stay motivated</h3>
                <p>
                  I post new workouts regularly so you can stay motivated and
                  accountable. With all my offerings, you'll never get bored of
                  your workout routine.
                </p>
              </div>
              <div className="intro-box">
                <OndemandVideoIcon
                  style={{ fill: "#ddc469", fontSize: "55px" }}
                />
                <h3>Easy-to-follow videos</h3>
                <p>
                  Know you're training the right way with my easy-to-follow
                  videos and simple instructions.
                </p>
              </div>
              <div className="intro-box">
                <PeopleIcon style={{ fill: "#ddc469", fontSize: "55px" }} />

                <h3>Community</h3>
                <p>
                  Join my community to share your fitness journey, stay
                  accountable, and connect with others.
                </p>
              </div>
              <div className="intro-box">
                <LocalDiningIcon
                  style={{ fill: "#ddc469", fontSize: "55px" }}
                />
                <h3>Nutrition made simple</h3>
                <p>
                  Learn exactly what to eat when, get grocery lists, and find
                  out the foods that will keep you fueled and energized.
                </p>
              </div>
              <div className="intro-box">
                <EventNoteIcon style={{ fill: "#ddc469", fontSize: "55px" }} />
                <h3>Workout programs</h3>
                <p>
                  My workout programs are made for all fitness levels to help
                  you achieve your goals.
                </p>
              </div>
              <div className="intro-box">
                <GraphicEqIcon style={{ fill: "#ddc469", fontSize: "55px" }} />
                <h3>Talk to me</h3>
                <p>
                  Message me directly in the app to ask me any questions you may
                  have and request your favorite content.
                </p>
              </div>
            </div>
          </section>

          <section className="home-info padd-v-50">
            <div className="home-info-box wd rev">
              <div className="home-info-box-item">
                <p>
                  Becoming a Yoxeen membership you will able to get in touch
                  with our team of experts. In addition you can also get private
                  session directly in our web app.
                </p>
                <h2>CHAT WITH US</h2>
              </div>
              <div className="home-info-box-item">
                <p>
                  We offer an amazing experience with a lot of experts ready to
                  help you. Hundreds of workout plan are ready for you.
                </p>
                <p>
                  Our package allows you to acces to a lot of content. There's
                  also a Blog section where you can find our experts' articles
                  that may hel you to keep your goal.
                </p>
              </div>

              <div className="home-info-box-item">
                <h5>MEMBERSHIP</h5>
                <h1>EASY ACCESS TO ALL CONTENT</h1>
              </div>
            </div>
          </section>

          {/* <section className="blog-home">
            <div className="blog-home-title wd-t">
              <h2>BLOG POSTS</h2>
            </div>
            <div className="blog-home-box wd">
              <div className="blog-home-item">
                <div className="blog-home-item-img">
                  <img src="https://source.unsplash.com/random" />
                </div>
                <h3>
                  <a href="https://www.google.it">
                    HOW TO JOIN FIT BODY'S SPRING SHRED CHALLENGE!
                  </a>
                </h3>
                <p>February 18, 2022</p>
                <div className="blog-link">
                  <a href="https://www.google.it">CONTINUE READING</a>
                </div>
              </div>
              <div className="blog-home-item">
                <div className="blog-home-item-img">
                  <img src="https://source.unsplash.com/random" />
                </div>
                <h3>
                  <a href="https://www.google.it">
                    HOW TO JOIN FIT BODY'S SPRING SHRED CHALLENGE!
                  </a>
                </h3>
                <p>February 18, 2022</p>
                <div className="blog-link">
                  <a href="">CONTINUE READING</a>
                </div>
              </div>
              <div className="blog-home-item">
                <div className="blog-home-item-img">
                  <img src="https://source.unsplash.com/random" />
                </div>
                <h3>
                  <a href="https://www.google.it">
                    HOW TO JOIN FIT BODY'S SPRING SHRED CHALLENGE!
                  </a>
                </h3>
                <p>February 18, 2022</p>
                <div className="blog-link">
                  <a href="https://www.google.it">CONTINUE READING</a>
                </div>
              </div>
            </div>
            <Button
              style={{ width: "150px", height: "45px" }}
              variant="contained"
            >
              view more
            </Button>
          </section> */}

          {/* <section className="blog">
            <div className="blog-box">
              <div className="blog-title">
                <h2>JOIN THE COMMUNITY</h2>
              </div>
              <div className="blog-desc">
                <h4>Follow more fitness tips in our dedicated section</h4>
              </div>
              <Button style={{ width: "100px" }} variant="contained">
                blog
              </Button>
            </div>
          </section> */}

          <section className="subscribe center">
            <div className="subscribe-box">
              <div className="subscribe-img">
                <img src="https://source.unsplash.com/zQNDCje06VM" alt="" />
              </div>
              <div className="subscribe-form center wd">
                <h2>
                  Subscribe to my newsletter for exclusive offers & workout tips
                </h2>
                <div className="form-box">
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Subscribe to the newsletter"
                  />
                  <Button className="form-btn" variant="contained">
                    Send
                  </Button>
                </div>
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Yes, I accept the terms and conditions. Read more"
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </section>

          <section className="subscribe center padd-v-50">
            <div className="home-faq wd">
              <h2>Frequently Asked Questions</h2>

              <div className="faq-box">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="accordion-title px-22 px-24">
                      How much does the app cost?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="accordion-desc px-24">
                      You can join my channel and community for just $1 for the
                      first month. After that, you'll pay $14.99/month or
                      $99.99/year. That breaks down to just $0.49 a day or $0.28
                      a day. You get access to endless health content every day
                      for less than a cup of coffee!
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="accordion-title px-22 px-24">
                      Will this app really give me everything I need to succeed
                      for only $14.99/month?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="accordion-desc px-24">
                      YES! For the low price of $14.99/month or $99.99/year
                      you'll receive everything you need to reach your goals
                      (weight loss, strength training, bulk up, etc.). My app
                      features workout programs and individual exercises that
                      you can perform at home or at the gym. You also get
                      nutrition guides and access to workouts from 200+ other
                      top trainers
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="accordion-title px-22 px-24">
                      What makes your app different? How do I know if it will
                      work for me?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="accordion-desc px-24">
                      If you haven't had success with other workout apps in the
                      past, you may be skeptical about mine. But I'm here to
                      tell you, I guarantee you'll get the results you want with
                      me. Why? I don't give you one-off programs or PDF
                      documents and no assistance. I add new programs (with
                      interactive videos explaining exercises) on a regular
                      basis. My content is new, fresh, and ever-changing to keep
                      you motivated and ready to work! Plus, if you need help,
                      I'm always here for you. You can message me directly if
                      you have any questions, feedback, or simply want to share
                      your success.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="accordion-title px-22 px-24">
                      I’m a beginner, will I be able to do your workouts?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="accordion-desc px-24">
                      My workouts and programs work with any level of fitness.
                      Plus, I walk you through all exercises with easy-to-follow
                      videos and simple written instructions. This means you'll
                      be able to do all my moves easily, accurately, and safely.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="accordion-title px-22 px-24">
                      Do I need to purchase equipment to workout with you?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="accordion-desc px-24">
                      Most, if not all, of my workouts and exercises will
                      require equipment.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Fade>
  );
}
