import { Button } from "@mui/material";
import React from "react";

import { useNavigate } from "react-router-dom";

import HomeIcon from "@mui/icons-material/Home";

import "./404.css";

export default function Not_found() {
  //navigate
  let navigate = useNavigate();

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "grid",
        placeItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "grid",
          placeItems: "center",
          textAlign: "center",
        }}
      >
        <h5 style={{ color: "lightgray", fontSize: "28px", margin: "0" }}>
          yoxeen.it
        </h5>
        <h1 style={{ color: "lightgray", fontSize: "60px", margin: "0" }}>
          ERROR 404
        </h1>
        <h1
          style={{
            color: "lightgray",
            fontSize: "60px",
            margin: "0",
          }}
        >
          PAGE NOT FOUND
        </h1>
        <Button
          variant="outlined"
          className="not-found-btn"
          style={{
            color: "black",
            fontSize: "20px",
            borderColor: "black",
            marginTop: "20px",
          }}
          onClick={() => navigate("/")}
        >
          <HomeIcon />
        </Button>
      </div>
    </div>
  );
}
