import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, Outlet, useParams } from "react-router-dom";

//loader
import CircularProgress from "@mui/material/CircularProgress";
//icon
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

import allSport from "./../../sportLang.json";

import "./Sport.css";
import SliderPerView from "../../../../components/carousel/slider-x-view/SliderPerView";
import { Button } from "@mui/material";

// const all = async (props) => {
//   axios.get("yoxeen.it:4000/" + props.name);
// };

const cat = ["calcio", "palestra", "nuoto", "tennis"];

export default function Category() {
  //navigstion
  const navigate = useNavigate();
  //category sport list
  const [categorySport, setCategorySport] = useState(["a", "b", "c"]);

  //user from local
  const userLocalUid = localStorage.getItem("user");

  //loading state
  const [loading, setLoading] = useState(false);

  //user setting info
  const [userLang, setUserLang] = useState("");

  //category sport
  const [catList, setCatList] = useState(cat);

  //api call get lang
  const getLang = async () => {
    if (userLocalUid) {
      // console.log("utente", userLocalUid);
      await Axios.get("https://yoxeen.ks-hub.com:4000/user/", {
        params: { _id: userLocalUid },
      })
        .then((result) => {
          setUserLang(result.data.lang);
          setCatList(allSport["sport"][result.data.lang]);
        })
        .catch((error) => console.log("error d", error));
    }
  };

  useEffect(() => {
    getLang();
  }, []);

  //react router params
  const { sport, category } = useParams();

  // console.log("first", sport, category);

  //go to category
  const goEx = (et) => {
    // console.log("I'm", et);
    navigate("/explore/" + sport.toLowerCase() + "/" + et);
  };

  // //ex controll
  // useEffect(() => {
  //   const t = catList.includes(sport);
  //   if (!t) {
  //     navigate("/404");
  //   }
  // }, [sport]);

  //api call get exercises by category
  const getExercises = async () => {
    if (sport === "" || userLang === "") return;
    // console.log("datum", sport, userLang);
    await Axios.get("https://yoxeen.ks-hub.com:4000/exercise/category", {
      params: { sport: sport, lang: userLang },
    })
      .then((result) => setCategorySport(result.data))
      .catch((error) => console.log(error));
    setLoading(true);
  };

  useEffect(() => {
    getExercises();
  }, [userLang]);

  //TODO api call to workout tips for sport selected based on language
  //TODO api call to blog filtered by sport

  //go to top
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);

  return (
    <div className="sport-box">
      {category ? (
        <>
          <Outlet />
        </>
      ) : (
        <>
          {loading ? (
            // <div className="center">
            <div className="sport-inner">
              {/* <Button
                variaton="outloned"
                className="floating-back"
                onClick={() => navigate("/explore")}
              >
                <ArrowBackRoundedIcon />
              </Button> */}
              {/* <SliderPerView /> */}
              <h3>Look deep in specific categories</h3>
              <div className="category-box-layout">
                {categorySport.map((e, index) => (
                  <div
                    className="category-sport-item-layout"
                    key={index + "e"}
                    style={{ cursor: "pointer" }}
                    onClick={() => goEx(e.toString())}
                  >
                    {e}
                    {category}
                  </div>
                ))}
              </div>

              <h2>Find out our training programs made by our experts</h2>
              <h4>
                Our team is constantly in work to build the perfect program to
                start with Yoxeen Web App
              </h4>
              <p>Try and Check!</p>

              <div className="center sz">
                <h4>Beginner</h4>
                <div className="category-box">
                  {["1", "2", "3", "4", "5"].map((el, ind) => (
                    <div className="workout-sport-item" key={ind + "s"}>
                      <div className="workout-sport-item-img">
                        <img src="https://source.unsplash.com/random" alt="" />
                      </div>
                      <div className="workout-sport-item-desc">
                        <h5>@ExpertName {el}</h5>
                        <h2>workout title</h2>
                        <p>Desciption</p>
                        <div>user valuttation</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="center sz">
                <h4>Intermed</h4>
                <div className="category-box">
                  {["1", "2", "3", "4", "5"].map((el, ind) => (
                    <div className="workout-sport-item" key={ind + "z"}>
                      <div className="workout-sport-item-img">
                        <img src="https://source.unsplash.com/random" alt="" />
                      </div>
                      <div className="workout-sport-item-desc">
                        <h5>@ExpertName {el}</h5>
                        <h2>workout title</h2>
                        <p>Desciption</p>
                        <div>user valuttation</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="center sz">
                <h4>Advanced</h4>
                <div className="category-box">
                  {["1", "2", "3", "4", "5"].map((el, ind) => (
                    <div className="workout-sport-item" key={ind + "p"}>
                      <div className="workout-sport-item-img">
                        <img src="https://source.unsplash.com/random" alt="" />
                      </div>
                      <div className="workout-sport-item-desc">
                        <h5>@ExpertName {el}</h5>
                        <h2>workout title</h2>
                        <p>Desciption</p>
                        <div>user valuttation</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="center sz">
                <h4>Blog</h4>
                <div className="category-box">
                  {["1", "2", "3", "4", "5"].map((el, ind) => (
                    <div className="workout-sport-item" key={ind + "v"}>
                      <div className="workout-sport-item-img">
                        <img src="https://source.unsplash.com/random" alt="" />
                      </div>
                      <div className="workout-sport-item-desc">
                        <h5>{sport}</h5>
                        <h2>Title {el}</h2>
                        <p>Desciption</p>
                        <div>Data gg/mm/yy</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* </div> */}
            </div>
          ) : (
            <CircularProgress />
          )}

          {/* <>
            <Outlet />
          </> */}
        </>
      )}
    </div>
  );
}
