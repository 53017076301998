import React, { useEffect } from "react";
// import { StyleSheet, Text, View } from 'react-native';
import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Sidebar from "./components/sidebar/Sidebar";
import Home from "./routes/home/Home";
import Module from "./routes/module/Module";
import Account from "./routes/account/Account";
import ModPres from "./routes/module-pres/ModPres";
import Log from "./routes/login/log";
import View from "./routes/account/account-view/View";
import Create from "./routes/account/account-create/Create";
import General from "./routes/account/account-general/General";
import Privacy from "./routes/account/account-privacy/Privacy";
import Plan from "./routes/account/account-plan/Plan";
import Progress from "./routes/account/account-progress/Progress";
import Profile from "./routes/account/account-edit-profile/Profile";
import Password from "./routes/account/account-password/Password";
import Help from "./routes/account/account-help/Help";

import Blog from "./routes/account/account-blog/Blog";
import BlogView from "./routes/account/account-blog/BlogView/BlogView";
import Explore from "./routes/account/account-explore/Explore";
import Sport from "./routes/account/account-explore/account-explore-sport/Sport";
import Category from "./routes/account/account-explore/account-explore-category/Category";
import Not_found from "./routes/not_found_404/404";
import Exercise from "./routes/account/account-explore/account-explore-exercise/Exercise";
import Food from "./routes/food_api/food";
import FoodPlan from "./routes/account/food/foodPlan";

import CookieConsent from "react-cookie-consent";
import FindExpert from "./routes/find_expert/FindExpert";
// import { auth } from './firebase-config';
// import { onAuthStateChanged } from "firebase/auth";

//google analytics
// import ReactGA from "react-ga";
// const TRACKING_ID = "UA-215774780-1"; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);
// UA - 215774780 - 1;

// const styles = StyleSheet.create({
//   containerNormal: {
//     height: 100,
//   }

// })

// function style () {
//   if(window.screen.width < 1024) {
//     console.log("dentro");
//       const tmp = document.querySelector(".active");
//       tmp.forEach(el => console.log(el));
//   }
// }

function App() {
  // firebase
  // const [user, setUser] = useState({});
  // onAuthStateChanged(auth, (currentUser) => {
  //   setUser(currentUser);
  // })

  // function handleLog() {
  //   setLog(!log);
  // }

  //google analytics
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  const [inactive, setInactive] = useState(false);
  const [accActive, setAccActive] = useState(false);

  const changeState = () => {
    setInactive(!inactive);
  };

  const changeStateNav = (a) => {
    setAccActive(a);
  };

  // useEffect(() => {
  //   window.scrollTo(0, 1);
  // }, []);

  return (
    <Router>
      <div className="app">
        {accActive === false ? (
          <>
            <Navbar onClick={changeState} btnState={inactive} />
            <Sidebar sideState={inactive} navState={changeStateNav} />
          </>
        ) : (
          <></>
        )}

        <div className={`${inactive ? "activeApp" : ""}`}>
          <div className="app-container">
            <Routes>
              <Route path="*" element={<Not_found />} />
              <Route path="/404" element={<Not_found />} />
              <Route path="/" exact element={<Home sideState={false} />} />
              {/* <Route
                path="modpres/module"
                element={<Module sideState={false} />}
              /> */}
              <Route
                path="account"
                element={
                  <Account sideState={false} navState={changeStateNav} />
                }
              >
                <Route path="create" exact element={<Create />}>
                  <Route path=":id" element={<Create />} />
                </Route>
                <Route path="view" element={<View />} />
                <Route path="general" element={<General />} />
                <Route path="profile" element={<Profile />} />
                <Route path="password" element={<Password />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="plan" element={<Plan />} />
                <Route path="progress" element={<Progress />} />
                <Route path="help" element={<Help />} />
                <Route path="diet" element={<FoodPlan />} />

                {/* <Route path="blog" element={<Blog />}>
                  <Route path=":id" element={<Blog />} />
                </Route> */}
              </Route>
              <Route path="blog" exact element={<Blog />}>
                <Route path="blog/:id" element={<BlogView />} />
              </Route>
              <Route path="explore" element={<Explore />}>
                <Route
                  path=":sport/:category/:exercise"
                  element={<Exercise />}
                />
                <Route path=":sport/:category" element={<Category />} />
                <Route path=":sport" element={<Sport />} />
              </Route>
              {/* <Route path="modpres" element={<ModPres sideState={false} />} /> */}
              <Route path="log" element={<Log sideState={false} />} />
              <Route path="food" element={<Food />} />
              <Route path="find" element={<FindExpert />} />
            </Routes>
          </div>
        </div>

        <CookieConsent
          location="bottom"
          buttonText="Ok"
          cookieName="GeneralCookieInfo"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
          {/* <span style={{ fontSize: "10px" }}>
            This bit of text is smaller :O
          </span> */}
        </CookieConsent>
      </div>
    </Router>
  );
}

export default App;
