import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, Outlet, useParams } from "react-router-dom";

//icon
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

//loader
import CircularProgress from "@mui/material/CircularProgress";

import "./Category.css";
import { Button } from "@mui/material";

// const all = async (props) => {
//   axios.get("yoxeen.it:4000/" + props.name);
// };

// const cat = ["calcio", "palestra", "nuoto", "tennis"];

export default function Category() {
  //navigate react router
  const navigate = useNavigate();

  //exercise list
  const [exerciseList, setExerciseList] = useState(["a", "b", "c"]);

  //loading state
  const [loading, setLoading] = useState(false);

  //user from local
  const userLocalUid = localStorage.getItem("user");

  //user setting info
  const [userLang, setUserLang] = useState("");

  //get lang
  const getLang = async () => {
    if (userLocalUid) {
      // console.log("utente", userLocalUid);
      await Axios.get("https://yoxeen.ks-hub.com:4000/user/", {
        params: { _id: userLocalUid },
      })
        .then((result) => setUserLang(result.data.lang))
        .catch((error) => console.log("error d", error));
    }
  };

  useEffect(() => {
    getLang();
  }, []);

  //react router params
  const { sport, category, exercise } = useParams();

  // console.log("segundo", sport, category, exercise);

  //go to category
  const goEx = (et) => {
    // console.log("I'm", et);
    navigate("/explore/" + sport + "/" + category + "/" + et);
    // console.log("esese", exercise);
  };

  // //ex controll
  // useEffect(() => {
  //   const t = cat.includes(sport);
  //   if (!t) {
  //     navigate("/404");
  //   }
  // }, [sport]);

  //get exercises by category
  const getExercises = async () => {
    if (category === "" || userLang === "") return;
    // console.log("datum", sport, category, userLang);
    await Axios.get("https://yoxeen.ks-hub.com:4000/exercise/category/ex", {
      params: { category: category, lang: userLang },
    })
      .then((result) => {
        // console.log("dataria", result);
        setExerciseList(result.data);
      })
      .catch((error) => {
        // console.log(error);
        navigate("/404");
      });
    setLoading(true);
  };

  useEffect(() => {
    getExercises();
  }, [userLang, category]);

  //go to top
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);

  return (
    <div className="center">
      {exercise ? (
        <>
          <Outlet />
        </>
      ) : (
        <div className="exercise-list-box">
          {/* <Button
            variaton="outloned"
            onClick={() => navigate("/explore/" + sport)}
          >
            {" "}
            <ArrowBackRoundedIcon />
          </Button> */}
          {loading ? (
            <div className="exercise-list-container">
              {exerciseList.map((el, index) => (
                <div
                  className="exercise-list"
                  key={index + "e"}
                  style={{ cursor: "pointer" }}
                  onClick={() => goEx(el.name)}
                >
                  <div className="exercise-list-img">
                    <img src={el.img} />
                  </div>
                  <div className="exercise-list-desc">
                    {el.name}
                    {/* {exercise} */}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <CircularProgress />
          )}
        </div>
      )}
    </div>
  );
}
