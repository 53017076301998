import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

//router
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import "./Navbar.css";

export default function Navbar(props) {
  const t = props.btnState;
  let navigate = useNavigate();

  // useEffect(() => {
  window.addEventListener("scroll", function () {
    let header = document.querySelector(".navbar-container");
    if (header !== null && !black) {
      let windowPosition = window.scrollY > 0;
      header.classList.toggle("scrolling-active", windowPosition);
    }
  });
  // }, []);

  const el = useLocation();
  // console.log("path", el);

  //general style state
  const [black, setBlack] = useState(false);
  useEffect(() => {
    if (el.pathname.includes("/explore")) {
      setBlack(true);
    } else {
      setBlack(false);
    }
  }, [el]);

  return (
    // <div className={`${props.btnState ? "active" : ""}`}>
    <div className={`navbar-container ${props.btnState ? "active" : ""}`}>
      <div className="navbar-box">
        <div>
          <Link
            to={"/"}
            className={black ? "app-btn black" : "app-btn"}
            // style={{
            //   color: "black",
            //   textDecoration: "none",
            //   fontWeight: "600",
            //   cursor: "pointer",
            // }}
          >
            YOXEEN
          </Link>
          {/* {t ? (
            <CloseIcon
              onClick={(event) => props.onClick(event.target)}
              style={{ color: "white", width: "24px", height: "24px" }}
              className="sideBtn"
              fontSize="large"
            />
          ) : (
            <MenuIcon
              onClick={(event) => props.onClick(event.target)}
              style={{ color: "white", width: "24px", height: "24px" }}
              className="sideBtn"
              fontSize="large"
            />
          )} */}
        </div>

        <div
          className={black ? "app-btn black" : "app-btn"}
          onClick={() => navigate("/account")}
        >
          <h6>App</h6>
        </div>
        {/* <div className="app-btn" onClick={{}}>
            APP
          </div> */}
      </div>
    </div>
    // </div>
  );
}
