import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// import "./Create.css";
import "./WorkoutCard.css";

export default function WorkoutCard({ item, col, lang }) {

  console.log("iamge url: ", item?.img)

  return (
    <
      //   key={index}
      //   className="workout-card"
      //   onClick={() => handleEditWorkout(index)}
    >
      <div className="workout-card-title">
        <h1 className={lang === "jpn" ? "jpn-size-title" : ""}>{item.name}</h1>
      </div>
      <div className="workout-card-img">
        {/* <h1>{item.img}</h1> */}
        <img src={item.img} />
      </div>
      <div className="workout-card-desc">
        {/* table */}
        <TableContainer component={Paper}>
          <Table
            // sx={{ minWidth: 400, minHeight: 200 }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow className={lang === "jpn" ? "jpn-size" : ""}>
                {col.map((col, ind) => (
                  <TableCell key={ind + "a"} align="center">
                    {col}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {item.rows.map((row, index) => (
                <TableRow
                  key={index + "b"}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    // key={row.set}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    {row.set}
                  </TableCell>
                  <TableCell align="center">{row.rep}</TableCell>
                  <TableCell align="center">{row.weight}</TableCell>
                  <TableCell align="center">{row.rec}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
