import React from "react";

// Mui
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";

//styles
import { makeStyles } from "@material-ui/core/styles";

//css
import "./Help.css";

//autocomplete style
const useStyles = makeStyles((theme) => ({
  root: {
    // "& label.Mui-focused": {
    //   color: "white",
    // },
    // "& .MuiInput-underline:after": {
    //   borderBottomColor: "yellow",
    // },
    "& .MuiOutlinedInput-root": {
      // "& fieldset": {
      //   borderColor: "white",
      // },
      "&:hover fieldset": {
        borderColor: "#21355e",
      },
      // "&.Mui-focused fieldset": {
      //   borderColor: "yellow",
      // },
    },
  },
}));

export default function Help() {
  //import style
  const styles = useStyles();

  return (
    <div className="help">
      <div className="help-content">
        <div className="help-title">
          <h1>Help Center</h1>
          <h3>
            Please complete the fields above with your information to be
            contacted
          </h3>
        </div>

        <div className="help-box">
          <div className="help-box-name">
            <TextField
              classes={styles}
              fullWidth
              variant="outlined"
              placeholder="name"
            />
            <TextField
              classes={styles}
              fullWidth
              variant="outlined"
              placeholder="lastname"
            />
          </div>
          <TextField
            classes={styles}
            fullWidth
            variant="outlined"
            placeholder="email"
          />
          <TextField
            fullWidth
            multiline
            classes={styles}
            variant="outlined"
            placeholder="Message..."
            className="message-box"
          />
          <Button fullWidth variant="outlined">
            Send Request
          </Button>
        </div>
      </div>
    </div>
  );
}
