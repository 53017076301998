import React, { useEffect, useState, useRef } from "react";
import { MapContainer, TileLayer, useMap, Marker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";

import LanguageIcon from "@mui/icons-material/Language";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Rating from "@mui/material/Rating";

import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";

import icon from "./Constants";

//user current location

import "./Map.css";
import useGeoLocation from "./user-current-location/Location";
import axios from "axios";

// Cordinates of Marcillac
// const centerMap = [45.269169177925754, -0.5231516014256281];
const center = [51.509865, -0.118092];

const purpleOptions = { color: "white" };

const testExpertResult = [
  {
    name: "Federico",
    lastname: "Bianchi",
    email: "federico.bianchi@gmail.com",
    website: "xxx-bianchi.com",
    instagram: "https://www.instagram.com/javascript.js/",
    whatsapp: "3462773846",
    place: "Milano",
    lat: "45.4641971",
    long: "9.1892605235",
    role: "Nutritionist",
    rating: 3,
  },
  {
    name: "Federico",
    lastname: "Colombo",
    email: "federico.bianchi@gmail.com",
    website: "xxx-bianchi.com",
    instagram: "https://www.instagram.com/javascript.js/",
    whatsapp: "3462773846",
    place: "Milano",
    lat: "45.4641971",
    long: "9.1892605235",
    role: "Nutritionist",
    rating: 3,
  },
  {
    name: "Chiara",
    lastname: "Bianchi",
    email: "federico.bianchi@gmail.com",
    website: "xxx-bianchi.com",
    instagram: "https://www.instagram.com/javascript.js/",
    whatsapp: "3462773846",
    place: "Milano",
    lat: "45.4641971",
    long: "9.1892605235",
    role: "Nutritionist",
    rating: 5,
  },
  {
    name: "Matteo",
    lastname: "Bianchi",
    email: "federico.bianchi@gmail.com",
    website: "xxx-bianchi.com",
    instagram: "https://www.instagram.com/javascript.js/",
    whatsapp: "3462773846",
    place: "Milano",
    lat: "45.4641971",
    long: "9.1892605235",
    role: "Nutritionist",
    rating: 2.5,
  },
  {
    name: "Giacomo",
    lastname: "Venturi",
    email: "federico.bianchi@gmail.com",
    website: "xxx-bianchi.com",
    instagram: "https://www.instagram.com/javascript.js/",
    whatsapp: "3462773846",
    place: "Milano",
    lat: "45.4641971",
    long: "9.1892605235",
    role: "Personal Trainer",
    rating: 1,
  },
];

function LeafletMapFun() {
  const map = useMap();

  //function to geto te city location from an address, and then the users bind with the city
  const reverseGeoCode = async (lat, long) => {
    await axios
      .get(
        `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${long}`
      )
      .then((result) => {
        console.log("Reverse geocose", result);

        //TODO api call to fetch all user in current city
        console.log(
          "city getted",
          result.data?.address?.town, // or city
          result.data?.address?.city,
          result.data?.address?.suburb,
          result.data?.address?.country,
          result.data?.address?.country_code,
          result.data?.address?.state
        );
      })
      .catch((err) => console.log(err));
  };
  //function to get the search result and make queries to db
  function searchEventHandler(result) {
    console.log("cane", result.location);
    reverseGeoCode(result.location.y, result.location.x);
    map.flyTo([result.location.y, result.location.x], 14, {
      animate: true,
    });
  }

  //useeffect to get value
  // useEffect(() => {
  //   console.log("ok", document.getElementsByClassName("glass")[0].value);
  // });

  //general leaflet useEffect to interact with map
  useEffect(() => {
    // enableLocateMe;
    const provider = new OpenStreetMapProvider();

    const searchControl = new GeoSearchControl({
      provider,
      marker: {
        icon,
      },
    });

    // console.log("searchCTRL", searchControl);

    map.addControl(searchControl);

    map.on("geosearch/showlocation", searchEventHandler);

    return () => map.removeControl(searchControl);
  }, []);

  return null;
}

const Map = () => {
  // rating value
  const [ratingValue, setRatingValue] = React.useState(2);

  // map ref
  const mapRef = useRef();

  useEffect(() => {
    // document.getElementsByClassName("active")

    if (document.getElementsByClassName("glass")[0]) {
      console.log("in", document.getElementsByClassName("glass")[0]);
      document
        .getElementsByClassName("glass")[0]
        .setAttribute("autocomplete", "new-password");
    }
    // if (document.querySelector("geosearch")) {
    //   let el = document.querySelector("geosearch");

    //   if (el.querySelector("form")) {
    //     el.querySelector("form")[0].setAttribute("autocomplete", "off");
    //   }
    // }
  });

  const [openApiDrawer, setOpenApiDrawer] = useState(false);

  const handleOpenApiDrawer = () => {
    setOpenApiDrawer(true);
  };
  const handleCloseApiDrawer = () => {
    setOpenApiDrawer(false);
  };

  return (
    <div id="mapid" className="map-test">
      <button
        style={{ position: "absolute", top: "0", zIndex: "1400" }}
        onClick={handleOpenApiDrawer}
      >
        open
      </button>
      <MapContainer
        center={center}
        zoom={9}
        ref={mapRef}
        // whenCreated={(map) => setMap(map)}
        scrollWheelZoom={true}
        className="map"
      >
        <TileLayer
          attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
        />
        <LeafletMapFun />
      </MapContainer>
      <div className={openApiDrawer ? "api-search-result-container open2" : ""}>
        {/* <div className="api-search-result-box"> */}
        <div
          className={
            openApiDrawer
              ? "api-search-result open2"
              : "api-search-result close2"
          }
        >
          <h3>Search result</h3>

          {testExpertResult?.map((el, ind) => (
            <div className="expert-item">
              <img src="https://source.unsplash.com/random" alt="" />
              <div className="expert-item-description">
                <div className="expert-item-description-title">
                  <h3>{el.name}</h3>
                  <h3>{el.lastname}</h3>
                </div>
                <h3 className="expert-item-description-mail">{el.email}</h3>
                <h3 className="expert-item-description-mail">{el.role}</h3>

                <div className="expert-item-description-review">
                  <Rating
                    name="read-only"
                    value={el.rating}
                    size="small"
                    precision={0.5}
                    readOnly
                  />
                </div>

                <a href={el.website} target="blank">
                  <LanguageIcon />
                </a>
                <a href={el.instagram} target="blank">
                  <InstagramIcon />
                </a>
                <a href={el.whatsapp} target="blank">
                  <WhatsAppIcon />
                </a>
              </div>
            </div>
          ))}
        </div>
        <div
          className={openApiDrawer ? "api-search-result-shadow" : ""}
          onClick={handleCloseApiDrawer}
        ></div>
      </div>
      {/* </div> */}
    </div>
  );
  // }
};

export default Map;
