import React, { useEffect, useState } from "react";
import Axios from "axios";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Drawer from "@mui/material/Drawer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";

//switch
import Switch from "@mui/material/Switch";

//styles
import { makeStyles } from "@material-ui/core/styles";

// import Alert from "@mui/material/Alert";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import MenuItem from "@mui/material/MenuItem";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

//media query
import useMediaQuery from "@mui/material/useMediaQuery";

//react-select

//icons
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import ShareIcon from "@mui/icons-material/Share";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SaveIcon from "@mui/icons-material/Save";

//react icon
import {
  FaBroom,
  FaCloudUploadAlt,
  FaFile,
  FaFilePdf,
  FaPlus,
  FaShareAlt,
  FaTrash,
  FaSave,
} from "react-icons/fa";

import { GiBroom, GiCloudDownload } from "react-icons/gi";
//tooltip
import Tooltip from "@mui/material/Tooltip";

//router
import {
  useNavigate,
  useLocation,
  useParams,
  useOutletContext,
} from "react-router-dom";
import { createBrowserHistory, History } from "history";

//transition
import Fade from "@mui/material/Fade";

//notification
import {
  successNotification,
  errorNotification,
  warningNotification,
} from "../../../components/notification/Notification";
import { ToastContainer, toast } from "react-toastify";

import WorkoutCard from "./../../../components/card/workout-card/WorkoutCard";

//translation
import { useTranslation } from "react-i18next";

//firebase
//import { getAuth } from "firebase/auth";

import allSport from "./../sportLang.json";

import "./Create.css";
import "../../../components/card/workout-card/WorkoutCard.css";
// import { Alert } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid lightgrey",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  minWidth: 200,
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const coll = ["set", "rep", "weight", "rec"];
const ro = [
  {
    set: 1,
    rep: 8,
    weight: 20,
    rec: 30,
  },
  {
    set: 2,
    rep: 8,
    weight: 20,
    rec: 30,
  },
  {
    set: 3,
    rep: 8,
    weight: 20,
    rec: 30,
  },
];

//autocomplete style
const useStyles = makeStyles((theme) => ({
  // root: {
  //   "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
  //     // Default transform is "translate(14px, 20px) scale(1)""
  //     // This lines up the label with the initial cursor position in the input
  //     // after changing its padding-left.
  //     transform: "translate(34px, 20px) scale(1);",
  //   },
  // },
  inputRoot: {
    // color: "purple",
    // // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    // '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
    //   // Default left padding is 6px
    //   paddingLeft: 26,
    // },
    // "& .MuiOutlinedInput-notchedOutline": {
    //   borderColor: "green",
    // },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#21355e",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#59698a",
    },
  },
}));

export default function Create(props) {
  // const auth = getAuth();
  // const user = auth.currentUser;

  //context
  const [selectedIndex, setSelectedIndex] = useOutletContext();

  //user Id
  const userLocalUid = localStorage.getItem("user");

  //translation obj
  const { t } = useTranslation();

  //react router params
  const { id } = useParams();

  // console.log("id", id);

  //navigate
  let navigate = useNavigate();

  //media quey
  const dimension = useMediaQuery("(max-width:600px)");
  const dimensionAndHeight = useMediaQuery(
    "(max-width:600px and max-height:890px)"
  );

  //import style
  //use styles
  const styles = useStyles();

  //user setting info
  const [userLang, setUserLang] = useState("en");
  const [userWg, setUserWg] = useState("");

  const getLang = async () => {
    if (userLocalUid) {
      // console.log("utente", userLocalUid);
      await Axios.get("https://yoxeen.ks-hub.com:4000/user/", {
        params: { _id: userLocalUid },
      })
        .then((result) => {
          if (result.data && result.data.lang && result.data.weight) {
            setUserLang(result.data.lang);
            setUserWg(result.data.weight);
          }

          // setColumns(allSport["columns"][result.data.lang]);
        })
        .catch((error) => console.log("error d", error));
    }
  };

  //use effect use info
  useEffect(() => {
    getLang();
  }, []);

  //modal general state
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSupersetExercise(false);
    setOpen(false);
    handleCloseBottom();
    setRows([]);
    setWorkoutEditable(false);
    setWorkoutEditableIndex(-1);
    setExerciseName("");
    generalRow();
  };

  const [openBottom, setOpenBottom] = React.useState(false);
  const handleOpenBottom = () => setOpenBottom(true);
  const handleCloseBottom = () => {
    setOpenBottom(false);
    setSaveState(false);
    generalRowSetted();
  };

  //modal options
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  //modaltitle options
  const [openModalTitle, setOpenModalTitle] = useState(false);
  const handleOpenModalTitle = () => setOpenModalTitle(true);
  const handleCloseModalTitle = () => setOpenModalTitle(false);

  //handle create workout
  const [isCreating, setIsCreating] = useState(false);
  const displayTable = () => {
    setIsCreating(true);
  };
  const hideTable = () => {
    setIsCreating(false);
  };

  // console.log(allSport["columns"][userLang]);

  //handle Workout name edited
  const [isEditing, setIsEditing] = useState(false);
  //workout name state
  const [workoutName, setWorkoutName] = useState("");
  //workout name edited state
  const [workoutNameEdited, setWorkoutNameEdited] = useState("");

  //workout category
  const [workoutCategory, setWorkoutCategory] = useState("");

  //exercise name state
  const [exerciseName, setExerciseName] = useState("");

  //set multiple exercise
  const [multipleExercise, setMultipleExercise] = useState(false);

  //set multiple exercise
  const [supersetExercise, setSupersetExercise] = useState(false);

  //set multiple exercise state
  const [multipleExerciseArray, setMultipleExerciseArray] = useState([{}]);

  // console.log("pussy", multipleExercise);
  //image url
  const [imgUrl, setImgUrl] = useState("");

  //rows array state
  const [rows, setRows] = React.useState(ro);

  //columns state
  const [columns, setColumns] = useState(coll);
  //handling router change

  const location = useLocation();
  // console.log("location", location);
  const [name, setName] = useState(location.state);
  // console.log("location nn", name);

  // const params = useParams();
  // console.log("params", params);
  // console.log("nameless", name);
  // useEffect(() => {
  //   if (name) {
  //     setIsCreating(true);
  //     setWorkoutName(name);
  //   }
  // }, []);

  //yellow.warning
  useEffect(() => {
    console.disableYellowBox = true;
  });

  //single general row
  function generalRow() {
    let tmp = {};

    for (let i = 0; i < columns.length; i++) {
      tmp[columns[i]] = "";
    }
    return tmp;
  }

  function generalRowSetted() {
    let tmp = {};

    for (let i = 0; i < columns.length; i++) {
      if (columns[i] === "set") {
        tmp[columns[i]] = rows.length + 1;
      } else {
        tmp[columns[i]] = "";
      }
    }

    setRowGen(tmp);
  }

  //row general state
  const [rowGen, setRowGen] = useState(generalRow());
  //workout general state
  const [workoutEditable, setWorkoutEditable] = useState(false);
  //workout general state index
  const [workoutEditableIndex, setWorkoutEditableIndex] = useState(-1);

  //modify general row function
  const handleRowChange = (value, col) => {
    setRowGen({
      ...rowGen,
      [col]: value,
    });
  };

  //function to check if all field are fill
  function checkProperties(ob) {
    const val = Object.values(ob);
    // console.log("val", val);

    let t = false;
    for (let i = 0; i < val.length; i++) {
      if (val[i] === "") {
        t = true;
      }
    }
    return t;
  }
  // add a row to table
  const handleRowAdd = () => {
    if (checkProperties(rowGen)) {
      // console.log("merda");
      handleCloseBottom();
      //TODO notification
      return;
    } else {
      rowGen.set = rows.length + 1;
      let tmp = [...rows, rowGen];
      setRows(tmp);
      handleCloseBottom();
    }
  };

  // general state bottom open if true open add ex if false open modify exercise
  const [saveState, setSaveState] = useState(false);
  const handleExOpen = () => {
    setSaveState(true);
  };

  // handle specific row edit
  const handleRowSave = () => {
    let tmp = [...rows];
    for (let i = 0; i < tmp.length; i++) {
      if (tmp[i].set === rowGen.set) {
        tmp[i] = rowGen;
      }
    }
    setRows(tmp);
    handleCloseBottom();
  };

  // handle specific row delete
  const handleRowDelete = () => {
    let tmp = [...rows];
    const ind = rowGen.set - 1;
    tmp.splice(ind, 1);

    for (let i = 0; i < tmp.length; i++) {
      tmp[i].set = i + 1;
    }
    setRows(tmp);
    handleCloseBottom();
  };

  //add an ex to the dashboard
  const [workout, setWorkout] = useState([
    // {
    //   id: 0,
    //   name: "",
    //   category: "",
    //   img: "url",
    //   columns: [],
    //   rows: [],
    // },
  ]);

  // console.log("wk", workout);

  //add ex to workout list
  const handleSaveEx = () => {
    if (multipleExercise) {
      let tmp = [...workout];
      multipleExerciseArray.map((el, ind) => {
        tmp.push({
          id: workout.length + ind,
          name: el.name,
          img: el.img,
          columns: columns,
          rows: rows,
        });
      });
      setWorkout(tmp);
      handleClose();
    } else {
      if (rows.length === 0 || exerciseName === "") {
        //TODO notification
        handleClose();
      } else {
        let tmp = [];
        if (supersetExercise) {
          let tmp_name = exerciseName + " (superset)";
          tmp = [
            ...workout,
            {
              id: workout.length,
              name: tmp_name,
              img: imgUrl,
              columns: columns,
              rows: rows,
            },
          ];
        } else {
          tmp = [
            ...workout,
            {
              id: workout.length,
              name: exerciseName,
              img: imgUrl,
              columns: columns,
              rows: rows,
            },
          ];
        }
        // let tmp = [
        //   ...workout,
        //   {
        //     id: workout.length,
        //     name: exerciseName,
        //     img: imgUrl,
        //     columns: columns,
        //     rows: rows,
        //   },
        // ];
        setWorkout(tmp);
        handleClose();
      }
    }
  };

  //useEffect to detect a superset exercise
  // useEffect(() => {
  //   if (supersetExercise) setExerciseName(exerciseName + " (superset)");
  // }, [exerciseName]);

  const checkSuperset = () => {
    // console.log("superset?", supersetExercise);
    let tmp_name = exerciseName + " (superset)";
    if (supersetExercise === true) setExerciseName(tmp_name);
    // console.log("in", exerciseName);
  };

  // console.log("all wk", workout);
  // console.log("out", exerciseName);

  //handle edit workout
  const handleEditWorkout = (ind) => {
    setRows(workout[ind].rows);
    setExerciseName(workout[ind].name);
    setImgUrl(workout[ind].img);
    setOpen(true);
    setWorkoutEditable(true);
    setWorkoutEditableIndex(ind);
  };

  //handle workout edit save
  const handleSaveWorkout = () => {
    if (rows.length === 0) {
      handleClose();
    } else {
      let tmp = [...workout];
      // console.log("tempolo", tmp);
      // let tmp_arr = [...rows];

      tmp[workoutEditableIndex].rows = rows;

      let tmp_name = exerciseName + " (superset)";
      if (supersetExercise) tmp[workoutEditableIndex].name = tmp_name;
      else tmp[workoutEditableIndex].name = exerciseName;

      tmp[workoutEditableIndex].img = imgUrl;

      // console.log("rows", rows);
      // console.log("rowgen", rowGen);

      setWorkout([]);
      setWorkout(tmp);
      setWorkoutEditableIndex(-1);
      handleClose();
      // handleClear();
    }
  };

  //handle exercise delete
  const handleDeleteWorkout = () => {
    let tmp = [...workout];
    tmp.splice(workoutEditableIndex, 1);
    setWorkout(tmp);
    handleClose();
  };

  //handle clear dashboard
  const handleClear = () => {
    setWorkout([]);
    setWorkoutCategory("");
    setWorkoutName("");
  };

  const handleClearDahboard = () => {
    setWorkout([]);
  };

  //form pdf choice
  const [value, setValue] = React.useState("");
  const [pdfName, setPdfName] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handlePdfName = (name) => {
    setPdfName(name);
  };

  const handleExport = () => {
    setExportNotification(true);
    handleCloseModal();
    const id = toast.loading("Your pdf is converting...");
    console.log("canissimo", workout);
    Axios.post(
      "https://yoxeen.ks-hub.com:4000/pdf",
      { workout: workout, value: value, title: workoutName },
      {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      }
    )
      .then((response) => {
        toast.update(id, {
          render: "Pdf converted successfully",
          type: "success",
          isLoading: false,
          autoClose: 800,
        });
        setExportNotification(false);
        //TODO notification untill pdf is fetched
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = pdfName + ".pdf";
        link.click();
        //  this.closeModal() // close modal
      })
      .catch((err) => {
        toast.update(id, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
        });
        console.log(err);
      });
  };

  //API CALLS
  //save workout created
  const saveWorkout = async () => {
    if (workoutName === "" || workoutCategory === "" || !userLocalUid) {
      // console.log("Error, empty fields");
      errorNotification("Missed field");
      return;
    }
    let tmp = {};
    if (workoutNameEdited) {
      tmp = {
        _id: workoutName,
        name: workoutNameEdited,
        category: workoutCategory,
        data: workout,
      };
    } else {
      tmp = {
        _id: workoutName,
        name: workoutName,
        category: workoutCategory,
        data: workout,
      };
    }
    await Axios.post(
      "https://yoxeen.ks-hub.com:4000/workout",
      { id: userLocalUid, data: tmp },
      {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(() => {
        // console.log("cane 2",r);
        successNotification("Workout successfully saved");
      })
      .catch((error) => console.log("error c", error));
  };

  //get workout from DB
  const getWorkout = async () => {
    if (userLocalUid) {
      // console.log("name", name);
      if (name) {
        // console.log("inside getWorkout()", name.name);
        setIsCreating(true);
        // console.log("namolo", workoutName);
        await Axios.get("https://yoxeen.ks-hub.com:4000/workout/search", {
          params: { id: userLocalUid, name: name.name },
        })
          .then((res) => {
            // console.log("responce", res.data);
            setWorkout(res.data.data);
            setWorkoutName(res.data.name);
            setWorkoutCategory(res.data.category);
          })
          .catch((error) => console.log("error b", error));
        setName(null);
      } else {
        // console.log("namolo", workoutName);
        await Axios.get("https://yoxeen.ks-hub.com:4000/workout/search", {
          params: { id: userLocalUid, name: workoutName },
        })
          .then((res) => {
            // console.log("responce", res.data);
            setWorkout(res.data.data);
            setWorkoutName(res.data.name);
            setWorkoutCategory(res.data.category);
          })
          .catch((error) => console.log("error b", error));
      }
    }
  };

  //get WK from link
  const getWorkoutLink = async (identity) => {
    // if (userLocalUid) {
    // if (name) {
    //   setIsCreating(true);
    //   console.log("namolo", workoutName);
    //   await Axios.get("https://yoxeen.ks-hub.com:4000/workout/search", {
    //     params: { id: identity, name: name.name },
    //   })
    //     .then((res) => {
    //       console.log("responce", res.data);
    //       setWorkout(res.data.data);
    //       setWorkoutName(res.data.name);
    //       setWorkoutCategory(res.data.category);
    //     })
    //     .catch((error) => console.log("error b", error));
    //   setName({});
    // } else {
    // console.log("namolo", workoutName);
    // console.log("iddolo", identity);
    await Axios.get("https://yoxeen.ks-hub.com:4000/workout/search", {
      params: { id: identity, name: workoutName },
    })
      .then((res) => {
        // console.log("responce", res.data);
        setWorkout(res.data.data);
        setWorkoutName(res.data.name);
        setWorkoutCategory(res.data.category);
      })
      .catch((error) => console.log("error b", error));
    //}
    //}
  };
  useEffect(() => {
    if (name !== null) {
      setIsCreating(true);
      setWorkoutName(name.name);
    }
  }, []);

  const [count, setCount] = useState(0);
  useEffect(() => {
    if (!id) {
      if (isCreating) {
        getWorkout();
        // console.log("called");
      }
    } else {
      if (count > 1) return;
      const t = id.split("-");
      getWorkoutLink(t[0]);
      setCount(count + 1);
    }
  }, [workoutName]);

  // console.log("rse", user.uid);
  const [allWorkout, setAllWorkout] = useState([]);

  //user category
  const [userCategory, setUserCategory] = useState([]);
  // const [workoutOne, setWorkoutOne] = useState(["figa"]);
  const [exCategory, setExCategory] = useState("");
  // const [exercises, setExercises] = useState([]);

  //get all workouts from a user
  //TODO on save cleat category array
  const getAllWorkout = async () => {
    if (!userLocalUid) return;
    // console.log("linghezza", allWorkout.length);
    // if (allWorkout.length !== 0) return;
    await Axios.get("https://yoxeen.ks-hub.com:4000/workout/all", {
      params: { _id: userLocalUid },
    })
      .then((res) => {
        setAllWorkout([...res.data]);
        // console.log("responce", res.data);
        // console.log("res", res);
      })
      .catch((error) => console.log("error a", error));
  };

  //get all categories(folder) from a user
  //TODO on save cleat category array
  const getUserCategory = async () => {
    if (!userLocalUid) return;
    if (allWorkout.length !== 0) return;
    console.log("send category if", userLocalUid);

    await Axios.get("https://yoxeen.ks-hub.com:4000/workout/category", {
      params: { _id: userLocalUid },
    })
      .then((res) => {
        setUserCategory([...res.data]);
        // console.log("responce category", res.data);
      })
      .catch((error) => console.log("error a", error));
  };

  // useEffect(() => {

  //   if(isCreating)
  //   {handleChangeLoadCategory(workoutCategory);
  //   }

  // }, [workoutCategory]);

  const handleChangeLoadCategory = async (categoryValue) => {
    // console.log("valuesiaosd", categoryValue);
    await Axios.get("https://yoxeen.ks-hub.com:4000/workout/allExByCategory", {
      params: { _id: userLocalUid, category: categoryValue },
    })
      .then((res) => {
        setAllWorkout([...res.data]);
        console.log("responce category", res.data);
      })
      .catch((error) => console.log("error a", error));
  };

  //delete selected workout
  const handleDelete = async () => {
    // console.log("clicked");
    if (!userLocalUid) return;
    await Axios.put("https://yoxeen.ks-hub.com:4000/workout/search", {
      data: { _id: userLocalUid, name: workoutName },
    })
      .then((res) => {
        // console.log("Deleted responce", res);
        //TODO API CALL refresh category state
        getUserCategory();
        successNotification("workout deleted");
        handleClear();
        hideTable();
      })
      .catch((error) => console.log("error c", error));
  };

  // useEffect(() => {
  //   getAllWorkout();
  // }, []); //empty array means that the effect will run only once
  //console.log("allworkouts", allWorkout);
  /*

  //get all workout from user
  .


  .
  .










  .






  .*/

  const [exCategoryList, setExCategoryList] = useState([]);
  const [sport, setSport] = useState("Gym");
  const [exercise, setExercise] = useState([]);

  // const handleChangeCategory = (event) => {
  //   setExCategory(event.target.value);
  // };

  const handleChangeSport = (event) => {
    setSport(event.target.value);
  };

  //on sport change, set relative table's column
  // useEffect(() => {
  //   if (
  //     sport === "Palestra" ||
  //     sport === "Gym" ||
  //     sport === "Gimnasio" ||
  //     sport === "Turnhalle" ||
  //     sport === "ジム"
  //   ) {
  //     setColumns(["set", "rep", "weight", "recup"]);
  //   }
  // }, [sport]);

  //get exercises category
  const getCategory = async () => {
    await Axios.get("https://yoxeen.ks-hub.com:4000/exercise/category", {
      params: { sport: sport, lang: userLang },
    })
      .then((result) => {
        // console.log("result category", result.data);
        setExCategoryList(result.data);
      })
      .catch((error) => console.log("error e", error));
  };

  useEffect(() => {
    getCategory();
  }, [sport]);

  //get all ex from categores
  //TODO post user preference language
  const getExerciseCategory = async () => {
    if (userLang !== "" && exCategory !== "") {
      await Axios.get("https://yoxeen.ks-hub.com:4000/exercise/category/ex", {
        params: { category: exCategory, lang: userLang },
      })
        .then((result) => {
          // console.log("ex-categories", result.data);
          setExercise(result.data);
        })
        .catch((error) => console.log("error f", error));
    }
  };

  useEffect(() => {
    getExerciseCategory();
  }, [exCategory]);

  /*
  .


  .
  .










  .*/

  //get all exercises from a category
  // const getExercise = async () => {
  //   if (exCategory !== "") {
  //     await Axios.get("https://yoxeen.ks-hub.com:4000/exercise/", {
  //       params: { category: exCategory, lan: user },
  //     })
  //       .then((result) => console.log(result))
  //       .catch((error) => console.log(error));
  //   } else {
  //     <Alert severity="error">Missing category</Alert>;
  //   }
  // };

  // useEffect(() => {
  //   getExercise();
  // }, []);
  /*
  .


  .
  .










  .






  .*/
  //handle state delete from refersh
  const history = createBrowserHistory();
  useEffect(() => {
    if (history.location.state && history.location.state.transaction) {
      let state = { ...history.location.state };
      delete state.transaction;
      history.replace({ ...history.location, state });
    }
  }, []);

  /*
  .








  .

  ..


  */

  //share workout
  const handleShare = () => {
    /* Copy the text inside the text field */
    // console.log("ins");
    const link =
      "https://yoxeen.ks-hub.com:4000:3000/account/create/" +
      userLocalUid +
      "-" +
      workoutName +
      "-" +
      userLang +
      "-" +
      "link";
    navigator.clipboard
      .writeText(link)
      .then(() => {
        successNotification("Link copied!");
      })
      .catch(() => {
        errorNotification("something went wrong");
      });
    //<Alert onClose={() => {}}>This is a success alert — check it out!</Alert>;
  };

  const handleTest = async () => {
    await Axios.get("https://yoxeen.ks-hub.com:4000/test", {
      withCredentials: true,
    })
      .then((res) => console.log("token responce", res))
      .catch((err) => console.log(err));
  };

  const [openAlert, setOpenAlert] = React.useState(false);

  const handleClick = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  useEffect(() => {
    // props.setIndex;
    setSelectedIndex(0);
  }, []);
  // if (openAlert) {
  //   successNotification("Dati modificati con successo");
  // } else {
  //   errorNotification(
  //     "Errore nella modifica dei dati. Aspetta qualche minuto e riprova."
  //   );
  // }

  // useEffect(() => {
  //   successNotification("Dati modificati con successo");
  // }, [openAlert]);

  //controll shared link
  const linkReq = () => {
    const t = id.split("-");
    setWorkoutName(t[1]);
    console.log("split", t);
  };
  useEffect(() => {
    if (id) {
      // console.log("request params", id);
      linkReq();
    }
  });

  // mobile menu
  const [openMobile, setOpenMobile] = useState(false);

  //notification export
  const [ExportNotification, setExportNotification] = useState(false);

  // console.log("id", id);
  /*----------------------------------- RETURN ---------------------------------------------- */

  const [infoToolTip, setInfoToolTip] = useState(false);
  return (
    <Fade in={true} {...(true ? { timeout: 500 } : {})}>
      <div
        className="create"
        // onClick={() => {
        //   if (infoToolTip == true) {
        //     setInfoToolTip(false);
        //   }
        // }}
        // onClick={() => (dimension ? setInfoToolTip(false) : "")}
      >
        {/* <Button variant="outlined" onClick={handleTest}>
          TEST TOKEN COOKIE
        </Button> */}
        <Tooltip
          title="Remember to save your changes"
          placement="left"
          onClick={() => setInfoToolTip(!infoToolTip)}
          open={dimension ? infoToolTip : false}
        >
          <div
            className="floating-info-btn"
            // onClick={() => {
            //   if (dimension) {
            //     setInfoToolTip(true);
            //     console.log("cane", infoToolTip);
            //   }
            // }}
          >
            <InfoOutlinedIcon />
          </div>
        </Tooltip>

        {openMobile && (
          <div
            className="black-mobile-screen"
            onClick={() => setOpenMobile(false)}
          ></div>
        )}
        {/* //notify container */}
        <ToastContainer />
        {id && (
          <div className="floating-create-btn">
            <div
              className="circle-class"
              onClick={() => {
                // setWorkoutName("");
                // setWorkoutCategory("");
                // handleClear();
                navigate("/account/create");
              }}
            >
              <ArrowBackIosNewIcon />
            </div>
          </div>
        )}
        {!id && (
          <>
            {!dimensionAndHeight ? (
              <div className="floating-create-btn">
                {isCreating && (
                  <>
                    <Tooltip title="Delete your workout" placement="left">
                      <div
                        className="circle-class"
                        onClick={() => {
                          handleDelete();
                        }}
                      >
                        <FaTrash />
                      </div>
                    </Tooltip>
                    <Tooltip title="Share your workout link" placement="left">
                      <div
                        className="circle-class"
                        onClick={() => {
                          handleClick();
                          handleShare();
                        }}
                      >
                        <FaShareAlt />
                      </div>
                    </Tooltip>
                  </>
                )}
                <Tooltip title="Clear your dashboard" placement="left">
                  <div
                    className="circle-class"
                    onClick={() => {
                      hideTable();
                      handleClear();
                      setIsEditing(false);
                    }}
                  >
                    <GiBroom />
                  </div>
                </Tooltip>

                <Tooltip title="Export your workout in PDF" placement="left">
                  <div
                    className="circle-class"
                    onClick={() => {
                      handleOpenModal();
                      setOpenMobile(false);
                      // hideTable();
                      // handleClear();
                      // setIsEditing(false);
                    }}
                  >
                    <FaFilePdf />
                  </div>
                </Tooltip>

                <Tooltip title="Add new exercise" placement="left">
                  <div className="circle-class" onClick={handleOpen}>
                    <FaPlus />
                  </div>
                </Tooltip>
                <Tooltip title="Save your workout!" placement="left">
                  <div
                    className="circle-class"
                    onClick={() => {
                      // saveWorkout();
                      // setOpenMobile(!openMobile);
                      if (workoutName && workoutCategory) saveWorkout();
                      else handleOpenModalTitle();
                    }}
                  >
                    <FaSave />
                  </div>
                </Tooltip>

                <Tooltip title="Load a workout" placement="left">
                  <div
                    className={
                      isCreating ? "circle-class btn-bg" : "circle-class"
                    }
                    onClick={() => {
                      displayTable();
                      handleClear();
                    }}
                  >
                    <GiCloudDownload />
                  </div>
                </Tooltip>
                <Tooltip title="Create new workout" placement="left">
                  <div
                    className={
                      !isCreating ? "circle-class btn-bg" : "circle-class"
                    }
                    onClick={() => {
                      hideTable();
                      handleClear();
                      setIsEditing(false);
                    }}
                  >
                    <FaFile />
                  </div>
                </Tooltip>
              </div>
            ) : (
              <div className="floating-create-btn">
                {openMobile && (
                  <>
                    {isCreating && (
                      <>
                        <Tooltip
                          open={true}
                          title="Delete your workout"
                          placement="left"
                        >
                          <div
                            className="circle-class"
                            onClick={() => {
                              handleDelete();
                              setOpenMobile(!openMobile);
                            }}
                          >
                            <FaTrash />
                          </div>
                        </Tooltip>
                        <Tooltip
                          open={true}
                          title="Share your workout link"
                          placement="left"
                        >
                          <div
                            className="circle-class"
                            onClick={() => {
                              handleClick();
                              handleShare();
                              setOpenMobile(!openMobile);
                            }}
                          >
                            <FaShareAlt />
                          </div>
                        </Tooltip>
                      </>
                    )}
                    <Tooltip
                      open={true}
                      title="Clear your dashboard"
                      placement="left"
                    >
                      <div
                        className="circle-class"
                        onClick={() => {
                          hideTable();
                          handleClear();
                          setIsEditing(false);
                          setOpenMobile(!openMobile);
                        }}
                      >
                        <GiBroom />
                      </div>
                    </Tooltip>

                    <Tooltip
                      open={true}
                      title="Export your workout in PDF"
                      placement="left"
                    >
                      <div
                        open={true}
                        className="circle-class"
                        onClick={() => {
                          handleOpenModal();
                          setOpenMobile(false);

                          // hideTable();
                          // handleClear();
                          // setIsEditing(false);
                          // setOpenMobile(!openMobile);
                        }}
                      >
                        <FaFilePdf />
                      </div>
                    </Tooltip>
                    <Tooltip
                      open={true}
                      title="Add new exercise"
                      placement="left"
                    >
                      <div
                        className="circle-class"
                        onClick={() => {
                          handleOpen();
                          setOpenMobile(!openMobile);
                        }}
                      >
                        <FaPlus />
                      </div>
                    </Tooltip>

                    <Tooltip
                      open={true}
                      title="Save your workout!"
                      placement="left"
                    >
                      <div
                        className="circle-class"
                        onClick={() => {
                          // saveWorkout();
                          // setOpenMobile(!openMobile);
                          // handleOpenModalTitle();
                          if (workoutName && workoutCategory) saveWorkout();
                          else {
                            setOpenMobile(!openMobile);
                            handleOpenModalTitle();
                          }
                        }}
                      >
                        <FaSave />
                      </div>
                    </Tooltip>

                    <Tooltip
                      open={true}
                      title="Load a workout"
                      placement="left"
                    >
                      <div
                        className={
                          isCreating ? "circle-class btn-bg" : "circle-class"
                        }
                        onClick={() => {
                          displayTable();
                          handleClear();
                          setOpenMobile(!openMobile);
                        }}
                      >
                        <GiCloudDownload />
                      </div>
                    </Tooltip>
                    <Tooltip
                      open={true}
                      title="Create new workout"
                      placement="left"
                    >
                      <div
                        className={
                          !isCreating ? "circle-class btn-bg" : "circle-class"
                        }
                        onClick={() => {
                          hideTable();
                          handleClear();
                          setIsEditing(false);
                          setOpenMobile(!openMobile);
                        }}
                      >
                        <FaFile />
                      </div>
                    </Tooltip>
                  </>
                )}
                {!openMobile && (
                  <Tooltip title="Add new exercise" placement="left">
                    <div
                      className="circle-class"
                      onClick={() => {
                        handleOpen();
                        // setOpenMobile(!openMobile);
                      }}
                    >
                      <FaPlus />
                    </div>
                  </Tooltip>
                )}
                <Tooltip title="Open menu" placement="left">
                  <div
                    className={
                      openMobile
                        ? "circle-class display-on btn2-bg"
                        : "circle-class display-on btn1-bg"
                      // : "circle-class display-off"
                    }
                    onClick={() => setOpenMobile(!openMobile)}
                  >
                    <MoreHorizIcon />
                  </div>
                </Tooltip>
              </div>
            )}
          </>
        )}
        <div className="create-ui">
          <h1 style={{ fontSize: "30px" }}>
            {!isCreating ? "Create your workout!" : "Load your workout"}
          </h1>
          {dimension && (
            <>
              {!id && (
                <div className="create-settings display-off">
                  {/* //TODO gestire il salvataggio e la condivisione dell'intera scheda */}

                  <Button
                    variant="outlined"
                    onClick={() => {
                      hideTable();
                      handleClear();
                      setIsEditing(false);
                    }}
                    className={!isCreating ? "btn-bg" : ""}
                  >
                    Create new workout
                  </Button>
                  {/* <Button
              variant="outlined"
              onClick={() => {
                handleClick();
                handleShare();
                successNotification("Link copied!");
              }}
            >
              share
            </Button> */}

                  <Button
                    variant="outlined"
                    onClick={() => {
                      displayTable();
                      handleClear();
                    }}
                    className={isCreating ? "btn-bg" : ""}
                  >
                    Load workout
                  </Button>
                  {/* <Button variant="outlined" onClick={saveWorkout}>
              {t("menu_crea_salva_workout")}
            </Button> */}
                  <Button variant="outlined" onClick={handleOpenModal}>
                    {t("menu_crea_esporta_workout")}
                  </Button>

                  {/* ---------------------------------------------- */}
                  {/* <Button variant="outlined" onClick={handleOpen}>
              {t("menu_crea_aggiungi_es")}
            </Button> */}
                  <Button variant="outlined" onClick={handleClearDahboard}>
                    {t("menu_crea_pulisci")}
                  </Button>

                  {/* {isCreating && (
              <Button variant="outlined" onClick={handleDelete}>
                Elimina
              </Button>
            )} */}
                </div>
              )}
            </>
          )}
          {id && (
            <div className="create-settings">
              {/* salva */}
              {/* <Button variant="outlined" onClick={saveWorkout}>
                {t("menu_crea_salva_workout")}
              </Button> */}
              {/* //esporta */}
              <Button
                className="view-btn"
                variant="outlined"
                onClick={handleOpenModal}
              >
                {t("menu_crea_esporta_workout")}
              </Button>

              {/* ---------------------------------------------- */}
              {/* <Button variant="outlined" onClick={handleOpen}>
                {t("menu_crea_aggiungi_es")}
              </Button> */}
            </div>
          )}
        </div>
        {/* <div>
          <Button onClick={getAllWorkout}>getAll</Button>
          <Button onClick={getWorkout}>getWorkout</Button>
        </div> */}
        {isCreating && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "grid",
              placeItems: "center",
              marginBottom: "30px",
            }}
          >
            {isCreating && (
              <div className="load-wk-input">
                {/* {workoutCategory && (
                  <> */}
                <div>
                  <p>Workout category</p>
                  <Autocomplete
                    disablePortal
                    id="user-category"
                    options={userCategory}
                    sx={{ width: 300 }}
                    onChange={(event, newValue) => {
                      setWorkoutCategory(newValue);
                      //TODO CALL API
                      handleChangeLoadCategory(newValue);
                    }}
                    // isOptionEqualToValue={() => {}}
                    // value={workoutCategory}
                    onFocus={getUserCategory}
                    renderInput={(params, option) => (
                      <TextField
                        // onChange={(e) => setWorkoutCategory(e.target.value)}
                        key={option}
                        {...params}
                        label={workoutCategory}
                        placeholder={
                          workoutCategory === ""
                            ? "Select a category"
                            : workoutCategory
                        }
                      />
                    )}
                  />
                </div>
                {/* </>
                )} */}
                {/* {workoutCategory && (
                  <> */}
                <div>
                  <p>Workout name</p>

                  <Autocomplete
                    disablePortal
                    // disabled={!workoutCategory ? true : false}
                    classes={styles}
                    // style={{ zIndex: "100" }}
                    id="user-workout"
                    ListboxProps={{ style: { maxHeight: "200px" } }}
                    options={allWorkout}
                    sx={{ width: 300 }}
                    onChange={(event, newValue) => {
                      setWorkoutName(newValue);
                      //TODO CALL API
                      //get all workout changed
                      // console.log(allWorkout);
                      // getAllWorkout();
                      //getAllData on workout changed
                    }}
                    // onFocus={getAllWorkout}
                    renderInput={(params, option) => (
                      <TextField
                        onChange={(e) => setWorkoutName(e.target.value)}
                        key={option}
                        {...params}
                        placeholder={
                          workoutName === ""
                            ? "Select a workout from yours"
                            : workoutName
                        }
                      />
                    )}
                  />
                </div>
                {/* </>
                )} */}
              </div>
            )}
          </div>
        )}
        {/* <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={workoutOne}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Select a workout" />
          )}
        /> */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
          className={
            workoutName !== "" && isCreating ? "display-on" : "display-off"
          }
        >
          <div className="flex-rev">
            {isCreating && workoutName && !isEditing && (
              <div className="modified-title-box-btn">
                <EditIcon
                  // style={{ fill: "gray", cursor: "pointer" }}
                  className="edit-icon"
                  onClick={() => setIsEditing(true)}
                />
              </div>
            )}
            {isEditing && (
              <div
                className="flex-center-or-between"
                style={{ gap: "10px", width: "100%" }}
              >
                <CloseIcon
                  // style={{ fill: "gray", cursor: "pointer" }}
                  className="edit-icon"
                  onClick={() => {
                    setIsEditing(false);
                    setWorkoutNameEdited("");
                  }}
                />
                <Button
                  variant="outlined"
                  style={{
                    fontSize: "10px",
                    color: "gray",
                    borderColor: "gray",
                  }}
                  onClick={() => {
                    setIsEditing(false);
                  }}
                >
                  save
                </Button>
              </div>
            )}
            {isCreating && (
              <div className="title-desc-box">
                <div className="flex-in">
                  <h1>{t("menu_crea_titolo")}:</h1>
                  {/* {!isCreating && !id && (
                  <TextField
                    fullWidth
                    style={{ color: "gray" }}
                    onChange={(e) => setWorkoutName(e.target.value)}
                    variant="outlined"
                  />
                )} */}
                  {!isCreating && (
                    <TextField
                      fullWidth
                      style={{ color: "gray" }}
                      onChange={(e) => setWorkoutName(e.target.value)}
                      variant="outlined"
                    />
                  )}
                  <div className="center">
                    {!isEditing && workoutNameEdited ? (
                      <h2>{workoutNameEdited}</h2>
                    ) : (
                      <h2>{workoutName}</h2>
                    )}
                  </div>

                  {/* <h1>{workoutName}</h1> */}
                  {isEditing && isCreating && (
                    <TextField
                      onChange={(e) => setWorkoutNameEdited(e.target.value)}
                      variant="outlined"
                      label={
                        workoutNameEdited ? workoutNameEdited : workoutName
                      }
                    />
                  )}
                </div>
                <div className="flex-in">
                  <h1>Collection:</h1>

                  {/* {!isCreating && !id && (
                  <TextField
                    fullWidth
                    value={workoutCategory}
                    style={{ color: "gray" }}
                    onChange={(e) => setWorkoutCategory(e.target.value)}
                    variant="outlined"
                  />
                )} */}
                  {!isCreating && (
                    <TextField
                      fullWidth
                      value={workoutCategory}
                      style={{ color: "gray" }}
                      onChange={(e) => setWorkoutCategory(e.target.value)}
                      variant="outlined"
                    />
                  )}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    {isEditing ? (
                      <TextField
                        fullWidth
                        value={workoutCategory}
                        onChange={(e) => setWorkoutCategory(e.target.value)}
                        variant="outlined"
                      />
                    ) : (
                      <h2>{workoutCategory}</h2>
                    )}

                    {/* {!isEditing && <EditIcon />} */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <div className="center" style={{ width: "100%" }}>
          {!isCreating && !id && (
            <div className="title-desc-box-create">
              <div className="flex-in2">
                <h1>{t("menu_crea_titolo")}:</h1>

                <TextField
                  fullWidth
                  style={{ color: "gray" }}
                  onChange={(e) => setWorkoutName(e.target.value)}
                  variant="outlined"
                />
              </div>
              <div className="flex-in2">
                <h1>Collection:</h1>
                <TextField
                  fullWidth
                  value={workoutCategory}
                  style={{ color: "gray" }}
                  onChange={(e) => setWorkoutCategory(e.target.value)}
                  variant="outlined"
                />
              </div>
            </div>
          )}
        </div> */}
        {/* <Autocomplete
          disablePortal
          id="user-category"
          options={userCategory}
          sx={{ width: 300 }}
          onChange={(event, newValue) => {
            setWorkoutCategory(newValue);
            //TODO CALL API
          }}
          isOptionEqualToValue={() => {}}
          value={workoutCategory}
          onFocus={getUserCategory}
          renderInput={(params) => (
            <TextField
              onChange={(e) => setWorkoutCategory(e.target.value)}
              {...params}
              label={
                workoutCategory === ""
                  ? "Select a category from yours or digit new"
                  : workoutCategory
              }
            />
          )}
        /> */}
        <div className="workout-area-box" id="content">
          <div className="workout-area">
            {workout.map((w, index) => (
              <div
                className="workout-card"
                key={index}
                onClick={() => handleEditWorkout(index)}
              >
                <WorkoutCard
                  item={w}
                  col={allSport["columns"][userLang]}
                  lang={userLang}
                />
              </div>
            ))}
          </div>
        </div>
        {/* input form */}
        <Drawer
          anchor={dimension ? "bottom" : "right"}
          open={open}
          onClose={handleClose}
        >
          <div className="create-view" id="drawer-container">
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>

            {/* exercise name section */}
            <h5>{t("menu_crea_aggiungi_nome_es")}</h5>

            {/* <TextField
              onChange={(e) => setExerciseName(e.target.value)}
              placeholder={t("menu_crea_aggiungi_nome_es")}
              variant="outlined"
            /> */}
            <h1>
              {supersetExercise ? exerciseName + " (superset)" : exerciseName}
            </h1>

            <div className="autocomplete-desc">
              {/* sport */}
              {/* <FormControl fullWidth>
                <TextField
                  id="sport"
                  value={sport}
                  select
                  onChange={handleChangeSport}
                  label={"sport"}
                  // InputLabelProps={{ shrink: false }}
                >
                  {allSport["sport"][userLang].map((el, ind) => (
                    <MenuItem key={ind + "a"} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl> */}

              {/* category */}
              <Autocomplete
                disablePortal={true}
                classes={styles}
                id="category"
                options={exCategoryList}
                fullWidth
                onChange={(event, newValue) => {
                  setExCategory(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Category" />
                )}
              />

              {/* Exercise */}
              {!multipleExercise && (
                <Autocomplete
                  id="exercise-list"
                  classes={styles}
                  fullWidth
                  options={exercise}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => {
                    if (option) {
                      return (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img width="50" src={option.img} alt="" />
                          {option.name}
                        </Box>
                      );
                    }
                  }}
                  onChange={(event, newValue) => {
                    // console.log("cane", newValue);
                    if (newValue) {
                      setImgUrl(newValue.img);
                      setExerciseName(newValue.name);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Exercise"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-ex", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              )}
              {multipleExercise && (
                <Autocomplete
                  multiple
                  id="exercise-list2"
                  classes={styles}
                  fullWidth
                  options={exercise}
                  disableCloseOnSelect
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      <img width="50" src={option.img} alt="" />
                      {option.name}
                    </li>
                  )}
                  onChange={(event, newValue) => {
                    setMultipleExerciseArray(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Exercise"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "Add-ex", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              )}
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <h6>Add more than one ex</h6>
              <Switch
                onChange={() => {
                  setMultipleExercise(!supersetExercise && !multipleExercise);
                  // setExCategoryList([]);
                  // setMultipleExerciseArray([{}]);
                  // setExerciseName("");
                  // generalRow();
                }}
                checked={multipleExercise}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <h6>Is it a superset ?</h6>
              <Switch
                onChange={() => {
                  setSupersetExercise(!multipleExercise && !supersetExercise);
                }}
                checked={supersetExercise}
              />
            </div>
            <Button
              variant="outlined"
              onClick={() => {
                handleOpenBottom();
                handleExOpen();
              }}
            >
              {/* ADD SET */}
              {t("menu_crea_aggiungi_set")}
            </Button>

            {/* SAVE BUTTON  */}
            {workoutEditable ? (
              <>
                <Button
                  variant="outlined"
                  onClick={() => {
                    // checkSuperset();
                    handleSaveWorkout();
                  }}
                >
                  {t("menu_crea_aggiungi_salva")}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    // checkSuperset();
                    handleDeleteWorkout();
                  }}
                >
                  {t("menu_crea_aggiungi_elimina")}
                </Button>
              </>
            ) : (
              <Button variant="outlined" onClick={handleSaveEx}>
                {t("menu_crea_aggiungi_salva")}
              </Button>
            )}

            {/* table preview*/}
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 400, minHeight: 50 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    {allSport["columns"][userLang].map((col, ind) => (
                      <TableCell key={ind + "c"} align="center">
                        {col}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={index + "d"}
                      onClick={() => {
                        handleOpenBottom();
                        setRowGen(row);
                      }}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center" component="th" scope="row">
                        {row.set}
                      </TableCell>
                      <TableCell align="center">{row.rep}</TableCell>
                      <TableCell align="center">{row.weight}</TableCell>
                      <TableCell align="center">{row.rec}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Drawer>
        {/* input form field */}
        <Drawer
          className="drawer-btn"
          variant="temporary"
          anchor="bottom"
          open={openBottom}
          onClose={handleCloseBottom}
        >
          <Button variant="outlined" onClick={handleCloseBottom}>
            Close
          </Button>
          <div className="create-input">
            {columns.length !== 0 &&
              columns.map((col, index) => {
                if (col === "set") {
                  return (
                    <TextField
                      key={index + "ff"}
                      InputProps={{
                        readOnly: true,
                      }}
                      type="number"
                      onChange={(e) => handleRowChange(e.target.value, col)}
                      placeholder={
                        saveState
                          ? (rows.length + 1).toString()
                          : rowGen[col].toString()
                      }
                      variant="outlined"
                    />
                  );
                } else {
                  return (
                    <TextField
                      key={index + "h"}
                      required
                      type="number"
                      onChange={(e) => handleRowChange(e.target.value, col)}
                      placeholder={
                        saveState ? col.toString() : rowGen[col].toString()
                      }
                      variant="outlined"
                    />
                  );
                }
              })}
          </div>
          {saveState ? (
            <Button onClick={handleRowAdd} variant="outlined">
              {t("menu_crea_aggiungi_es_aggiungi")}
            </Button>
          ) : (
            <>
              <Button onClick={handleRowSave} variant="outlined">
                {t("menu_crea_aggiungi_es_modifica")}
              </Button>
              <Button onClick={handleRowDelete} variant="outlined">
                {t("menu_crea_aggiungi_es_elimina")}
              </Button>
            </>
          )}
        </Drawer>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* //todo translate */}
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Choose the pdf design
            </Typography>
            <Box style={{ padding: "10px 0" }}>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Filename
              </FormLabel>
              <TextField
                required
                onChange={(e) => handlePdfName(e.target.value)}
                placeholder="saveAs"
                variant="outlined"
              />
            </Box>

            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Layout
              </FormLabel>
              <RadioGroup
                style={{ flexDirection: "row", padding: "10px 0" }}
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="table"
                  control={<Radio />}
                  label="Table"
                />
                <FormControlLabel
                  value="circle"
                  control={<Radio />}
                  label="Circle"
                />
              </RadioGroup>
            </FormControl>

            {/* //todo translate */}
            <Button
              className="grey-btn"
              onClick={handleExport}
              variant="outlined"
            >
              Confirm
            </Button>
          </Box>
        </Modal>

        <Modal
          open={openModalTitle}
          onClose={handleCloseModalTitle}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="modal-save-title">
              <div className="flex-in3">
                <h1>{t("menu_crea_titolo")}:</h1>
                <TextField
                  fullWidth
                  style={{ color: "gray" }}
                  onChange={(e) => setWorkoutName(e.target.value)}
                  variant="outlined"
                />
              </div>
              <div className="flex-in3">
                <h1>Collection:</h1>

                <TextField
                  fullWidth
                  value={workoutCategory}
                  style={{ color: "gray" }}
                  onChange={(e) => setWorkoutCategory(e.target.value)}
                  variant="outlined"
                />
              </div>

              <div className="flex-in3">
                <p>or... select from yours</p>

                {/* <TextField
                  fullWidth
                  value={workoutCategory}
                  style={{ color: "gray" }}
                  onChange={(e) => setWorkoutCategory(e.target.value)}
                  variant="outlined"
                /> */}
                <Autocomplete
                  disablePortal
                  id="user-category"
                  options={userCategory}
                  sx={{ width: 300 }}
                  onChange={(event, newValue) => {
                    setWorkoutCategory(newValue);
                    //TODO CALL API
                  }}
                  // isOptionEqualToValue={() => {}}
                  // value={workoutCategory}
                  onFocus={getUserCategory}
                  renderInput={(params, option) => (
                    <TextField
                      // onChange={(e) => setWorkoutCategory(e.target.value)}
                      key={option}
                      {...params}
                      // label={
                      //   workoutCategory === ""
                      //     ? "Select a category from yours or digit new"
                      //     : workoutCategory
                      // }
                    />
                  )}
                />
              </div>

              <Button
                fullWidth
                variant="outlined"
                onClick={() => {
                  saveWorkout();
                  setOpenMobile(openMobile && !openMobile);
                  handleCloseModalTitle();
                }}
              >
                Save your workout
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
    </Fade>
  );
}
