import { Button, TextField } from "@mui/material";
import React from "react";

import "./Password.css";

export default function Password() {
  return (
    <div className="edit-pw">
      <div className="edit-pw-container">
        <div className="pw-title">
          <h1>Change your password</h1>
        </div>

        <div className="pw-item curr">
          <h6>Current password</h6>
          <TextField variant="outlined" fullWidth />
        </div>

        <div className="pw-item">
          <h6>New password</h6>
          <TextField variant="outlined" fullWidth />
        </div>

        <div className="pw-item">
          <h6>Repeat new password</h6>
          <TextField variant="outlined" fullWidth />
        </div>

        <div className="pw-btn">
          <Button className="general-btn cancel-btn" variant="outlined">
            Cancel
          </Button>
          <Button className="general-btn new-btn" variant="outlined">
            Set new password
          </Button>
        </div>
      </div>
    </div>
  );
}
