import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@material-ui/core/Avatar";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";

//notification
import {
  successNotification,
  errorNotification,
  warningNotification,
} from "../../../components/notification/Notification";

//transition
import Fade from "@mui/material/Fade";

//firebase
import { auth } from "../../../firebase-config";

//router
import {
  useNavigate,
  useLocation,
  useParams,
  useOutletContext,
} from "react-router-dom";

import "./General.css";
import i18next from "i18next";
import cookies from "js-cookie";
import axios from "axios";

const languages = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "it",
    name: "Italiano",
  },
  {
    code: "es",
    name: "Espanol",
  },
  {
    code: "de",
    name: "Deutch",
  },
  {
    code: "fr",
    name: "Francais",
  },
  {
    code: "jpn",
    name: "Japan",
  },
];

export default function General() {
  //nav
  let navigate = useNavigate();

  // image upload
  // var file = event.target.files[0];
  // const reader = new FileReader();
  // var url = reader.readAsDataURL(file);

  // reader.onloadend = function (e) {
  //   this.setState({
  //     selectedFile: [reader.result],
  //   });
  // }.bind(this);

  //user
  // const auth = getAuth();
  // const user = auth.currentUser;
  const userLocalUid = localStorage.getItem("user");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //info obj
  const [userInfo, setUserInfo] = useState({
    _id: "",
    name: "",
    last_name: "",
    lang: "",
    email: "",
    weight: "",
    role: "",
    firstAccess: true,
  });
  //language cookie
  // language cookies
  // const [currentLanguageCode, setCurrentLanguageCode] = useState("");
  const currentLanguageCode = cookies.get("i18next") || userInfo.lang || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    if (userLocalUid) {
      let tmp = { ...userInfo };
      tmp._id = userLocalUid;
      setUserInfo(tmp);
    }
  }, []);

  // console.log(userInfo);

  useEffect(() => {
    setUserInfo({ ...userInfo, lang: currentLanguage.code });
  }, []);

  //weight control
  const handleChangeWeight = (event) => {
    setUserInfo({ ...userInfo, [event.target.name]: event.target.value });
  };

  //API_CALL
  const saveInfo = async () => {
    if (userLocalUid) {
      await axios
        .post("https://yoxeen.ks-hub.com:4000/user", userInfo, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((result) => {
          successNotification("Profile Update");
        })
        .catch((error) => console.log(error));
    }
  };

  const deleteAccount = async () => {
    if (userLocalUid) {
      axios
        .delete("https://yoxeen.ks-hub.com:4000/user")
        .then(() => {
          successNotification("Account deleted");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleLogOut = () => {
    auth.signOut().then(
      () => {
        localStorage.removeItem("user");
        navigate("/log");
      },
      (error) => {
        console.log("error", error);
      }
    );
  };

  //get user info
  const getInfo = async () => {
    if (userLocalUid) {
      // console.log("sto chiedendo");
      await axios
        .get("https://yoxeen.ks-hub.com:4000/user", {
          params: { _id: userLocalUid },
        })
        .then((result) => {
          setUserInfo(result.data);
          i18next.changeLanguage(result.data.lang);
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <Fade in={true} {...(true ? { timeout: 500 } : {})}>
      <div className="general">
        <div className="general-container">
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {languages.map(({ code, name }) => {
              return (
                <MenuItem
                  disabled={code === currentLanguageCode}
                  key={code}
                  onClick={() => {
                    handleClose();
                    i18next.changeLanguage(code);
                    let tmp = { ...userInfo };
                    tmp.lang = code;
                    setUserInfo(tmp);
                  }}
                >
                  {name}
                </MenuItem>
              );
            })}
          </Menu>

          <div className="account-overview">
            <div className="account-title">
              <h1>Account overview</h1>
            </div>

            <div className="account-profile-picture">
              <img
                src="https://source.unsplash.com/random"
                alt=""
                width="100px"
                height="100px"
              />
              {/* <Avatar src={url} /> */}
              {/* <TextField variant="outlined" Accept/> */}
              {/* <input
                accept="image/*"
                id="contained-button-file"
                type="file"
              /> */}
              {/* <TextField type="file" accept="image/*" variant="outlined" /> */}
              <form class="form">
                <div class="file-upload-wrapper" data-text="Select your file!">
                  <input
                    name="file-upload-field"
                    type="file"
                    class="file-upload-field"
                    value=""
                  />
                </div>
              </form>
            </div>

            <div className="account-info">
              <h3>Profile</h3>

              <div className="account-single-info">
                <h4>Name</h4>
                {userInfo && <h4>{userInfo.name}</h4>}
              </div>

              <div className="account-single-info">
                <h4>Last-name</h4>
                {userInfo && <h4>{userInfo.last_name}</h4>}
              </div>

              <div className="account-single-info">
                <h4>E-mail</h4>
                {userInfo && <h4>{userInfo.email}</h4>}
              </div>

              <div className="account-single-info">
                <h4>role</h4>
                {userInfo && <h4>{userInfo.role}</h4>}
              </div>

              <div className="account-single-info">
                <h4
                  // id="basic-button"
                  // aria-controls={open ? "basic-menu" : undefined}
                  // aria-haspopup="true"
                  // aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  Language
                </h4>
                {userInfo ? <h4>{userInfo.lang}</h4> : <h4>en</h4>}
              </div>
            </div>
          </div>

          {/* <Button className="general-btn" variant="outlined" onClick={saveInfo}>
            save
          </Button> */}
          <Button className="general-btn" variant="outlined" onClick={saveInfo}>
            Edit Profile
          </Button>

          <h3>Your Plan</h3>
          <div className="general-fee">
            <div className="general-fee-container">
              <div className="general-fee-title">
                <h1>Free Plan</h1>{" "}
              </div>
              <div className="general-fee-content">
                <p>
                  If you like the app, support the creators with a donation.{" "}
                  <a href="http://www.google.it">Donate now</a>
                </p>
              </div>
            </div>
          </div>

          <h3>Coming Soon</h3>
          <div className="general-coming-soon">
            <ul className="coming-soon-list-container">
              {[
                "Nutrition plan",
                "Fitness Social network",
                "Content powerup",
                "Podcast",
              ].map((el, ind) => (
                <li key={ind + "xx"} className="coming-soon-list">
                  <h4>{el}</h4>
                </li>
              ))}
            </ul>
          </div>

          <div className="general-action-btn">
            <Button
              className="general-btn"
              variant="outlined"
              onClick={deleteAccount}
            >
              Delete Account
            </Button>
            <Button
              className="general-btn"
              variant="outlined"
              onClick={handleLogOut}
            >
              Sign Out
            </Button>
          </div>
        </div>
      </div>
    </Fade>
  );
}
