import React from "react";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Link } from "react-router-dom";
import "./ModPres.css";

const translateSlide = () => {
    let container = document.querySelector(".module-container");

    console.log("entrato", container);
    window.scrollTo({
        top: 94 * window.innerHeight/100,
        behavior:'smooth',
        });
    }

export default function ModPres(props) {

    return (
        <div className={`${props.sideState ? "active" : ""}`}>
            <div className="modpres-container">
                <div className="modpres-container-presentation">
                    <div className="modpres-container-presentation-box">

                    <h1>INIZIA A COSTRUIRE LA TUA SCHEDA PERSONALIZZATA</h1>
                    <ArrowDownwardIcon sx={{fontSize: 50}} className="modpres-arrow" onClick={translateSlide} />
                    </div>
                </div>

                <div className="modpres-info">

                    <h1>PRONTI PER COMINCIATE</h1>
                    <Link className="modpres-info-btn" to="/modpres/module">INIZIAMO</Link>

                </div>
            </div>
        </div>
    )
}