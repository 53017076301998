import React from "react";

//react select
import Select from "react-select";

//mui
import { TextField, Button } from "@mui/material";

import "./Profile.css";

export default function Profile() {
  //TODO API CALL

  //Languages options
  const languages = [
    {
      value: "en",
      label: "English",
    },
    {
      value: "it",
      label: "Italiano",
    },
    {
      value: "es",
      label: "Espanol",
    },
    {
      value: "de",
      label: "Deutch",
    },
    {
      value: "fr",
      label: "Francais",
    },
    {
      value: "jpn",
      label: "Japan",
    },
  ];

  //search curr lang
  const [currLang, setCurrLang] = React.useState({
    value: "en",
    label: "English",
  });

  // gestisce la visualizzazione in base al filtro applicato
  const handleChangeFilter = (selectedOption) => {
    setCurrLang(selectedOption);
    // cane(selectedOption);
  };

  return (
    <div className="profile">
      <div className="profile-container">
        <div className="profile-title">
          <h1>Edit Profile</h1>
        </div>
        <div className="profile-single-item curr">
          <h6>Name</h6>
          <TextField variant="outlined" fullWidth />
        </div>
        <div className="profile-single-item">
          <h6>Last Name</h6>
          <TextField variant="outlined" fullWidth />
        </div>
        <div className="profile-single-item">
          <h6>Email</h6>
          <TextField variant="outlined" fullWidth />
        </div>

        <div className="profile-single-item">
          <h6>Language</h6>
          <Select
            styles={{ width: "100%" }}
            className="profile-select"
            placeholder="All Sport"
            defaultValue={{ value: "en", label: "English" }}
            value={currLang}
            onChange={handleChangeFilter}
            options={languages}
          />
        </div>

        <div className="pw-btn">
          <Button className="general-btn cancel-btn" variant="outlined">
            Cancel
          </Button>
          <Button className="general-btn new-btn" variant="outlined">
            Save profile
          </Button>
        </div>
      </div>
    </div>
  );
}
