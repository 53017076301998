import React, { useEffect, useState } from "react";

// import React, { PureComponent } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import "./Progress.css";

const { getRequestHeaders, getWeeklyData } = require("./DataRequestManager.js");

const Dashboard = (props) => {
  // fetch weekly data
  const accessToken = props.user.accessToken;
  const [weekData, setWeekData] = useState([]);
  const [weekDataShow, setWeekDataShow] = useState([]);
  const [weekDataAvarage, setWeekDataAvarage] = useState({
    minCal: 0,
    maxCal: 0,
    minMove: 0,
    maxMove: 0,
    minSteps: 0,
    maxSteps: 0,
    minHeart: 0,
    maxHeart: 0,
    avgCalories: 0,
    avgHeart: 0,
    avgMove: 0,
    avgSteps: 0,
    totCalories: 0,
    totHeart: 0,
    totMove: 0,
    totSteps: 0,
  });

  // let weekData = [];

  // let selected = [0, 1, 2, 3, 4, 5, 6];
  const callBack = (state) => {
    setWeekData(state);
  };
  const requestHeaders = getRequestHeaders(accessToken);
  const timeRightNow = new Date().getTime();
  getWeeklyData(timeRightNow, requestHeaders, callBack, weekData);

  console.log("weeks", weekData);

  useEffect(() => {
    if (weekData) {
      let tmp = { ...weekDataAvarage };
      let c = [];
      let h = [];
      let m = [];
      let s = [];
      let calories = 0;
      let heart = 0;
      let move = 0;
      let steps = 0;
      //   let mincalories = 0;
      //   let minheart = 0;
      //   let minmove = 0;
      //   let minsteps = 0;
      //   let maxcalories = 0;
      //   let maxheart = 0;
      //   let maxmove = 0;
      //   let maxsteps = 0;
      weekData.forEach((w) => {
        let d = w.Date;
        if (d instanceof Date) {
          let x = d.getDate() + "-" + d.getMonth() + "-" + d.getFullYear();
          w.Date = x;
        }
        c.push(w.Calories);
        h.push(w.Heart);
        m.push(w.Move);
        s.push(w.Steps);

        calories += w.Calories;
        heart += w.Heart;
        move += w.Move;
        steps += w.Steps;
      });

      tmp.totCalories = calories.toFixed(0);
      tmp.totHeart = heart.toFixed(0);
      tmp.totMove = move.toFixed(0);
      tmp.totSteps = steps.toFixed(0);
      tmp.minCal = Math.min(...c).toFixed(0);
      tmp.maxCal = Math.max(...c).toFixed(0);
      tmp.minMove = Math.min(...m).toFixed(0);
      tmp.maxMove = Math.max(...m).toFixed(0);
      tmp.minSteps = Math.min(...s).toFixed(0);
      tmp.maxSteps = Math.max(...s).toFixed(0);
      tmp.minHeart = Math.min(...h).toFixed(0);
      tmp.maxHeart = Math.max(...h).toFixed(0);
      tmp.avgCalories = (calories / 7).toFixed(0);
      tmp.avgHeart = (heart / 7).toFixed(0);
      tmp.avgMove = (move / 7).toFixed(0);
      tmp.avgSteps = (steps / 7).toFixed(0);
      setWeekDataAvarage(tmp);
      setWeekDataShow(weekData);
      console.log(weekData);
    }
  }, [weekData]);

  return (
    <>
      <div className="google-data-details">
        <h4>Last 7 days summary</h4>
        <div className="google-data-details-item bl">
          <h5>Tot calories week abvarage burn: </h5>
          <h5>{weekDataAvarage.totCalories} kcal</h5>
        </div>
        <div className="google-data-details-item">
          <h5>Calories week abvarage burn: </h5>
          <h5>{weekDataAvarage.avgCalories} kcal</h5>
        </div>
        <div className="google-data-details-item">
          <h5>Min Calories week abvarage burn:</h5>
          <h5>{weekDataAvarage.minCal} kcal</h5>
        </div>
        <div className="google-data-details-item">
          <h5>Max Calories week abvarage burn:</h5>
          <h5>{weekDataAvarage.maxCal} kcal</h5>
        </div>
        <div className="google-data-details-item bl">
          <h5>Tot activity time: </h5>
          <h5>{weekDataAvarage.totMove} min</h5>
        </div>
        <div className="google-data-details-item">
          <h5>Avarage of activity time:</h5>
          <h5>{weekDataAvarage.avgMove} min</h5>
        </div>
        <div className="google-data-details-item">
          <h5>Min of activity time:</h5>
          <h5>{weekDataAvarage.minMove} min</h5>
        </div>
        <div className="google-data-details-item">
          <h5>Max of activity time</h5>
          <h5>{weekDataAvarage.maxMove} min</h5>
        </div>
        <div className="google-data-details-item bl">
          <h5>Tot step achived: </h5>
          <h5>{weekDataAvarage.totSteps} steps</h5>
        </div>
        <div className="google-data-details-item">
          <h5>Step week avarage counted:</h5>
          <h5>{weekDataAvarage.avgSteps} steps</h5>
        </div>
        <div className="google-data-details-item">
          <h5>Min step week avarage counted:</h5>
          <h5>{weekDataAvarage.minSteps} steps</h5>
        </div>
        <div className="google-data-details-item">
          <h5> Max step week avarage counted:</h5>
          <h5>{weekDataAvarage.maxSteps} steps</h5>
        </div>
        <div className="google-data-details-item bl">
          <h5>Tot Heart points: </h5>
          <h5>{weekDataAvarage.totHeart} bpm</h5>
        </div>
        <div className="google-data-details-item">
          <h5>Heart week avarage burn:</h5>
          <h5>{weekDataAvarage.avgHeart} bpm</h5>
        </div>
        <div className="google-data-details-item">
          <h5>Min heart week avarage burn:</h5>
          <h5>{weekDataAvarage.minHeart} bpm</h5>
        </div>
        <div className="google-data-details-item">
          <h5>Max heart week avarage burn:</h5>
          <h5>{weekDataAvarage.maxHeart} bpm</h5>
        </div>
      </div>
      <div className="google-chart">
        <div className="google-chart-box">
          <ResponsiveContainer>
            <AreaChart data={weekDataShow}>
              <defs>
                <linearGradient id="lg" x1="0" y1="0" x2="2" y2="1">
                  <stop offset="0%" stopColor="#0a5d6b" />
                  <stop offset="100%" stopColor="#fff" />
                </linearGradient>
              </defs>
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <XAxis dataKey="Date" />
              <YAxis />
              <Tooltip
                contentStyle={{
                  wordBreak: "break-all",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  backgroundColor: "#0a5d6b",
                  color: "#fff",
                }}
                itemStyle={{ color: "#fff" }}
              />
              <Legend />
              <Area
                type="monotone"
                dataKey="Calories"
                stroke="#8884d8"
                fill="url(#lg)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>

        <div className="google-chart-box">
          <ResponsiveContainer>
            <AreaChart data={weekData}>
              <defs>
                <linearGradient id="lg" x1="0" y1="0" x2="2" y2="1">
                  <stop offset="0%" stopColor="#0a5d6b" />
                  <stop offset="100%" stopColor="#fff" />
                </linearGradient>
              </defs>
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <XAxis dataKey="Date" />
              <YAxis />
              <Tooltip
                contentStyle={{
                  wordBreak: "break-all",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  backgroundColor: "#0a5d6b",
                  color: "#fff",
                }}
                itemStyle={{ color: "#fff" }}
              />
              <Legend />
              <Area
                type="monotone"
                dataKey="Heart"
                stroke="#8884d8"
                fill="url(#lg)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <div className="google-chart-box">
          <ResponsiveContainer>
            <AreaChart data={weekData}>
              <defs>
                <linearGradient id="lg" x1="0" y1="0" x2="2" y2="1">
                  <stop offset="0%" stopColor="#0a5d6b" />
                  <stop offset="100%" stopColor="#fff" />
                </linearGradient>
              </defs>
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <XAxis dataKey="Date" />
              <YAxis />
              <Tooltip
                contentStyle={{
                  wordBreak: "break-all",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  backgroundColor: "#0a5d6b",
                  color: "#fff",
                }}
                itemStyle={{ color: "#fff" }}
              />
              <Legend />
              <Area
                type="monotone"
                dataKey="Move"
                stroke="#8884d8"
                fill="url(#lg)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <div className="google-chart-box">
          <ResponsiveContainer>
            <AreaChart data={weekData}>
              <defs>
                <linearGradient id="lg" x1="0" y1="0" x2="2" y2="1">
                  <stop offset="0%" stopColor="#0a5d6b" />
                  <stop offset="100%" stopColor="#fff" />
                </linearGradient>
              </defs>
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <XAxis dataKey="Date" />
              <YAxis />
              <Tooltip
                contentStyle={{
                  wordBreak: "break-all",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  backgroundColor: "#0a5d6b",
                  color: "#fff",
                }}
                itemStyle={{ color: "#fff" }}
              />
              <Legend />
              <Area
                type="monotone"
                dataKey="Steps"
                stroke="#8884d8"
                fill="url(#lg)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
