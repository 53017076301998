import React from "react";
import "./CardSport.css";

export default function CardSport(props) {
  return (
    <>
      {props.workout ? (
        <div className="cardsport-item">
          <h5>{props.workout}</h5>
        </div>
      ) : (
        <div className="cardsport-sport">
          <h5>{props.sport}</h5>
        </div>
      )}
    </>

    // <div className="card-container" style={{ margin: "10px" }}>
    //   <div className="sidebar-logo-container">
    //     <div className="sidebar-logo"></div>
    //   </div>

    //   <h1> {props.titolo} </h1>

    //   {props.descrizione.map((row, index) => {
    //     return <p key={index}>{row}</p>;
    //   })}
    // </div>
  );
}
