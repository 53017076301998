import React, { useEffect, useState } from "react";
import { useNavigate, Outlet, useParams } from "react-router-dom";
import Axios from "axios";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

//transition
import Fade from "@mui/material/Fade";

import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

import ImageSlider from "../../../components/carousel/ImageSlider";
import allSport from "./../sportLang.json";
import "./Explore.css";

const cat = [
  {
    name: "Palestra",
    img: "https://source.unsplash.com/Kl2t5U6Gkm0",
  },
  {
    name: "Calcio",
    img: "https://source.unsplash.com/iKJULbPcilA",
  },
  {
    name: "Nuoto",
    img: "https://source.unsplash.com/F20aPGvyhrQ",
  },
  {
    name: "Basket",
    img: "https://source.unsplash.com/QAX5Ylx-lKo",
  },
  {
    name: "Tennis",
    img: "https://source.unsplash.com/6D2Lmtv_X8A",
  },
  {
    name: "Box",
    img: "https://source.unsplash.com/XmvuWRDimrg",
  },
];

export default function Explore(props) {
  //search parameter
  const [search, setSearch] = useState("");
  //Gestisce la ricerca
  function handleSearch(e) {
    setSearch(e.target.value);
    // nuovaRichiesta = true;
    // console.log("ciao");
    // const timeOutId = setTimeout(() => getData(sortBy, e.target.value), 500);
    // return () => clearTimeout(timeOutId);
  }
  //clear ricerca
  function clearSearch() {
    setSearch("");
  }

  //navigate
  let navigate = useNavigate();

  //userUid
  const userLocalUid = localStorage.getItem("user");

  //category list
  const [category, setCategory] = useState(cat);

  const { sport } = useParams();

  // console.log(sport);

  //go to top
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);

  useEffect(() => {
    getLang();
  }, []);

  //user setting info
  const [userLang, setUserLang] = useState("en");

  const getLang = async () => {
    if (userLocalUid) {
      // console.log("utente", userLocalUid);
      await Axios.get("https://yoxeen.ks-hub.com:4000/user/", {
        params: { _id: userLocalUid },
      })
        .then((result) => {
          setUserLang(result.data.lang);
          let tmp = [...category];
          let sport_tmp = [...allSport["sport"][result.data.lang]];
          tmp.map((el, ind) => {
            el.name = sport_tmp[ind];
          });
          setCategory(tmp);
          // setColumns(allSport["columns"][result.data.lang]);
        })
        .catch((error) => console.log("error d", error));
    }
  };

  //go to category
  const goCategory = (ca) => {
    // console.log("cazzo", ca);
    // console.log("logger", ca);
    navigate("/explore/" + ca);
  };
  return (
    <Fade in={true} {...(true ? { timeout: 500 } : {})}>
      <div className="explore">
        {sport ? (
          <div className="">
            <Outlet />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "10px",
                height: "120px",
              }}
            >
              <h1>Explore</h1>
              <TextField
                sx={{ marginTop: 1, minWidth: "350px" }}
                value={search}
                onChange={handleSearch}
                label={""}
                autoComplete="off"
                InputLabelProps={{ shrink: false }}
                placeholder={"Search for any exercise in the database"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {search !== "" && (
                        <CloseIcon
                          style={{ cursor: "pointer" }}
                          onClick={clearSearch}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            </div>

            <div className="explore-container">
              {/* <div className="explore-container-slider">
                <ImageSlider />
              </div>

              <div className="explore-community">Community</div> */}

              <div className="explore-info-container">
                {/* <div>
              <h5>Category</h5>
              <div className="explore-container-category">
                {category.map((c, index) => (
                  <div
                    key={index + "a"}
                    className="explore-container-category-item"
                  >
                    {c}
                  </div>
                ))}
              </div>
            </div> */}

                <div
                  style={{
                    display: "grid",
                    placeItems: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <h5
                    style={{
                      textAlign: "center",
                      maxWidth: "800px",
                      fontSize: "14px",
                    }}
                  >
                    Start from clicking one of the sports displayed to explore
                    our exercises section and also specific workouts for each
                    sports and level! Yoxeen provide a wide range of exercise
                    for individual training. We think that the differce start
                    from you, and the workout are just a starter point to become
                    a beast in wathever you're in. Stay motivated, stay focuse,
                    stay tuned for YOURSELF.
                  </h5>
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <h5>Coaching e benessere</h5>
                    <h5>vedi tutto</h5>
                  </div>
                  <div className="explore-container-category">
                    {category.map((c, index) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          cursor: "pointer",
                        }}
                        key={index + "q"}
                        onClick={() => goCategory(c.name)}
                      >
                        <div className="explore-container-category-item">
                          <div className="explore-item-img">
                            <img src={c.img} alt="" />
                          </div>
                        </div>
                        <div className="explore-item-desc">{c.name}</div>
                      </div>
                    ))}
                  </div> */}

                  {/* version 2 */}
                  <div className="explore-category-box">
                    {category.map((c, index) => {
                      if (c.name !== allSport["palestra"][userLang]) {
                        return (
                          <div
                            className="category-item2"
                            key={index + "q"}
                            // onClick={() => goCategory(c.name)}
                          >
                            <div className="avaible"></div>
                            <div className="explore-category-item-img">
                              <img src={c.img} alt="" />
                            </div>

                            <div className="explore-category-item-desc">
                              {c.name}
                            </div>
                          </div>
                        );
                      } else {
                        // console.log(c.name);
                        return (
                          <div
                            className="category-item"
                            key={index + "q"}
                            onClick={() => goCategory(cat[index].name)}
                          >
                            <div className="explore-category-item-img">
                              <img src={c.img} alt="" />
                            </div>

                            <div className="explore-category-item-desc">
                              {c.name}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Fade>
  );
}
