import React, { useEffect, useState } from "react";

//component
import { Button, TextField, InputAdornment } from "@mui/material";

//transition
import Fade from "@mui/material/Fade";

//api
import axios from "axios";

//router
import { Link } from "react-router-dom";

//icon
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

//firebase
//import { getAuth } from "firebase/auth";

import CardSport from "../../../components/card/card-collection-sport/CardSport";
import "./View.css";

export default function View() {
  // const auth = getAuth();
  // const user = auth.currentUser;
  //firebase user
  const userLocalUid = localStorage.getItem("user");

  //variables
  const [workout, setWorkout] = useState([]);
  const [sport, setSport] = useState([]);

  //tmp variables
  const [tmpWorkoutToDisplay, setWorkoutToDisplay] = useState([]);
  const [tmpSport, setTmpSport] = useState([]);

  const [active, setActive] = useState(false);
  const [tmpWorkout, setTmpWorkout] = useState([]);

  //   useEffect(()=>{

  //   },[tmpWorkout]);

  /*handlesport*/
  const handleSport = () => {
    setActive(!active);
    // console.log(active);
  };

  /*handleclean*/
  const handleClean = () => {
    setTmpWorkout([]);
  };

  /*Funzione per il filtro dell'array con uno specifico sport */
  function reduction(sportInput) {
    let tmp = workout.filter((item) => item.sport === sportInput);
    setTmpWorkout(tmp);
  }

  const [allWk, setAllWk] = useState(true);
  const [allWkCategory, setAllWkCategory] = useState(false);

  const handleAll = () => {
    setAllWk(!allWk);
    setAllWkCategory(!allWkCategory);
    setActive(false);
    // console.log(allWk);
  };

  const handleCategory = () => {
    setAllWkCategory(!allWkCategory);
    setAllWk(!allWk);
    // console.log(allWkCategory);
  };

  //API CALL
  //get all workouts from a user
  //TODO on save cleat category array
  const getAllWorkout = async () => {
    if (!userLocalUid) return;
    await axios
      .get("https://yoxeen.ks-hub.com:4000/workout/allName", {
        params: { _id: userLocalUid },
      })
      .then((res) => {
        setWorkout([...res.data]);
        setWorkoutToDisplay([...res.data]);
        // console.log("responce", res.data);
      })
      .catch((error) => console.log("error a", error));
  };

  // console.log("axios allworkout responce", workout);

  //get all categories(folder) from a user
  //TODO on save cleat category array
  const getUserCategory = async () => {
    if (!userLocalUid) return;
    await axios
      .get("https://yoxeen.ks-hub.com:4000/workout/category", {
        params: { _id: userLocalUid },
      })
      .then((res) => {
        let tmp = res.data.filter(function (item, pos) {
          return res.data.indexOf(item) == pos;
        });
        setSport(tmp);
        setTmpSport(tmp);
        // console.log("responce category", res.data);
      })
      .catch((error) => console.log("error a", error));
  };

  useEffect(() => {
    getAllWorkout();
  }, []);

  useEffect(() => {
    getUserCategory();
  }, []);

  //search feature

  const [search, setSearch] = useState("");

  //gestisce il cambio dei valore della ricerca
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  //gestisce la cancellazione della ricerca
  const clearSearch = () => {
    setSearch("");
    setSport(tmpSport);
    setWorkout(tmpWorkoutToDisplay);
  };

  //gestisce l'ok della ricerca
  const handleSearchClick = () => {
    // setSearch("");
  };

  useEffect(() => {
    if (allWk) {
      if (search !== "") {
        let filter = [];
        let tmp = [...tmpWorkoutToDisplay];
        if (tmp) {
          tmp.forEach((el) => {
            // console.log("name", el.nome);
            if (el.nome.toLowerCase().includes(search)) {
              // console.log(el);
              filter.push(el);
            }
          });
        }
        setWorkout(filter);
      } else {
        setWorkout(tmpWorkoutToDisplay);
      }
    } else {
      if (search !== "") {
        let filter = [];
        let tmp = [...tmpSport];
        if (tmp) {
          tmp.forEach((el) => {
            if (el.toLowerCase().includes(search)) {
              filter.push(el);
            }
          });
        }
        setSport(filter);
      } else {
        setSport(tmpSport);
      }
    }
  }, [search]);

  return (
    <Fade in={true} {...(true ? { timeout: 300 } : {})}>
      <div className="view">
        <h1>View your workouts</h1>
        <div className="view-filter">
          <div>
            <Button
              // style={{
              //   color: "white",
              //   borderColor: "lightgray",
              //   fontSize: "12px",
              //   borderRadius: "15px",
              //   minWidth: "80px",
              //   marginRight: "10px",
              // }}
              className="view-btn"
              disabled={allWk}
              onClick={handleAll}
              variant="outlined"
            >
              All
            </Button>
            <Button
              // style={{
              //   color: "lightgray",
              //   borderColor: "lightgray",
              //   fontSize: "12px",
              //   borderRadius: "15px",
              //   minWidth: "80px",
              // }}
              className="view-btn"
              disabled={allWkCategory}
              onClick={handleCategory}
              variant="outlined"
            >
              Category
            </Button>
          </div>
          <div
            className="back-btn"
            onClick={() => {
              handleSport();
              handleClean();
            }}
            style={{ display: `${active ? "block" : "none"}` }}
          >
            <ArrowBackRoundedIcon />
          </div>
        </div>

        <div className="drawer-container-title">
          {/* <h5>Filter by name</h5> */}
          {active === false && (
            <TextField
              sx={{ marginTop: 1, minWidth: "350px" }}
              value={search}
              onChange={handleSearch}
              label={""}
              autoComplete="off"
              InputLabelProps={{ shrink: false }}
              placeholder={"Search for any exercise in the database"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {search !== "" && (
                      <CloseIcon
                        style={{ cursor: "pointer" }}
                        onClick={clearSearch}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
          )}
          {/* <Button onClick={handleSearchClick} variant="outlined">
            Search
          </Button> */}
        </div>

        {allWk === false ? (
          <>
            {active === false ? (
              <div className="sport">
                <p>Collections</p>
                <div className="sport-container">
                  {sport.map((s, index) => {
                    return (
                      <div
                        key={index + "b"}
                        // className="sport-item"
                        onClick={() => {
                          handleSport();
                          reduction(s);
                        }}
                      >
                        <CardSport sport={s} />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div>
                {tmpWorkout.map((w, index) => {
                  return (
                    <Link
                      className="item-link"
                      key={index + "a"}
                      to="/account/create"
                      state={{ name: w.nome }}
                    >
                      <CardSport
                        key={index + "a"}
                        sport={w.sport}
                        workout={w.nome}
                      />
                    </Link>
                  );
                })}
              </div>
            )}
          </>
        ) : (
          <div className="sport">
            <p>All workouts</p>
            <div className="sport-container">
              {workout.map((w, index) => {
                return (
                  <Link
                    className="item-link"
                    key={index + "a"}
                    to="/account/create"
                    state={{ name: w.nome }}
                  >
                    <CardSport sport={w.nome} />
                  </Link>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </Fade>
  );
}
