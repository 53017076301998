import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
// import {useOutletContext } from "react-router-dom";

//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//list
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
//modao
import Modal from "@mui/material/Modal";

//select
import Autocomplete from "@mui/material/Autocomplete";

//styles
import { makeStyles } from "@material-ui/core/styles";

//accordion
import Typography from "@mui/material/Typography";

//drawer
import Drawer from "@mui/material/Drawer";

//transition

import Fade from "@mui/material/Fade";

//api
//google
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import Dashboard from "./Dashboard";

// import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  PieChart,
  Pie,
  Tooltip,
  Cell,
  ResponsiveContainer,
  Legend,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Brush,
} from "recharts";

// // icons
import DonutLargeIcon from "@mui/icons-material/DonutLarge";

import "./Progress.css";
import axios from "axios";
// import { axios } from "axios";

// const { getRequestHeaders, getWeeklyData } = require("./DataRequestManager");

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
// const API_KEY = process.env.API_KEY;
const { SetCookie, DeleteCookie, hasCookie } = require("./CookieManager");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItemns: "center",
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="tabpanel-view"
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const dataPie = [
  { name: "Fat", value: 400, perc: "40%" },
  { name: "Carbs", value: 300, perc: "30%" },
  { name: "Proteins", value: 300, perc: "30%" },
];

const datas2 = [
  { date: "2022-03-22", weight: 300 },
  { date: "2022-03-23", weight: 400 },
  { date: "2022-03-21", weight: 300 },
  { date: "2022-03-20", weight: 200 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const useStyles = makeStyles((theme) => ({
  // root: {
  //   "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
  //     // Default transform is "translate(14px, 20px) scale(1)""
  //     // This lines up the label with the initial cursor position in the input
  //     // after changing its padding-left.
  //     transform: "translate(34px, 20px) scale(1);",
  //   },
  // },
  inputRoot: {
    // color: "purple",
    // // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    // '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
    //   // Default left padding is 6px
    //   paddingLeft: 26,
    // },
    // "& .MuiOutlinedInput-notchedOutline": {
    //   borderColor: "green",
    // },
    // "&:hover .MuiOutlinedInput-notchedOutline": {
    //   borderColor: "red",
    // },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#59698a",
    },
  },
}));

export default function Progress() {
  //firebase user
  const userLocalUid = localStorage.getItem("user");

  //use styles
  const styles = useStyles();

  //screen dim
  const [dimension, setDimension] = useState(0);

  //Ottiene la dimensione corrente dello schermo
  function getWindowDimensions() {
    return window.innerWidth;
  }
  //Setta la dimensione della pagina
  useEffect(() => {
    setDimension(getWindowDimensions);
  }, []);
  //Gestisce il resize della pagina
  useEffect(() => {
    function handleResize() {
      setDimension(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //data value
  const [value, setValue] = React.useState(0);

  //go to top
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, [value]);

  //kcal
  const [kcal, setKcal] = useState({ name: "Kcal", value: 1000 });

  //open drawer
  //modal general state
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  //open modal
  //weight
  const [open2, setOpen2] = React.useState(false);

  //chart tab state data
  // const [charts, setCharts] = useState(false);

  //modal state (mod item or add item weight)
  const [weightState, setWeightState] = useState(false);

  //open weight modal
  const handleOpen2 = () => {
    setOpen2(true);
    // setWeightState(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  //pie data
  const [data, setData] = useState(dataPie);
  const [tmpData, setTmpData] = useState(data);
  const [pieState, setState] = useState(false);

  //single macronutrients object
  const [macro, setMacro] = useState(data);

  const handleOpenPieChange = () => {
    setState(true);
  };
  const handleClosePieChange = () => {
    setState(false);
  };

  //renedering percentage
  function percentage(arr, c) {
    arr.forEach((d) => {
      if (d.name === "Fat") {
        let tmp = d.value * 9;
        let p = (tmp / c) * 100;
        d.perc = p.toFixed(0) + " %";
      }
      if (d.name === "Carbs") {
        let tmp = parseInt(d.value) * 4;
        let p = (tmp / c) * 100;
        d.perc = p.toFixed(0) + " %";
      }
      if (d.name === "Proteins") {
        let tmp = d.value * 4;
        let p = (tmp / c) * 100;
        d.perc = p.toFixed(0) + " %";
      }
    });
    return arr;
  }

  // useEffect(() => {
  //   data.forEach((d) => {
  //     if (d.name === "Fat") {
  //       let tmp = d.value;
  //       let p = (tmp / kcal.value) * 100;
  //       d.perc = p.toFixed(0) + " %";
  //     }
  //     if (d.name === "Carbs") {
  //       let tmp = parseInt(d.value);
  //       let p = (tmp / kcal.value) * 100;
  //       d.perc = p.toFixed(0) + " %";
  //     }
  //     if (d.name === "Proteins") {
  //       let tmp = d.value;
  //       let p = (tmp / kcal.value) * 100;
  //       d.perc = p.toFixed(0) + " %";
  //     }
  //   });
  // }, [data, kcal]);

  //modify pie Deatails section item
  const handleDetailsChange = (value, col, i) => {
    let tmp = [...macro];
    tmp[i] = {
      ...tmp[i],
      [col]: parseInt(value),
    };
    setMacro(tmp);
  };

  const handleDetailsSaveChange = () => {
    let cal =
      parseInt(macro[0].value * 9) +
      parseInt(macro[1].value * 4) +
      parseInt(macro[2].value * 4);
    setKcal({
      name: "Kcal",
      value: cal,
    });
    let tmp = percentage(macro, cal);
    setData(tmp);

    handleClosePieChange();
  };

  //weight object
  const [dataWeight, setDataWeight] = React.useState(datas2);
  const [tmpDataWeight, setTmpDataWeight] = React.useState(dataWeight);
  const [tmpVisulWeight, setTmpVisualWeight] = useState([]);

  useEffect(() => {
    let tmp = dataWeight.reverse();
    setTmpVisualWeight(tmp);
  }, [dataWeight]);

  const [weightLine, setWeightLine] = React.useState(-1);
  const [weight, setWeight] = React.useState({ date: "", weight: 0 });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //data check in array
  function checkDate() {
    if (weight.date) {
      for (let i = 0; i < dataWeight.length; i++) {
        if (weight.date === dataWeight[i].date) {
          return i;
        }
      }
      return false;
    }
    return false;
  }

  //sorting array by data
  function orderDate(array) {
    array.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
  }

  const handleAddWeight = () => {
    let i = checkDate();
    if (i !== -1) {
      let tmp = [...dataWeight];
      tmp[i] = weight;
      orderDate(tmp);
      setDataWeight(tmp);
    }
    if (i === false) {
      let tmp = [...dataWeight, weight];
      setDataWeight(tmp);
      orderDate(tmp);
    }

    handleClose2();
  };

  //modify single weight item
  const handleWeightChange = (value, col) => {
    setWeight({
      ...weight,
      [col]: value,
    });
  };

  //modify ine change
  const handleWeightChangeItem = () => {
    let tmp = [...dataWeight];
    tmp[weightLine] = weight;
    setDataWeight(tmp);
    handleClose2();
  };

  //modify ine change
  const handleWeightDeleteItem = () => {
    let tmp = [];
    dataWeight.forEach((el, ind) => {
      if (ind !== weightLine) tmp.push(el);
    });
    // tmp[weightLine] = weight;
    // let fin = tmp.slice(1, weightLine);
    setDataWeight(tmp);
    handleClose2();
  };

  // .
  // .
  // .
  // .
  // .

  //MACRO & WEIGHT API CALL
  const getProgress = async () => {
    if (!userLocalUid) return;
    await axios
      .get("https://yoxeen.ks-hub.com:4000/progress", {
        params: { _id: userLocalUid },
      })
      .then((result) => {
        setTmpData(result.data.macro);
        setTmpDataWeight(result.data.weight);
        setData(result.data.macro);
        setDataWeight(result?.data.weight.reverse());
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getProgress();
  }, []);

  const saveChanges = async () => {
    if (!userLocalUid) return;
    await axios
      .post(
        "https://yoxeen.ks-hub.com:4000/progress",
        {
          id: userLocalUid,
          macro: data,
          weight: dataWeight,
        },
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((result) => {
        getProgress();
      })
      .catch((error) => console.log("error", error));
  };

  const [changed, setChanged] = useState(false);
  //check macro and weight changes

  useEffect(() => {
    //bool variable
    let t = false;

    if (data.length != tmpData.length) {
      t = true;
    } else {
      data.forEach((el, index) => {
        if (el.value !== tmpData[index].value) {
          t = true;
          return;
        }
      });
    }

    if (dataWeight.length != tmpDataWeight.length) {
      t = true;
    } else {
      dataWeight.forEach((el, index) => {
        if (
          el.date !== tmpDataWeight[index].date ||
          el.weight !== tmpDataWeight[index].weight
        ) {
          t = true;
          return;
        }
      });
    }

    if (t) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  }, [data, dataWeight]);

  // .
  // .
  // .
  // .
  //clear function
  const clearMacro = () => {
    let tmp = [...data];
    tmp.forEach((el) => {
      el.value = 0;
      el.perc = 0;
    });
    setData(tmp);
  };
  const clearWeight = () => {
    setDataWeight([]);
    setChanged(true);
  };
  // .
  // .
  // .
  // .
  // .

  //workout details tab

  //single workout to display
  const [workout, setWorkout] = useState({});
  //all workout object to choose
  const [allWorkout, setAllWorkout] = useState([]);
  //allworkoutlist call via api
  const [workoutListName, setWorkoutListName] = React.useState([]);

  //allworkoutlist
  const [workoutName, setWorkoutName] = React.useState("");

  //workout data recorded
  const [workoutData, setWorkoutData] = React.useState([
    {
      name: "distance",
      val: 0,
    },
    {
      name: "volume",
      val: 0,
    },
    {
      name: "rep",
      val: 0,
    },
    {
      name: "calories",
      val: 0,
    },
    {
      name: "duration",
      val: 0,
    },
  ]);

  const [workoutDataView, setWorkoutDataView] = React.useState(workoutData);
  const [workoutDataSum, setWorkoutDataSum] = React.useState({
    distance: 0,
    volume: 0,
    rep: 0,
    calories: 0,
    duration: 0,
  });

  useEffect(() => {
    let wk = [...workoutData];
    let tmp = [];
    let c = 0;

    for (let i = 0; i < wk.length; i++) {
      if (wk[i].val === 0) {
        i++;
      }
      tmp[c] = wk[i];
      c++;
    }

    setWorkoutDataView(tmp);
  }, [workoutData]);

  function getWkData() {
    if (workout != null || workout.data.length != 0) {
      let view = workoutDataSum;
      let dat = [...workoutData];
      let dist = 0;
      let rep = 0;
      let vol = 0;
      let cal = 0;

      workout.data.forEach((d) => {
        d.rows.forEach((w, i) => {
          if (w.distance) dist += w.distance;
          if (w.rep) rep += parseInt(w.rep);
          if (w.weight) {
            vol += parseInt(parseInt(w.rep) * parseInt(w.weight));
          }
        });
      });

      let time = Object.keys(workout.data[0].rows[0]).length * 8;
      cal = time * 4;

      view.volume = vol;
      view.rep = rep;
      view.duration = time;
      view.distance = dist;
      view.calories = cal;

      dat.forEach((d) => {
        if (d.name === "distance") d.val = dist;
        if (d.name === "volume") d.val = vol;
        if (d.name === "rep") d.val = rep;
        if (d.name === "calories") d.val = cal;
        if (d.name === "duration") d.val = time;
      });

      setWorkoutData(dat);
      setWorkoutDataSum(view);
    }
  }

  //handle week plan daily
  const handleName = (n) => {
    allWorkout.forEach((w) => {
      if (w.name === n) {
        setWorkout(w);
        setWorkoutName(n);
        // getWkData();
      }
    });
  };
  useEffect(() => {
    if (workoutName !== "") {
      getWkData();
    }
  }, [workoutName]);

  //API CALL WK
  const getUserWorkout = async () => {
    if (!userLocalUid) return;
    await axios
      .get("https://yoxeen.ks-hub.com:4000/workout", {
        params: { _id: userLocalUid },
      })
      .then((result) => {
        setAllWorkout(result.data);
      })
      .catch((error) => console.log("error", error));
  };
  const getUserWorkoutList = async () => {
    if (!userLocalUid) return;
    await axios
      .get("https://yoxeen.ks-hub.com:4000/workout/all", {
        params: { _id: userLocalUid },
      })
      .then((result) => {
        setWorkoutListName(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getUserWorkout();
    getUserWorkoutList();
  }, []);

  //handle workou update
  // useEffect(() => {
  //   if (workoutData.distance === 0) {
  //     delete workoutData.distance;
  //   }
  //   if (workoutData.kg === 0) {
  //     delete workoutData.kg;
  //   }
  // }, [workoutData]);

  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .

  //get data
  // fetch weekly data
  const [user, setUser] = useState({ haslogin: false, accessToken: "" });

  useEffect(() => {
    const cookieObject = hasCookie();
    if (cookieObject.haslogin) {
      setUser({
        ...cookieObject,
      });
    }
  }, []);
  function login(response) {
    if (response.wc.access_token) {
      setUser({
        ...response.profileObj,
        haslogin: true,
        accessToken: response.wc.access_token,
      });
    }
    SetCookie({
      ...response.profileObj,
      accessToken: response.wc.access_token,
    });
  }

  function logout(response) {
    setUser({ haslogin: false, accessToken: "" });
    DeleteCookie([
      "accessToken",
      "email",
      "givenName",
      "familyName",
      "imageUrl",
      "name",
      "googleId",
    ]);
  }

  function handleLoginFailure(response) {
    alert("Failed to log in");
  }
  function handleLogoutFailure(response) {
    alert("Failed to log out");
  }
  // async function handleData() {
  //   console.log(user.accessToken);
  //   const endTime = new Date().getTime();
  //   const requestHeaderBody = {
  //     params: {
  //       key: API_KEY,
  //     },
  //     headers: {
  //       Authorization: `Bearer ${user.accessToken}`,
  //       Accept: "application/json",
  //     },
  //   };

  //   await Axios.post(
  //     "https://www.googleapis.com/fitness/v1/users/me/dataset:aggregate",
  //     {
  //       aggregateBy: [
  //         {
  //           dataTypeName: "com.google.step_count.delta",
  //         },
  //       ],
  //       bucketByTime: { durationMillis: 86400000 },
  //       endTimeMillis: endTime,
  //       startTimeMillis: endTime - 7 * 86400000,
  //     },
  //     requestHeaderBody
  //   )
  //     .then((response) => {
  //       console.log("res", response);
  //     })
  //     .catch((err) => console.log(err));
  // }

  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // ....
  // .
  // .
  // ..
  // ..
  // .
  // ..
  // ...

  // .
  // fetch weekly data
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  // .
  return (
    <Fade in={true} {...(true ? { timeout: 500 } : {})}>
      <div className="progress">
        {changed && (
          <Button
            className="progress-save-btn"
            variant="contained"
            onClick={saveChanges}
          >
            save
          </Button>
        )}

        {dimension <= 600 && (
          <Fade in={true} {...(true ? { timeout: 400 } : {})}>
            <Button
              variant="filled"
              className="charts-btn"
              onClick={handleOpen}
            >
              Charts
            </Button>
          </Fade>
        )}

        <div className="progress-title">
          <h1>Progrss</h1>
          {dimension > 600 && (
            <Fade in={true} {...(true ? { timeout: 400 } : {})}>
              <Button
                variant="outlined"
                className="plan-btn"
                onClick={handleOpen}
              >
                Charts
              </Button>
            </Fade>
          )}
        </div>
        <h6>
          This section provides simple charts that allows to keep tracking your
          daily activities and progress. At the moment we are able to set this
          list of charts
        </h6>
        <div className="progress-container">
          <TabPanel value={value} index={0}>
            <p>
              For every sport you practice a good nutrition is a must. Take care
              about your macro in this section, so you can easily see the
              correct split.
            </p>
            <div style={{ width: "100%", height: "400px" }}>
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    animationDuration={800}
                    // label={renderCustomizedLabel}
                    outerRadius={100}
                    innerRadius={60}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Pie
                    data={[kcal]}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    innerRadius={110}
                    fill="#ADD8E6 "
                    label
                  />
                  <Tooltip />
                  <Legend
                    layout="horizontal"
                    verticalAlign="top"
                    align="left"
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>

            <div className="pie-details-box">
              <div className="pie-details" onClick={handleOpenPieChange}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "calc(100% - 20px)",
                    padding: "0 10px",
                  }}
                >
                  <h4
                    style={{
                      fontSize: "24px",
                      textAlign: "left",
                      width: "100%",
                    }}
                  >
                    Details
                  </h4>
                  <Button
                    variant="outlined"
                    className="plan-btn"
                    // style={{ color: "black", borderColor: "black" }}
                    onClick={clearMacro}
                  >
                    clear
                  </Button>
                </div>
                <List dense>
                  {data.map((d, index) => (
                    <ListItem key={index + "q"} disablePadding>
                      <div className="single-pie-list-item">
                        <h6>{d.name}</h6>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <h6>{d.value} g</h6>
                          <h6>{d.perc}</h6>
                        </div>
                      </div>
                    </ListItem>
                  ))}
                  <Divider />
                  <ListItem disablePadding>
                    <div className="single-pie-list-item">
                      <h6>{kcal.name}</h6>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <h6>{kcal.value}</h6>
                        <h6>100%</h6>
                      </div>
                    </div>
                  </ListItem>
                </List>
                {/* </div> */}
              </div>
              <div className="pie-details-sticker">
                <h4>Info</h4>
                <h6>
                  Use this section to save your Macro. For more information or
                  if you need a help to build your plan, feel free to contact
                  our team member. You can also look at the blog section if you
                  need more information a bout your nutrition.
                </h6>
              </div>
            </div>

            {/* <Button variant="contained" onClick={() => percentage()}>
            Update
          </Button> */}
          </TabPanel>

          {/* .
        .
        .
        .
        .
        .
        .
        .
        .
        .
        .
        ..
        ..
        .
        .
        .
        .
        ...
        .

        ..
        .
        .
        .
        .
        .

        . */}
          <TabPanel value={value} index={1}>
            <p>
              Start to track your wieght. In every sport you practice we know
              how it's important to control your progress. params!
            </p>
            <div className="weight-chart">
              <ResponsiveContainer height={400}>
                <AreaChart data={tmpVisulWeight}>
                  <defs>
                    <linearGradient id="lg" x1="0" y1="0" x2="2" y2="1">
                      <stop offset="0%" stopColor="#edf3fc" />
                      <stop offset="100%" stopColor="#edf3fc" />
                    </linearGradient>
                  </defs>
                  {/* <CartesianGrid strokeDasharray="3 3" /> */}
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "#aeb8cb",
                      color: "#fbfdff",
                      borderRadius: "10px",
                    }}
                    itemStyle={{ color: "#fbfdff" }}
                  />
                  <Legend />
                  <Brush dataKey="name" height={40} stroke="#aeb8cb" />
                  <Area
                    type="monotone"
                    dataKey="weight"
                    stroke="#aeb8cb"
                    strokeWidth={2}
                    dot={{ fill: "#fbfdff", r: 4 }}
                    activeDot={{ r: 4 }}
                    fill="url(#lg)"
                  />
                </AreaChart>
              </ResponsiveContainer>

              {/* <Button variant="outlined" onClick={handleOpen}>
                modify ex
              </Button> */}
              <Button
                variant="outlined"
                onClick={clearWeight}
                className="plan-btn"
                style={{ marginTop: "50px" }}
              >
                clear weight
              </Button>

              {/* table weight view*/}
              <div className="weight-details-box">
                <div className="weight-details">
                  <div>
                    <p>
                      Click on a particular row to edit the weight added on that
                      day
                    </p>
                    <Paper
                      sx={{
                        width: "100%",
                        overflow: "hidden",
                        maxWidth: "400px",
                      }}
                      className="weight-table"
                    >
                      <TableContainer
                        component={Paper}
                        sx={{
                          maxHeight: 300,
                        }}
                        style={{
                          // background: "white",
                          boxShadow: "0 0 11px rgb(0,0,0,0.4)",
                        }}
                      >
                        <Table
                          sx={{ minHeight: 50 }}
                          size="small"
                          aria-label="a dense table"
                          style={{
                            // background: "white",
                            boxShadow: "0 0 11px rgb(0,0,0,0.4)",
                          }}
                          stickyHeader
                        >
                          <TableHead style={{ background: "#edf3fc" }}>
                            <TableRow style={{ fontWeight: "bold" }}>
                              <TableCell
                                style={{
                                  background: "#edf3fc",
                                  fontWeight: "bold",
                                  color: "#21355e",
                                }}
                                align="center"
                              >
                                Data
                              </TableCell>
                              <TableCell
                                style={{
                                  background: "#edf3fc",
                                  fontWeight: "bold",
                                  color: "#21355e",
                                }}
                                align="center"
                              >
                                Weight
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className="weight-table-body">
                            {dataWeight.map((row, index) => (
                              <TableRow
                                key={index + "d"}
                                style={{ fontWeight: "700" }}
                                onClick={() => {
                                  setWeight(row);
                                  setWeightLine(index);
                                  setWeightState(true);
                                  handleOpen2();
                                }}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="center">{row.date}</TableCell>
                                <TableCell align="center">
                                  {row.weight}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </div>
                  <Button
                    className="add-weight-btn"
                    variant="outlined"
                    onClick={() => {
                      handleOpen2();
                      setWeightState(false);
                    }}
                  >
                    add weight
                  </Button>
                </div>
              </div>
            </div>
          </TabPanel>

          {/* .
        .
        .


























          Workout quality




        .

        . */}

          <TabPanel value={value} index={2}>
            {/* <h1>Workout quality</h1> */}
            <p>
              Start to view the quality of your workout. Take track about all
              params!
            </p>
            <Autocomplete
              className="progress-workout-autocomplete"
              disablePortal
              id="combo-box-demo"
              classes={styles}
              options={workoutListName}
              placeholder={"Select workout to display"}
              sx={{ maxWwidth: 300 }}
              inputValue={workoutName}
              onInputChange={(event, newInputValue) => {
                handleName(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  id="outlined-basic"
                  placeholder={"Select workout to display"}
                  variant="outlined"
                  // style={{ borderColor: "black" }}
                  // InputProps={{
                  //   readOnly: true,
                  // }}
                  {...params}
                  // onChange={(e) => handleName(e.target.value)}
                />
              )}
            />
            <div className="progress-workout">
              <div className="progress-workout-table-desc center">
                <div className="progress-workout-table">
                  <div className="progress-workout-table-item">
                    <span>Total-distance:</span>{" "}
                    <span>{workoutDataSum.distance}</span>
                  </div>
                  <div className="progress-workout-table-item">
                    <span>Total volume of workout:</span>
                    <span> {workoutDataSum.volume}</span>
                  </div>
                  <div className="progress-workout-table-item">
                    <span>Estimation of calories burn:</span>
                    <span> {workoutDataSum.calories}</span>
                  </div>

                  <div className="progress-workout-table-item">
                    <span>Total rep:</span>
                    <span> {workoutDataSum.rep}</span>
                  </div>
                  <div className="progress-workout-table-item">
                    <span>Estimation duration:</span>
                    <span>{workoutDataSum.duration} minutes</span>
                  </div>
                </div>
              </div>
              {workoutName !== "" && (
                <div className="progress-workout-chart">
                  <ResponsiveContainer>
                    <BarChart data={workoutDataView}>
                      <Bar dataKey="val" fill="#aeb8cb" />
                      <XAxis dataKey="name" />
                      <YAxis />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              )}
            </div>
          </TabPanel>
        </div>

        {/* .
        .
        .


























          overlay




        .

        . */}

        <TabPanel value={value} index={3}>
          {/* Daily data view //google fit */}
          <p>
            If you need to take care about your last week progress, use your
            GOOGLE account to login to GOOGLE FIT service and see your weekly
            resumee.
          </p>
          <div className="center" style={{ minHeight: "600px" }}>
            <h1 style={{ color: "#aeb8cb" }}>COMING SOON</h1>
          </div>
          {/* <div className="google-data">
            {user.haslogin ? (
              <GoogleLogout
                clientId={CLIENT_ID}
                buttonText="Logout"
                onLogoutSuccess={logout}
                onFailure={handleLogoutFailure}
              ></GoogleLogout>
            ) : (
              <GoogleLogin
                clientId={CLIENT_ID}
                buttonText="Login"
                onSuccess={login}
                onFailure={handleLoginFailure}
                cookiePolicy={"single_host_origin"}
                responseType="code,token"
                scope="openid https://www.googleapis.com/auth/fitness.activity.read https://www.googleapis.com/auth/fitness.body.read https://www.googleapis.com/auth/fitness.sleep.read https://www.googleapis.com/auth/fitness.nutrition.read https://www.googleapis.com/auth/fitness.heart_rate.read https://www.googleapis.com/auth/fitness.location.read"
              />
            )}
          </div>
          <button>Get data</button> */}
          <div className="google-data">
            {user.accessToken !== "" && <Dashboard user={user} />}
          </div>
        </TabPanel>

        {/* .
        .
        .


























          overlay




        .

        . */}

        <Drawer
          anchor={dimension < 600 ? "bottom" : "right"}
          open={pieState}
          onClose={handleClosePieChange}
        >
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "grid",
              placeItems: "center",
              gap: "20px",
              minWidth: 300,
              padding: "20px",
              maxHeight: 350,
            }}
          >
            {data.map((d, index) => (
              <TextField
                key={index + "f"}
                fullWidth
                id="date"
                label={d.name}
                defaultValue={d.value}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) =>
                  handleDetailsChange(e.target.value, "value", index)
                }
              />
            ))}

            <Button
              fullWidth
              variant="contained"
              onClick={handleDetailsSaveChange}
            >
              Save
            </Button>
          </Box>
        </Drawer>

        <Drawer
          anchor={dimension < 600 ? "bottom" : "right"}
          open={open}
          onClose={handleClose}
        >
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
              height: 250,
              minWidth: 300,
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: "divider", width: "100%" }}
              className="progress-chart-list-box"
            >
              <Tab
                icon={<DonutLargeIcon />}
                iconPosition="end"
                label="Macro Chart"
                onClick={handleClose}
                {...a11yProps(0)}
              />
              <Tab
                icon={<DonutLargeIcon />}
                iconPosition="end"
                label="Weight Chart"
                onClick={handleClose}
                {...a11yProps(1)}
              />
              <Tab
                icon={<DonutLargeIcon />}
                iconPosition="end"
                label="Workout chart"
                onClick={handleClose}
                {...a11yProps(2)}
              />
              <Tab
                icon={<DonutLargeIcon />}
                iconPosition="end"
                label="Daily data chart"
                onClick={handleClose}
                {...a11yProps(3)}
              />
            </Tabs>
          </Box>
        </Drawer>
        <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {weightState === false ? (
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Add new daily weight
              </Typography>
              <div className="weight-modal">
                {/* <Typography>Add new weight</Typography> */}
                <TextField
                  id="outlined-number"
                  label="Number"
                  type="number"
                  fullWidth
                  placeholder={weight.weight.toString()}
                  onChange={(e) => handleWeightChange(e.target.value, "weight")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="date"
                  label="Birthday"
                  type="date"
                  defaultValue={weight.date}
                  // sx={{ width: 220 }}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => handleWeightChange(e.target.value, "date")}
                />
                <Button fullWidth variant="outlined" onClick={handleAddWeight}>
                  Save
                </Button>
              </div>
            </Box>
          ) : (
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Edit weight
              </Typography>
              <div className="weight-modal" style={{ gap: "20px" }}>
                {/* <Typography>Add new weight</Typography> */}
                <TextField
                  id="outlined-number"
                  label="Number"
                  type="number"
                  fullWidth
                  placeholder={weight.weight.toString()}
                  onChange={(e) => handleWeightChange(e.target.value, "weight")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="date"
                  label="Birthday"
                  type="date"
                  defaultValue={weight.date}
                  fullWidth
                  // sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => handleWeightChange(e.target.value, "date")}
                />
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleWeightChangeItem}
                >
                  Save
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleWeightDeleteItem}
                >
                  delete
                </Button>
              </div>
            </Box>
          )}
        </Modal>
      </div>
    </Fade>
  );
}
